<template>
  <div class="content" id="duplicate-brochures"></div>
</template>

<script>
export default {
  name: "duplicate-brochures",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {
    onClose() {
      this.$emit("close");
    }
  }
};
</script>
