<template>
  <div class="categories content">
    <toolbar />

    <el-table
      v-if="isCategories"
      :data="categories"
      :default-sort="{ prop: 'name', order: 'ascending' }"
      stripe
      border
      class="mt-2"
    >
      <el-table-column
        :resizable="resizableColumn"
        label="Name"
        prop="name"
        min-width="310"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Language"
        prop="language"
        min-width="110"
        align="center"
      >
        <template slot-scope="scope">
          <TableLanguage :item="scope.row" />
        </template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Shops"
        prop="shops_count"
        min-width="199"
        sortable
      >
        <template slot-scope="scope">
          {{ getCount(scope.row.shops_count) }}
        </template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Brochures"
        min-width="310"
        prop="brochures"
      >
        <template slot-scope="scope">
          {{ displayBrochureInfo(scope.row) }}
        </template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Created"
        prop="inserted_at"
        min-width="460"
        sortable
      >
        <template slot-scope="scope">
          <div class="row col-xs-12 flex-items-xs-middle flex-items-xs-between">
            <div>
              {{ displayDate(scope.row.inserted_at) }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :resizable="resizableColumn"
        label="Options"
        min-width="100"
      >
        <template slot-scope="scope">
          <div class="row col-xs-12 flex-items-xs-middle flex-items-xs-between">
            <div>
              <table-category-controls :item="scope.row" />
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { formatDate } from "../../utils";
import tableCategoryControls from "./table-controls";
import TableLanguage from "../shared/table-language";
import toolbar from "./toolbar";

export default {
  name: "categories",
  components: { TableLanguage, tableCategoryControls, toolbar },
  data() {
    return {
      statData: null,
      statType: "categories",
      resizableColumn: false
    };
  },
  computed: {
    categories() {
      return this.$store.getters.allCategories;
    },
    isCategories() {
      return this.categories && this.categories.length;
    }
  },
  created() {
    this.$store.dispatch("fetchCategories");
  },
  methods: {
    displayBrochureInfo(brochure) {
      let active = this.getCount(brochure.active_count) + " active, ";
      let notPublished =
        this.getCount(brochure.not_published_count) + " not published, ";
      let expired = this.getCount(brochure.expired_count) + " expired";

      return active + notPublished + expired;
    },
    getCount(count) {
      return count ? count : 0;
    },
    displayDate(dataNumber) {
      return formatDate(Date.parse(dataNumber), "HH:MM dd-mm-yyyy");
    }
  }
};
</script>
