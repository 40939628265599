<template>
  <div :class="zoomStateClass" class="slider__counter">
    Page {{ index + 1 }} / {{ length }}
  </div>
</template>

<script>
import defaultMixin from "./defaultMixin";

export default {
  name: "Counter",
  mixins: [defaultMixin]
};
</script>
