import BootstrapVue from "bootstrap-vue";
import Element from "element-ui";
import "phoenix_html";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import VueResource from "vue-resource";
import draggableOffer from "vuedraggable";
import main from "./main.vue";
import router from "./router";
import store from "./store";
import { createProvider } from "./vue-apollo.js";
import VueApollo from "vue-apollo";
import Vuelidate from "vuelidate";

Vue.use(Vuelidate);
Vue.use(Element);
Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.use(VueApollo);

Vue.component("Multiselect", Multiselect);
Vue.component("draggableOffer", draggableOffer);

// this is necessary until we deal with k8s redirects
if (window.location.hostname !== "localhost") {
  window.location.protocol = "https:";
}

const app = new Vue({
  el: "#app",
  store,
  router,
  apolloProvider: createProvider(),

  render: (h) => h(main)
});

export { app, store };
