<template>
  <div class="table-extra" id="table-categories-controls">
    <ul class="table-controls">
      <li>
        <router-link :to="{ path: id }" append>
          <div class="custom-icon share-icon"></div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TableControls",
  props: ["id"]
};
</script>
