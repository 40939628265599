<template>
  <div class="p-1">
    <div class="border-b text-xs-center">
      <h5 class="secondary">{{ header }}</h5>
    </div>

    <div v-if="isBrochure" class="row">
      <div
        v-for="(item, index) in brochures"
        class="col-xs-6 mt-1"
        :key="index"
      >
        <BrochureItem :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import BrochureItem from "../brochures/brochure-item.vue";

export default {
  name: "box",
  props: ["title", "brochures"],
  components: { BrochureItem },
  data() {
    return {};
  },
  computed: {
    header() {
      let brochuresCount = this.brochures ? this.brochures.length : 0;
      return this.title + " (" + brochuresCount + ")";
    },
    isBrochure() {
      return this.brochures && this.brochures.length ? true : false;
    }
  },
  methods: {}
};
</script>
