export default {
  methods: {
    inputStateClass(state) {
      return state ? "danger" : "";
    },
    selectStateClass(state) {
      return state ? "invalid" : "";
    }
  }
};
