<template>
  <img
    class="table-language-img"
    v-if="this.item.language"
    :src="this.item.language.file_small_url"
  />
</template>

<script>
export default {
  name: "TableLanguage",
  props: ["item"]
};
</script>
