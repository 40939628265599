<template>
  <PageLayout class="ads">
    <ToolBar title="Ads">
      <template slot="actions">
        <BaseButton @click="addAd" class="leading-icon"
          ><div class="custom-icon add-circle-icon"></div>
          Add image ad</BaseButton
        >
        <BaseButton @click="addAdHtmlEmbed" class="leading-icon"
          ><div class="custom-icon add-circle-icon"></div>
          Add HTML ad</BaseButton
        >
      </template>
    </ToolBar>
    <AdsOverview />
  </PageLayout>
</template>

<script>
import AdsOverview from "./ads-overview.vue";
import BaseButton from "../atoms/BaseButton.vue";
import ToolBar from "../layout/tool-bar.vue";
import PageLayout from "../layout/page-layout.vue";

export default {
  name: "ads",
  components: {
    AdsOverview,
    BaseButton,
    ToolBar,
    PageLayout
  },
  methods: {
    addAd() {
      this.$router.push("/ads/new/");
    },
    addAdHtmlEmbed() {
      this.$router.push("/ads/new-html-embed/");
    }
  }
};
</script>
