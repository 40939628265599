<template>
  <div class="content-toolbar">
    <span class="page-title">Categories</span>
    <ul class="tools pull-right">
      <li>
        <b-button @click="showNewForm = true" variant="tool" class="btn-plus">
          <div class="custom-icon add-circle-icon"></div>
          Add category
        </b-button>
      </li>
    </ul>
    <new-category
      v-if="showNewForm"
      :show.sync="showNewForm"
      @close="showNewForm = false"
    />
  </div>
</template>

<script>
import newCategory from "./new";

export default {
  name: "categories-toolbar",
  components: { newCategory },
  data() {
    return {
      showNewForm: false
    };
  },
  methods: {},
  computed: {}
};
</script>
