<template>
  <div class="shop-modal">
    <modal :show.sync="show" :large="true" @cancel="onClose">
      <div slot="header">
        <h3 class="title">Edit {{ shopName }}</h3>
        <div v-if="formStruct && formStruct.customer" class="secondary">
          on <u>{{ getFormCustomerList(this.formStruct.customer) }}</u>
        </div>
      </div>
      <shop-form
        v-if="formStruct !== null && params !== null"
        ref="editShopForm"
        :struct="formStruct"
        :languages="languages"
        v-on:update="updateData"
      />
      <div slot="footer">
        <b-button class="btn-narrow-md" variant="modal" @click="onClose()">
          discard changes
        </b-button>
        <b-button class="btn-narrow-md" variant="modal" @click="onSubmit()">
          save changes
        </b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import shopForm from "./form";
import modal from "../shared/modal";
const helper = require("./helper");
import * as _ from "lodash";

export default {
  name: "edit-shop",
  components: { shopForm, modal },
  computed: {
    languages() {
      return this.$store.getters.allLanguages;
    },
    shopName() {
      let name =
        this.formStruct && this.formStruct.name ? this.formStruct.name : "";
      return _.truncate(name, {
        length: 35
      });
    }
  },
  data() {
    return {
      formStruct: helper.serialize(this.shop),
      params: helper.serialize(this.shop)
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    updateData(data) {
      this.params = data;
    },
    onSubmit() {
      const form = this.$refs.editShopForm;
      form.validateAll();
      if (form.isValid()) {
        let data = helper.deserialize(this.params, this.shop);
        this.$store.dispatch("updateShop", { id: this.shop.id, params: data });
        this.onClose();
      }
    },
    getFormCustomerList(customer) {
      let formCustomer = helper.customers.find(
        (el) => el.value == customer.value
      );
      return formCustomer.text;
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    shop: {
      type: Object
    }
  }
};
</script>
