import { deserializeRelations } from "../../utils/common.js";
import * as _ from "lodash";

export function formStruct() {
  return {
    name: "",
    customer_client: null,
    tracking_uid: "",
    additional_ga_tracking_id: "",
    shop_categories: [],
    shop_toplevel: [],
    shop_afterpage: [],
    website_url: "",
    search_keywords: "",
    postcodes_text: "",
    contact_emails_text: "",
    email: "",
    regions: [],
    customer: { value: "non_customer", text: "Non-Customer" },
    options: [],
    description: {},
    meta_description: {},
    title_main: {},
    title_secondary: {},
    slug_languages: "NL",
    auto_parser_type: "publitas",
    slug_input: {},
    minimum_brochures_no: "0",
    customer_level: 4,
    show_ads: false,
    show_ads_2: false,
    local_brochures: false,
    overwrite_utms: true,
    use_original_link: false,
    show_in_footer: false,
    background: { hex: "#FFFFFF" },
    hotspot_mode: hotspot_mode[0],
    last_page: last_page[0],
    icon: null,
    radius: 0.0
  };
}

export function serialize(shop) {
  var params = formStruct();
  params.name = shop.name;
  params.regions = append_delete_flag(shop.regions);
  params.tracking_uid = shop.tracking_uid;
  params.additional_ga_tracking_id = shop.additional_ga_tracking_id;
  params.email = shop.email;
  params.website_url = shop.website_url;
  params.show_ads = shop.show_ads;
  params.show_ads_2 = shop.show_ads_2;
  params.local_brochures = shop.local_brochures;
  params.overwrite_utms = shop.overwrite_utms;
  params.auto_parser_type = shop.auto_parser_type;
  params.slug_languages = resolve_slug_language(
    shop.auto_parser_slug_nl,
    shop.auto_parser_slug_fr
  );
  params.slug_input = {
    NL: shop.auto_parser_slug_nl,
    FR: shop.auto_parser_slug_fr
  };
  params.minimum_brochures_no = shop.minimum_brochures_no;
  params.search_keywords = shop.search_keywords;
  params.postcodes_text = shop.postcodes_text;
  params.contact_emails_text = shop.contact_emails_text;
  params.use_original_link = shop.use_original_link;
  params.show_in_footer = shop.show_in_footer;
  params.customer = this.customers.find((el) => {
    return el.value == shop.customer;
  });
  params.customer_level = shop.customer_level;
  params.background.hex = shop.background_color;
  params.shop_categories = shop.shop_categories;
  params.shop_toplevel = shop.shop_toplevel;
  params.shop_afterpage = shop.shop_afterpage;

  // if (shop.shop_descriptions.length) {
  params.description = {};
  params.meta_description = {};
  params.title_main = {};
  params.title_secondary = {};
  // }

  for (let i = 0; i < shop.shop_descriptions.length; i++) {
    params.description[shop.shop_descriptions[i].language_id] =
      shop.shop_descriptions[i].description;
    params.meta_description[shop.shop_descriptions[i].language_id] =
      shop.shop_descriptions[i].meta_description;
    params.title_main[shop.shop_descriptions[i].language_id] =
      shop.shop_descriptions[i].title_main;
    params.title_secondary[shop.shop_descriptions[i].language_id] =
      shop.shop_descriptions[i].title_secondary;
  }
  params.hotspot_mode = this.hotspot_mode.find(
    (el) => el.value == shop.hotspot_mode
  );
  params.last_page = this.last_page.find((el) => el.value == shop.last_page);

  params.icon = shop.file_small_url;
  params.radius = shop.radius;

  return params;
}

export function append_delete_flag(array) {
  _.forEach(array, (item) => {
    item.delete = false;
  });

  return array;
}

export function resolve_slug_language(slug, slug_fr) {
  return !slug_fr ? "NL" : "FR";
}

export function deserialize(data, item = {}) {
  var params = {};
  params.name = data.name;
  if (data.customer_client) {
    params.customer_client_id = data.customer_client.id;
  }
  params.regions = data.regions;
  params.tracking_uid = data.tracking_uid;
  params.additional_ga_tracking_id = data.additional_ga_tracking_id;
  params.email = data.email;
  params.website_url = data.website_url;
  params.customer = data.customer.value;
  params.show_ads = data.show_ads;
  params.show_ads_2 = data.show_ads_2;
  params.local_brochures = data.local_brochures;
  params.overwrite_utms = data.overwrite_utms;
  params.search_keywords = data.search_keywords;
  params.postcodes_text = data.postcodes_text;
  params.contact_emails_text = data.contact_emails_text;
  params.use_original_link = data.use_original_link;
  params.show_in_footer = data.show_in_footer;
  params.customer_level = data.customer_level;
  params.minimum_brochures_no = data.minimum_brochures_no;
  params.auto_parser_slug_nl = data.slug_input.NL;
  params.auto_parser_slug_fr = data.slug_input.FR;
  params.auto_parser_type = data.auto_parser_type;
  params.background_color = data.background.hex;
  params.shop_categories = deserializeRelations(
    data.shop_categories,
    item.shop_categories,
    "category",
    "shop_category_id"
  );
  params.shop_toplevel = deserializeRelations(
    data.shop_toplevel,
    item.shop_toplevel,
    "category",
    "shop_toplevel_id"
  );
  params.shop_afterpage = deserializeRelations(
    data.shop_afterpage,
    item.shop_afterpage,
    "category",
    "shop_afterpage_id"
  );

  if (!_.isString(data.icon)) params.icon = data.icon;
  params.shop_descriptions = resolve_shop_descriptions(
    data,
    item.shop_descriptions
  );
  params.hotspot_mode = data.hotspot_mode.value;
  params.last_page = data.last_page.value;
  params.radius = data.radius;

  return toFormData(params);
}

function toFormData(obj, form, namespace) {
  let fd = form || new FormData();
  let formKey;

  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (!obj[property] && obj[property] !== false) {
        obj[property] = "";
      }

      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString());
      } else if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        toFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
}

export function resolve_shop_descriptions(data, old_descriptions) {
  let description = data.description;
  let meta = data.meta_description;
  let title_main = data.title_main;
  let title_secondary = data.title_secondary;
  let params = [];
  for (let key in description) {
    let description_object = {
      language_id: key,
      description: description[key],
      meta_description: meta[key],
      title_main: title_main[key],
      title_secondary: title_secondary[key]
    };

    let existing_object = _.find(old_descriptions, (description) => {
      return description.language_id == key;
    });

    if (existing_object) {
      description_object.id = existing_object.id;
    }

    params.push(description_object);
  }

  return params;
}

export const customers = [
  { value: "non_customer", text: "Non-Customer" },
  { value: "customer", text: "Customer List" },
  { value: "vital", text: "Vital Customer List" }
];

export const shopOptions = [
  { text: "Ready For Approval", value: "for_approval" },
  { text: "Active", value: "published" },
  { text: "Trash", value: "trash" }
];

export const hotspot_mode = [
  { name: "Disabled", value: "disabled" },
  { name: "Links", value: "links" },
  { name: "Products without links", value: "products_without_link" },
  { name: "Products with links", value: "products_with_link" }
];

export const last_page = [
  { name: "Not enabled", value: false },
  { name: "Enabled", value: true }
];
