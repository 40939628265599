<template>
  <div v-if="isActive" class="slider">
    <SplitPages />
    <Hide />
    <Zoom />
    <Delete />
    <div class="slider__mainBox">
      <MainPhoto />
      <Arrows />
      <Counter />
    </div>

    <Thumbs />

    <LastButtons />
  </div>
</template>

<script>
import MainPhoto from "./MainPhoto";
import Delete from "./Delete";
import Arrows from "./Arrows";
import Counter from "./Counter";
import Thumbs from "./Thumbs";
import Hide from "./Hide";
import Zoom from "./Zoom";
import LastButtons from "./LastButtons";
import SplitPages from "./SplitPages";

export default {
  name: "Slider",
  components: {
    LastButtons,
    MainPhoto,
    Delete,
    Arrows,
    Counter,
    Thumbs,
    Hide,
    Zoom,
    SplitPages
  },
  computed: {
    isActive() {
      return this.$store.getters.sliderActive;
    }
  },
  beforeUpdate() {
    this.$store.dispatch("resetSlider");
  }
};
</script>
