<template>
  <div class="datepicker" :class="wrapperClass">
    <input
      :type="inline ? 'hidden' : 'text'"
      :class="inputClass"
      :name="name"
      :id="id"
      @click="showCalendar()"
      :value="formattedValue"
      :placeholder="placeholder"
      readonly
    />

    <slot name="icon"></slot>

    <!-- Day View -->
    <div
      class="calendar"
      :class="{ 'align-left': alignLeft }"
      v-show="showDayView"
      v-bind:style="calendarStyle"
    >
      <header>
        <span
          @click="previousMonth"
          class="prev"
          v-bind:class="{ disabled: previousMonthDisabled(currDate) }"
        >
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </span>
        <span @click="showMonthCalendar" class="up"
          >{{ currMonthName }} {{ currYear }}</span
        >
        <span
          @click="nextMonth"
          class="next"
          v-bind:class="{ disabled: nextMonthDisabled(currDate) }"
        >
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </span>
      </header>
      <span
        class="datepicker-cell day-header"
        v-for="d in daysOfWeek"
        :key="`${d}-daysOfWeek`"
        >{{ d }}</span
      >
      <span
        class="datepicker-cell day blank"
        v-for="d in blankDaysBefore"
        :key="`${d}-blankDaysBefore`"
        >{{ d }}</span
      >
      <span
        class="datepicker-cell day"
        v-for="day in days"
        track-by="timestamp"
        v-bind:class="{
          selected: day.isSelected,
          disabled: day.isDisabled,
          highlighted: day.isHighlighted
        }"
        @click="selectDate(day)"
        :key="`${day.date}-days`"
        >{{ day.date }}</span
      >
      <span
        class="datepicker-cell day blank"
        v-for="d in blankDaysAfter"
        :key="`${d}-blankDaysAfter-55`"
        >{{ d }}</span
      >

      <div class="manually" v-if="isSetManuallyItem">
        <header>Set Time</header>
        <ul>
          <li>
            <input
              v-model="hours"
              class="form-control form-control-sm"
              type="number"
              min="0"
              max="23"
            />
          </li>
          <div class="colon">:</div>
          <li>
            <input
              v-model="minutes"
              class="form-control form-control-sm"
              type="number"
              min="0"
              max="59"
            />
          </li>
          <li>
            <b-button @click="setCurrentTime" variant="modal"> now </b-button>
          </li>
        </ul>
      </div>
      <div class="defaults" v-if="type == 'defaults'">
        <b-form-radio
          v-model="selectedDefaults"
          :items="defaultValues"
          stacked
        />
      </div>
    </div>

    <!-- Month View -->
    <div
      class="calendar"
      v-show="showMonthView"
      v-bind:style="calendarStyleSecondary"
    >
      <header>
        <span
          @click="previousYear"
          class="prev"
          v-bind:class="{ disabled: previousYearDisabled(currDate) }"
          >&lt;</span
        >
        <span @click="showYearCalendar" class="up">{{ getYear() }}</span>
        <span
          @click="nextYear"
          class="next"
          v-bind:class="{ disabled: nextYearDisabled(currDate) }"
          >&gt;</span
        >
      </header>
      <span
        class="datepicker-cell month"
        v-for="(month, index) in months"
        track-by="timestamp"
        v-bind:class="{
          selected: month.isSelected,
          disabled: month.isDisabled
        }"
        @click.stop="selectMonth(month)"
        :key="`${index}-months`"
        >{{ month.month }}</span
      >
    </div>

    <!-- Year View -->
    <div
      class="calendar"
      v-show="showYearView"
      v-bind:style="calendarStyleSecondary"
    >
      <header>
        <span
          @click="previousDecade"
          class="prev"
          v-bind:class="{ disabled: previousDecadeDisabled(currDate) }"
          >&lt;</span
        >
        <span>{{ getDecade() }}</span>
        <span
          @click="nextDecade"
          class="next"
          v-bind:class="{ disabled: nextMonthDisabled(currDate) }"
          >&gt;</span
        >
      </header>
      <span
        :key="year.year"
        class="datepicker-cell year"
        v-for="year in years"
        track-by="timestamp"
        v-bind:class="{ selected: year.isSelected, disabled: year.isDisabled }"
        @click.stop="selectYear(year)"
        >{{ year.year }}</span
      >
    </div>
  </div>
</template>
<script>
import utils from "./utils.js";
import translations from "./translations.js";
import moment from "moment";
export default {
  name: "shared-datepicker",
  props: {
    baseDate: {
      type: [moment, Date, String]
    },
    baseTime: {
      type: String,
      default: "start"
    },
    type: {
      type: String,
      default: "manual"
    },
    value: {
      validator: function (val) {
        return (
          val == null ||
          val instanceof Date ||
          val instanceof moment ||
          typeof val === "string"
        );
      }
    },
    name: {
      type: String
    },
    id: {
      type: String
    },
    format: {
      type: String,
      default: "yyyy-MM-dd TT"
    },
    language: {
      type: String,
      default: "en"
    },
    disabled: {
      type: Object
    },
    highlighted: {
      type: Object
    },
    placeholder: {
      type: String,
      default: "Select date"
    },
    inline: {
      type: Boolean
    },
    inputClass: {
      type: String
    },
    wrapperClass: {
      type: String
    },
    mondayFirst: {
      type: Boolean,
      default: true
    },
    alignLeft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      /*
       * Vue cannot observe changes to a Date Object so date must be stored as a timestamp
       * This represents the first day of the current viewing month
       * {Number}
       */
      currDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).getTime(),
      selectedDate: new Date(),
      selectedDateTime: new Date(),
      /*
       * Flags to show calendar views
       * {Boolean}
       */
      showDayView: false,
      showMonthView: false,
      showYearView: false,
      calendarHeight: 0,
      defaultValues: [
        { text: "Manually", value: "manually" },
        { text: "Valid from + 1 Week", value: "one_week" },
        { text: "Valid from + 2 Weeks", value: "two_weeks" }
      ],
      selectedDefaults: "manually",
      minutes: this.baseTime != "finish" ? "00" : "59",
      hours: this.baseTime != "finish" ? "00" : "23"
    };
  },
  watch: {
    hours(value) {
      this.setDate(this.selectedDate.getTime());
    },
    minutes(value) {
      this.setDate(this.selectedDate.getTime());
    },
    value(value) {
      this.setValue(value);
    },
    selectedDefaults(value) {
      this.placeBaseDate(value);
    },
    baseDate(value) {
      this.placeBaseDate(this.selectedDefaults);
    }
  },
  computed: {
    formattedValue() {
      if (!this.selectedDateTime) {
        return null;
      }
      return utils.formatDate(
        new Date(this.selectedDateTime),
        this.format,
        this.translation
      );
    },
    translation() {
      return translations.translations[this.language];
    },
    currMonthName() {
      const d = new Date(this.currDate);
      return utils.getMonthName(d.getMonth(), this.translation.months.original);
    },
    currYear() {
      const d = new Date(this.currDate);
      return d.getFullYear();
    },

    blankDaysBefore() {
      const d = new Date(this.currDate);
      let dObj = new Date(
        d.getFullYear(),
        d.getMonth(),
        1,
        d.getHours(),
        d.getMinutes()
      );
      let prev = new Date(
        d.getFullYear(),
        d.getMonth(),
        1,
        d.getHours(),
        d.getMinutes()
      );
      prev.setMonth(prev.getMonth() - 1);
      let daysInMonth = utils.daysInMonth(prev.getFullYear(), prev.getMonth());
      let blankDays;
      if (this.mondayFirst) {
        blankDays = dObj.getDay() > 0 ? dObj.getDay() - 1 : 6;
      } else {
        blankDays = dObj.getDay();
      }

      var res = [];

      for (let i = 0; i < blankDays; i++) {
        res.unshift(daysInMonth - i);
      }
      return res;
    },
    blankDaysAfter() {
      const d = new Date(this.currDate);
      let dObj = new Date(
        d.getFullYear(),
        d.getMonth(),
        1,
        d.getHours(),
        d.getMinutes()
      );
      dObj.setMonth(dObj.getMonth() + 1);
      if (this.mondayFirst) {
        return (7 - dObj.getDay() + 1) % 7;
      } else {
        return (7 - dObj.getDay()) % 7;
      }
    },
    daysOfWeek() {
      if (this.mondayFirst) {
        const tempDays = this.translation.days.slice();
        tempDays.push(tempDays.shift());
        return tempDays;
      }
      return this.translation.days;
    },
    days() {
      const d = new Date(this.currDate);
      let days = [];
      // set up a new date object to the beginning of the current 'page'
      let dObj = new Date(
        d.getFullYear(),
        d.getMonth(),
        1,
        d.getHours(),
        d.getMinutes()
      );
      let daysInMonth = utils.daysInMonth(dObj.getFullYear(), dObj.getMonth());
      for (let i = 0; i < daysInMonth; i++) {
        days.push({
          date: dObj.getDate(),
          timestamp: dObj.getTime(),
          isSelected: this.isSelectedDate(dObj),
          isDisabled: this.isDisabledDate(dObj),
          isHighlighted: this.isHighlightedDate(dObj)
        });
        dObj.setDate(dObj.getDate() + 1);
      }
      return days;
    },
    months() {
      const d = new Date(this.currDate);
      let months = [];
      // set up a new date object to the beginning of the current 'page'
      let dObj = new Date(
        d.getFullYear(),
        0,
        d.getDate(),
        d.getHours(),
        d.getMinutes()
      );
      for (let i = 0; i < 12; i++) {
        months.push({
          month: utils.getMonthName(i, this.translation.months.original),
          timestamp: dObj.getTime(),
          isSelected: this.isSelectedMonth(dObj),
          isDisabled: this.isDisabledMonth(dObj)
        });
        dObj.setMonth(dObj.getMonth() + 1);
      }
      return months;
    },
    years() {
      const d = new Date(this.currDate);
      let years = [];
      // set up a new date object to the beginning of the current 'page'
      let dObj = new Date(
        Math.floor(d.getFullYear() / 10) * 10,
        d.getMonth(),
        d.getDate(),
        d.getHours(),
        d.getMinutes()
      );
      for (let i = 0; i < 10; i++) {
        years.push({
          year: dObj.getFullYear(),
          timestamp: dObj.getTime(),
          isSelected: this.isSelectedYear(dObj),
          isDisabled: this.isDisabledYear(dObj)
        });
        dObj.setFullYear(dObj.getFullYear() + 1);
      }
      return years;
    },
    calendarStyle() {
      let elSize = {
        top: 0,
        height: 0
      };
      if (this.$el) {
        elSize = this.$el.getBoundingClientRect();
      }
      let heightNeeded = elSize.top + elSize.height + this.calendarHeight || 0;
      let styles = {};
      // if the calendar doesn't fit on the window without scrolling position it above the input
      if (heightNeeded > window.innerHeight) {
        styles = {
          bottom: elSize.height + "px"
        };
      }
      if (this.isInline()) {
        styles.position = "static";
      }
      return styles;
    },
    calendarStyleSecondary() {
      return this.isInline() ? { position: "static" } : {};
    },
    isSetManuallyItem() {
      return this.selectedDefaults == "manually" && this.type != "none";
    }
  },
  methods: {
    close() {
      this.showDayView = this.showMonthView = this.showYearView = false;
    },
    isOpen() {
      return this.showDayView || this.showMonthView || this.showYearView;
    },
    isInline() {
      return typeof this.inline !== "undefined" && this.inline;
    },
    placeBaseDate(selectedStatus) {
      var week = 7 * 24 * 3600 * 1000;
      var day = 24 * 3600 * 1000;
      var baseDate = new Date(this.baseDate).getTime();
      if (baseDate) {
        if (selectedStatus == "one_week") {
          this.setDate(baseDate + (week - day));
          this.close();
        } else if (selectedStatus == "two_weeks") {
          this.setDate(baseDate + 2 * week - day);
          this.close();
        }
      }
    },
    showCalendar() {
      if (this.isInline()) {
        return false;
      }
      if (this.isOpen()) {
        return this.close();
      }
      this.showDayCalendar();
    },
    showDayCalendar() {
      this.close();
      this.showDayView = true;
      this.$emit("opened");
      this.$emit("input", this.selectedDateTime);
    },
    showMonthCalendar() {
      this.close();
      this.showMonthView = true;
    },
    showYearCalendar() {
      this.close();
      this.showYearView = true;
    },
    setCurrentTime() {
      this.selectedDate = new Date();
      this.hours = utils.formatTime(new Date().getHours());
      this.minutes = utils.formatTime(new Date().getMinutes());
    },
    setDate(timestamp) {
      let withTime = timestamp;
      let haveTime = timestamp % 10;

      this.selectedDate = new Date(timestamp);
      this.selectedDateTime = new Date(timestamp);

      if (!haveTime) {
        this.selectedDateTime.setHours(this.hours);
        this.selectedDateTime.setMinutes(this.minutes);
      }

      this.currDate = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        1
      ).getTime();

      this.$emit("selected", this.selectedDateTime);
      this.$emit("input", this.selectedDateTime);
    },
    selectDate(day) {
      if (day.isDisabled) {
        return false;
      }
      this.setDate(day.timestamp);
      if (this.isInline()) {
        return this.showDayCalendar();
      }
      // this.close()
    },
    selectMonth(month) {
      if (month.isDisabled) {
        return false;
      }
      this.currDate = month.timestamp;
      this.showDayCalendar();
    },
    selectYear(year) {
      if (year.isDisabled) {
        return false;
      }
      this.currDate = year.timestamp;
      this.showMonthCalendar();
    },
    /**
     * @return {Number}
     */
    getMonth() {
      let d = new Date(this.currDate);
      return d.getMonth();
    },
    /**
     * @return {Number}
     */
    getYear() {
      let d = new Date(this.currDate);
      return d.getFullYear();
    },
    /**
     * @return {String}
     */
    getDecade() {
      let d = new Date(this.currDate);
      let sD = Math.floor(d.getFullYear() / 10) * 10;
      return sD + "'s";
    },
    previousMonth() {
      if (this.previousMonthDisabled()) {
        return false;
      }
      let d = new Date(this.currDate);
      d.setMonth(d.getMonth() - 1);
      this.currDate = d.getTime();
      this.$emit("changedMonth");
    },
    previousMonthDisabled() {
      if (
        typeof this.disabled === "undefined" ||
        typeof this.disabled.to === "undefined" ||
        !this.disabled.to
      ) {
        return false;
      }
      let d = new Date(this.currDate);
      if (
        this.disabled.to.getMonth() >= d.getMonth() &&
        this.disabled.to.getFullYear() >= d.getFullYear()
      ) {
        return true;
      }
      return false;
    },
    nextMonth() {
      if (this.nextMonthDisabled()) {
        return false;
      }
      let d = new Date(this.currDate);
      const daysInMonth = utils.daysInMonth(d.getFullYear(), d.getMonth());
      d.setDate(d.getDate() + daysInMonth);
      this.currDate = d.getTime();
      this.$emit("changedMonth");
    },
    nextMonthDisabled() {
      if (
        typeof this.disabled === "undefined" ||
        typeof this.disabled.from === "undefined" ||
        !this.disabled.from
      ) {
        return false;
      }
      let d = new Date(this.currDate);
      if (
        this.disabled.from.getMonth() <= d.getMonth() &&
        this.disabled.from.getFullYear() <= d.getFullYear()
      ) {
        return true;
      }
      return false;
    },
    previousYear() {
      if (this.previousYearDisabled()) {
        return false;
      }
      let d = new Date(this.currDate);
      d.setYear(d.getFullYear() - 1);
      this.currDate = d.getTime();
      this.$emit("changedYear");
    },
    previousYearDisabled() {
      if (
        typeof this.disabled === "undefined" ||
        typeof this.disabled.to === "undefined" ||
        !this.disabled.to
      ) {
        return false;
      }
      let d = new Date(this.currDate);
      if (this.disabled.to.getFullYear() >= d.getFullYear()) {
        return true;
      }
      return false;
    },
    nextYear() {
      if (this.nextYearDisabled()) {
        return false;
      }
      let d = new Date(this.currDate);
      d.setYear(d.getFullYear() + 1);
      this.currDate = d.getTime();
      this.$emit("changedYear");
    },
    nextYearDisabled() {
      if (
        typeof this.disabled === "undefined" ||
        typeof this.disabled.from === "undefined" ||
        !this.disabled.from
      ) {
        return false;
      }
      let d = new Date(this.currDate);
      if (this.disabled.from.getFullYear() <= d.getFullYear()) {
        return true;
      }
      return false;
    },
    previousDecade() {
      if (this.previousDecadeDisabled()) {
        return false;
      }
      let d = new Date(this.currDate);
      d.setYear(d.getFullYear() - 10);
      this.currDate = d.getTime();
      this.$emit("changedDecade");
    },
    previousDecadeDisabled() {
      if (
        typeof this.disabled === "undefined" ||
        typeof this.disabled.to === "undefined" ||
        !this.disabled.to
      ) {
        return false;
      }
      let d = new Date(this.currDate);
      if (
        Math.floor(this.disabled.to.getFullYear() / 10) * 10 >=
        Math.floor(d.getFullYear() / 10) * 10
      ) {
        return true;
      }
      return false;
    },
    nextDecade() {
      if (this.nextDecadeDisabled()) {
        return false;
      }
      let d = new Date(this.currDate);
      d.setYear(d.getFullYear() + 10);
      this.currDate = d.getTime();
      this.$emit("changedDecade");
    },
    nextDecadeDisabled() {
      if (
        typeof this.disabled === "undefined" ||
        typeof this.disabled.from === "undefined" ||
        !this.disabled.from
      ) {
        return false;
      }
      let d = new Date(this.currDate);
      if (
        Math.ceil(this.disabled.from.getFullYear() / 10) * 10 <=
        Math.ceil(d.getFullYear() / 10) * 10
      ) {
        return true;
      }
      return false;
    },
    /**
     * Whether a day is selected
     * @param {Date}
     * @return {Boolean}
     */
    isSelectedDate(dObj) {
      return (
        this.selectedDate &&
        this.selectedDate.toDateString() === dObj.toDateString()
      );
    },
    /**
     * Whether a day is disabled
     * @param {Date}
     * @return {Boolean}
     */
    isDisabledDate(date) {
      let disabled = false;
      if (typeof this.disabled === "undefined") {
        return false;
      }
      if (typeof this.disabled.dates !== "undefined") {
        this.disabled.dates.forEach((d) => {
          if (date.toDateString() === d.toDateString()) {
            disabled = true;
            return true;
          }
        });
      }
      if (
        typeof this.disabled.to !== "undefined" &&
        this.disabled.to &&
        date < this.disabled.to
      ) {
        disabled = true;
      }
      if (
        typeof this.disabled.from !== "undefined" &&
        this.disabled.from &&
        date > this.disabled.from
      ) {
        disabled = true;
      }
      if (
        typeof this.disabled.days !== "undefined" &&
        this.disabled.days.indexOf(date.getDay()) !== -1
      ) {
        disabled = true;
      }
      return disabled;
    },
    /**
     * Whether a day is highlighted (only if it is not disabled already)
     * @param {Date}
     * @return {Boolean}
     */
    isHighlightedDate(date) {
      if (this.isDisabledDate(date)) {
        return false;
      }
      let highlighted = false;
      if (typeof this.highlighted === "undefined") {
        return false;
      }
      if (typeof this.highlighted.dates !== "undefined") {
        this.highlighted.dates.forEach((d) => {
          if (date.toDateString() === d.toDateString()) {
            highlighted = true;
            return true;
          }
        });
      }
      if (
        this.isDefined(this.highlighted.from) &&
        this.isDefined(this.highlighted.to)
      ) {
        highlighted =
          date >= this.highlighted.from && date <= this.highlighted.to;
      }
      if (
        typeof this.highlighted.days !== "undefined" &&
        this.highlighted.days.indexOf(date.getDay()) !== -1
      ) {
        highlighted = true;
      }
      return highlighted;
    },
    /**
     * Helper
     * @param  {mixed}  prop
     * @return {Boolean}
     */
    isDefined(prop) {
      return typeof prop !== "undefined" && prop;
    },
    /**
     * Whether the selected date is in this month
     * @param {Date}
     * @return {Boolean}
     */
    isSelectedMonth(date) {
      return (
        this.selectedDate &&
        this.selectedDate.getFullYear() === date.getFullYear() &&
        this.selectedDate.getMonth() === date.getMonth()
      );
    },
    /**
     * Whether a month is disabled
     * @param {Date}
     * @return {Boolean}
     */
    isDisabledMonth(date) {
      let disabled = false;
      if (typeof this.disabled === "undefined") {
        return false;
      }
      if (typeof this.disabled.to !== "undefined" && this.disabled.to) {
        if (
          (date.getMonth() < this.disabled.to.getMonth() &&
            date.getFullYear() <= this.disabled.to.getFullYear()) ||
          date.getFullYear() < this.disabled.to.getFullYear()
        ) {
          disabled = true;
        }
      }
      if (typeof this.disabled.from !== "undefined" && this.disabled.from) {
        if (
          (this.disabled.from &&
            date.getMonth() > this.disabled.from.getMonth() &&
            date.getFullYear() >= this.disabled.from.getFullYear()) ||
          date.getFullYear() > this.disabled.from.getFullYear()
        ) {
          disabled = true;
        }
      }
      return disabled;
    },
    /**
     * Whether a year is disabled
     * @param {Date}
     * @return {Boolean}
     */
    isSelectedYear(date) {
      return (
        this.selectedDate &&
        this.selectedDate.getFullYear() === date.getFullYear()
      );
    },
    /**
     * Whether a month is disabled
     * @param {Date}
     * @return {Boolean}
     */
    isDisabledYear(date) {
      let disabled = false;
      if (typeof this.disabled === "undefined" || !this.disabled) {
        return false;
      }
      if (typeof this.disabled.to !== "undefined" && this.disabled.to) {
        if (date.getFullYear() < this.disabled.to.getFullYear()) {
          disabled = true;
        }
      }
      if (typeof this.disabled.from !== "undefined" && this.disabled.from) {
        if (date.getFullYear() > this.disabled.from.getFullYear()) {
          disabled = true;
        }
      }
      return disabled;
    },
    /**
     * Set the datepicker value
     * @param {Date|String|null} date
     */
    setValue(date) {
      if (typeof date === "string") {
        let parsed = new Date(date);
        date = isNaN(parsed.valueOf()) ? null : parsed;
      }
      if (!date) {
        const d = new Date();
        this.currDate = new Date(d.getFullYear(), d.getMonth(), 1).getTime();
        this.selectedDate = null;
        return;
      }
      this.selectedDateTime = new Date(date);
      this.selectedDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      this.currDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        1
      ).getTime();
    },
    init() {
      if (this.value) {
        this.setValue(this.value);
        this.hours = utils.formatTime(this.selectedDateTime.getHours());
        this.minutes = utils.formatTime(this.selectedDateTime.getMinutes());
      }
      if (this.isInline()) {
        this.showDayCalendar();
      }
      this.$nextTick(() => {
        this.calendarHeight = this.$el
          .querySelector(".calendar")
          .getBoundingClientRect().height;
      });
      document.addEventListener(
        "click",
        (e) => {
          if (this.$el && !this.$el.contains(e.target)) {
            if (this.isInline()) {
              return this.showDayCalendar();
            }
            this.close();
          }
        },
        false
      );
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style scoped>
.align-left.calendar {
  left: 0;
}
.align-left.calendar:before {
  right: 110px;
}
</style>
