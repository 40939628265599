<template>
  <section class="shared-table">
    <table cellspacing="0" class="table custom-table">
      <thead>
        <tr>
          <th
            v-for="column in columns"
            @click="sortBy(column.name)"
            :class="{
              active: sortKey == column.name,
              sortable: !column.unsortable
            }"
            :style="column.style"
            :key="column.name"
          >
            {{ column.title }}
            <span
              v-if="!column.unsortable"
              class="arrow"
              :class="arrowClass(column.name)"
            >
              <i class="fa fa-caret-up"></i>
              <i class="fa fa-caret-down"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(entry, index) in filteredData" :key="`${index}-row`">
          <td
            v-for="(column, index) in columns"
            :style="column.style"
            :key="index"
          >
            <span>{{ tableText(column, entry) }}</span>
            <component
              v-if="column.extra"
              :is="column.extra"
              :item="entry"
            ></component>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import * as _ from "lodash";

export default {
  name: "shared-table",
  data() {
    var sortOrders = {};
    this.columns.forEach(function (col) {
      sortOrders[col.name] = 0;
    });
    return {
      sortKey: "",
      sortOrders: sortOrders
    };
  },
  props: {
    data: Array,
    columns: Array,
    searchQuery: String,
    filter: Object
  },
  computed: {
    filteredData: function () {
      var sortKey = this.sortKey;
      var searchQuery = this.searchQuery && this.searchQuery.toLowerCase();
      var order = this.sortOrders[sortKey] || 1;
      var data = this.data;
      var filter = this.filter;

      if (filter) {
        data = data.filter((row) => {
          var passed = false;
          for (let key in filter) {
            passed = row[key] == filter[key];
          }
          return passed;
        });
      }

      if (searchQuery) {
        data = data.filter((row) => {
          return (
            this.columns.filter((column) => {
              if (
                String(row[column.name]).toLowerCase().indexOf(searchQuery) > -1
              )
                return true;
            }).length > 0
          );
        });
      }
      if (sortKey) {
        data = data.slice().sort(function (a, b) {
          a = a[sortKey];
          b = b[sortKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    }
  },
  methods: {
    sortBy: function (key) {
      var self = this;
      var newSortOrders = {};
      this.columns.forEach(function (col) {
        if (col.name != key) {
          newSortOrders[col.name] = 0;
        } else {
          newSortOrders[key] = self.sortOrders[key];
          if (!col.unsortable) {
            newSortOrders[key] = newSortOrders[key] * -1 || 1;
            self.sortKey = key;
            self.sortOrders = newSortOrders;
          }
        }
      });
    },
    arrowClass: function (key) {
      switch (this.sortOrders[key]) {
        case 0:
          return "inactive";
        case -1:
          return "asc";
        case 1:
          return "dsc";
      }
    },
    tableText(column, entry) {
      let maxLength = 20;
      let element = column.computed
        ? column.computed(entry)
        : entry[column.name];

      if (_.isString(element) && !_.includes(element, ",")) {
        return _.truncate(element, {
          length: maxLength
        });
      }

      return element;
    }
  }
};
</script>
