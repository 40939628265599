<template>
  <tbody>
    <TableDataRow
      class="non-selectable"
      v-for="d in data"
      @click.native="onRowClick(d, $event)"
      :key="d.id"
      :id="d.id"
      :data="d"
      :columns="columns"
    />
  </tbody>
</template>

<script>
import TableDataRow from "./table-data-row.vue";
export default {
  name: "table-body",
  props: ["data", "columns", "rowCallback"],
  components: {
    TableDataRow
  },
  methods: {
    onRowClick(data, event) {
      this.rowCallback({ data, event });
    }
  }
};
</script>

<style scoped>
.non-selectable {
  user-select: none;
}
</style>
