<template>
  <PageLayout class="identity-ad-new">
    <ToolBar to="/ads" title="New HTML Embed ad" />
    <PageContentContainer>
      <NewForm />
    </PageContentContainer>
  </PageLayout>
</template>

<script>
import NewForm from "./new-ad-form.vue";
import ToolBar from "../../layout/tool-bar.vue";
import PageLayout from "../../layout/page-layout.vue";
import PageContentContainer from "../../layout/page-content-container.vue";

export default {
  name: "ad-new-html-embed",
  components: { NewForm, ToolBar, PageLayout, PageContentContainer },
  mounted() {
    this.$store.dispatch("getShopOptions");
  }
};
</script>
