import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { createLink } from "apollo-absinthe-upload-link";
import VueApollo from "vue-apollo";
import auth from "./utils/auth";

const graphqlPath = "/graphql-internal";

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: process.env.VUE_APP_API_HOST + graphqlPath
});

const uploadFile = createLink({
  uri: process.env.VUE_APP_API_HOST + graphqlPath
});

// Cache implementation
const cache = new InMemoryCache();

const asyncAuthLink = setContext(
  () =>
    new Promise((success) => {
      auth.getToken().then((token) => {
        success({
          headers: {
            authorization: token ? `Bearer ${token}` : ""
          }
        });
      });
    })
);

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: ApolloLink.from([asyncAuthLink, uploadFile]),
  cache
});

export function createProvider() {
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: "network-only"
      }
    },
    errorHandler(error) {
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    }
  });

  return apolloProvider;
}
