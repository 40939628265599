import * as _ from "lodash";

export const formStruct = {
  name: "",
  language: null,
  about: "",
  icon: null,
  title_main: "",
  title_secondary: "",
  meta_description: ""
};

export function serialize(item) {
  return {
    name: item.name,
    language: item.language,
    about: item.about,
    icon: item.file_small_url,
    title_main: item.title_main,
    title_secondary: item.title_secondary,
    meta_description: item.meta_description
  };
}

export function deserialize(data) {
  let formData = new FormData();

  formData.append("name", data.name);
  formData.append("language_id", data.language.id);
  formData.append("about", data.about);
  if (data.icon && !_.isString(data.icon)) {
    formData.append("icon", data.icon);
  }
  formData.append("title_main", data.title_main);
  formData.append("title_secondary", data.title_secondary);
  formData.append("meta_description", data.meta_description);

  return formData;
}
