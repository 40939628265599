import gql from "graphql-tag";

const deleteIdentityUser = gql`
  mutation DeleteIdentityUser($deleteIdentityUser: DeleteIdentityUserInput!) {
    deleteIdentityUser(deleteIdentityUser: $deleteIdentityUser) {
      status
    }
  }
`;

export default deleteIdentityUser;
