<template>
  <section class="content-toolbar clearfix">
    <Breadcrumb :data="breadcrumbData" class="tools pull-left" />

    <ul class="tools pull-right">
      <li class="ml-1">
        <div class="input-group toolbar-search">
          <input
            v-model="search"
            type="text"
            class="form-control"
            placeholder="Search location"
            aria-describedby="btnGroupAddon"
          />
          <span class="input-group-addon" id="btnGroupAddon">
            <div class="custom-icon search-icon"></div>
          </span>
        </div>
      </li>
      <li class="ml-1">
        <b-button
          @click="emitNew"
          variant="tool"
          :class="buttonStateClass(showNew)"
          class="btn-plus"
        >
          <div class="custom-icon add-circle-icon"></div>
          New
        </b-button>
      </li>
      <li class="ml-1">
        <b-button
          @click="emitImport"
          variant="tool"
          :class="showImport ? 'active' : ''"
        >
          Import
        </b-button>
      </li>
      <li class="ml-1">
        <b-button @click="downloadLocations" variant="tool">
          Download
        </b-button>
      </li>
    </ul>
  </section>
</template>

<script>
import Breadcrumb from "../shared/breadcrumb";
import * as _ from "lodash";

export default {
  name: "locations-toolbar",
  components: { Breadcrumb },
  props: ["shop", "showNew", "showImport"],
  data() {
    return {
      shop_id: this.$route.params.shop_id,
      search: "",
      lastSearched: ""
    };
  },
  created() {
    this.$store.dispatch("getLocationBlob", { shop_id: this.shop_id });
  },
  methods: {
    downloadLocations() {
      window.open(this.downloadLocationsUrl);
    },
    emitNew() {
      this.$emit("show-new");
    },
    emitImport() {
      this.$emit("show-import");
    },
    buttonStateClass(state) {
      return state ? "active" : "";
    },
    onSearch: _.debounce(function (query) {
      if (this.lastSearched != query) {
        this.$store.dispatch("locationSearchChange", this.search);
      }

      this.lastSearched = query;
    }, 500)
  },
  watch: {
    search(query) {
      this.onSearch(query);
    }
  },
  computed: {
    breadcrumbData() {
      return [
        {
          url: "/shops/",
          name: this.shop ? this.shop.name + " Shop Locations" : ""
        }
      ];
    },
    downloadLocationsUrl() {
      let blob = this.$store.getters.locationsFileBlob;
      if (blob) {
        return window.URL.createObjectURL(blob);
      } else return "";
    }
  }
};
</script>
