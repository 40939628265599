export const formStruct = {
  email: "",
  first_name: "",
  last_name: "",
  roles: [],
  password: "",
  password_confirmation: ""
};

export function serialize(item) {
  return {
    email: item.email,
    first_name: item.first_name,
    last_name: item.last_name,
    roles: item.roles,
    password: item.password,
    password_confirmation: item.password_confirmation
  };
}

export function deserialize(data) {
  return JSON.stringify({ admin: data });
}
