<template>
  <div class="data-table-pagination">
    <div class="list">
      <ul>
        <li
          v-for="page in pagination.totalPages"
          :class="{
            active: page === pagination.currentPage,
            disabled: page === pagination.totalPages
          }"
          :key="page"
          @click="onClick(page)"
        >
          {{ page }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: ["pagination"],
  data() {
    return {
      currentPage: this.pagination.currentPage
    };
  },
  methods: {
    onClick(page) {
      if (page !== this.pagination.currentPage) this.$emit("update", page);
    }
  }
};
</script>

<style scoped>
.data-table-pagination {
  margin-top: 16px;
  padding: 2px 5px;
}
.data-table-pagination ul {
  width: max-content;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.data-table-pagination li {
  background-color: #fff;
  color: #000;
  border-radius: 3px;
  border: none;
  cursor: default;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px;

  transition: 200ms background-color;
}
.data-table-pagination li.active {
  background-color: #000;
  color: #fff;
}
.data-table-pagination li:hover {
  cursor: pointer;
  background-color: rgb(228, 228, 228);
}
.data-table-pagination li.disabled {
  cursor: not-allowed;
  background-color: rgb(228, 228, 228);
}
</style>
