<template>
  <div class="parser-iteration">
    <modal
      :show="show"
      @cancel="onCancel()"
      :customerClass="'modal-type-dialog'"
      :closeIcon="false"
    >
      <div slot="header"></div>

      <div class="panel-background p-1 mb-1 border text-xs-left">
        <div
          v-for="(item, index) in messages"
          class="row font-small mb-1"
          :key="index"
        >
          <div class="col-xs-4">{{ changeToTime(item.inserted_at) }}</div>
          <div class="col-xs-8">{{ item.message }}</div>
        </div>
      </div>

      <div slot="footer">
        <b-button variant="modal" @click="onCancel()"> close </b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import modal from "../../shared/modal";
import toLocalDate from "../../../mixins/toLocalDate";
import moment from "moment";

export default {
  name: "Iteration",
  components: { modal },
  mixins: [toLocalDate],
  props: ["messages", "show"],
  methods: {
    onCancel() {
      this.$emit("cancel");
    },
    changeToTime(date) {
      let toLocalDate = this.toLocalDate(date);
      return moment(toLocalDate).format("HH:mm DD-MM-YYYY");
    }
  }
};
</script>
