<template>
  <div class="auth-wrapper">
    <logo />
    <div class="alert alert-danger" v-if="error">
      <span>{{ error }}</span>
    </div>
    <div class="button-container">
      <GoogleButton @sign-in="signIn" />
    </div>
  </div>
</template>

<script>
import auth from "../../utils/auth";
import logo from "../shared/logo";
import GoogleButton from "../molecules/GoogleButton.vue";
import MeQuery from "../../graphql/queries/Me";
import { GOOGLE_PROVIDER } from "@/utils/google";

export default {
  name: "login",
  components: { logo, GoogleButton },
  data() {
    return {
      error: "",
      signedIn: false
    };
  },

  apollo: {
    me: {
      query: MeQuery,
      skip() {
        return !this.signedIn;
      },
      result({ data }) {
        if (data && data.me) this.storeUserAndGoToBrochures(data.me);
        else
          this.error = "Error logging into Google Sign In, please try again.";
      }
    }
  },

  methods: {
    storeUserAndGoToBrochures(me) {
      this.$store.dispatch("login", me).then(() => {
        const user = this.$store.getters.user;
        if (user) this.$router.push({ path: "/brochures" });
        else
          this.error = "Error logging into Google Sign In, please try again.";
      });
    },
    async signIn(credential) {
      try {
        await auth.signIn(GOOGLE_PROVIDER, credential);
        this.signedIn = true;
        this.$apollo.queries.me.refresh();
      } catch (error) {
        this.error = "Error logging into Google Sign In, please try again.";
      }
    }
  }
};
</script>
