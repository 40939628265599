import gql from "graphql-tag";

const meQuery = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
      roles
    }
  }
`;

export default meQuery;
