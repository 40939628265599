<template>
  <section ref="shopsContainer" class="shops content">
    <shops-toolbar />

    <el-table v-loading="shopsLoading" :data="shops" stripe border class="mt-2">
      <div slot="empty">Sorry, empty table :(</div>

      <el-table-column
        :resizable="resizableColumn"
        label="Name"
        prop="name"
        min-width="200"
        sortable
      >
        <template slot-scope="scope">{{
          truncat(scope.row.name, 20)
        }}</template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Regions"
        prop="regions"
        min-width="170"
        sortable
      >
        <template slot-scope="scope">{{
          getColumns(scope.row.regions)
        }}</template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Categories"
        prop="categories"
        min-width="160"
        sortable
      >
        <template slot-scope="scope">{{
          getColumns(scope.row.categories)
        }}</template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="LP"
        prop="last_page"
        min-width="80"
        sortable
      >
        <template slot-scope="scope">{{
          getState(scope.row.last_page)
        }}</template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="HS"
        prop="hotspot_mode"
        min-width="80"
        sortable
      >
        <template slot-scope="scope">{{ scope.row.hotspot_mode }}</template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Ads"
        prop="show_ads"
        min-width="80"
        sortable
      >
        <template slot-scope="scope">{{
          getState(scope.row.show_ads)
        }}</template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Ads 2"
        prop="show_ads_2"
        min-width="80"
        sortable
      >
        <template slot-scope="scope">{{
          getState(scope.row.show_ads_2)
        }}</template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="CL"
        prop="customer_level"
        min-width="80"
        sortable
      ></el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Brochures"
        min-width="460"
      >
        <template slot-scope="scope">
          <div class="row col-xs-12 flex-items-xs-middle flex-items-xs-between">
            <div>
              <span>{{ getCount(scope.row.active_count) }} active,</span>
              <span
                >{{ getCount(scope.row.not_published_count) }} not
                published,</span
              >
              <span>{{ getCount(scope.row.expired_count) }} expired</span>
            </div>
            <div>
              <table-shop-controls
                :item="scope.row"
                @onStats="showStats($event)"
              />
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @current-change="pageChange($event)"
      layout="pager"
      :page-count="totalPages"
      class="text-xs-center mt-1"
    ></el-pagination>

    <stats :data="statData" :type="statType" />
  </section>
</template>

<script>
import shopsToolbar from "./shops-toolbar.vue";
import sharedTable from "../shared/table.vue";
import tableShopControls from "./table-controls.vue";
import filters from "../../mixins/filterMixin";
import stats from "../stats/index.vue";
import truncat from "../../mixins/truncatMixin";

export default {
  name: "shops",
  components: { shopsToolbar, sharedTable, tableShopControls, stats },
  mixins: [filters, truncat],
  data() {
    return {
      statData: null,
      statType: "shops",
      resizableColumn: false
    };
  },
  computed: {
    shops() {
      return this.$store.getters.allShops;
    },
    shopsLoading() {
      return this.$store.getters.allShopsLoading;
    },
    totalPages() {
      return this.$store.getters.shopsTotalPages;
    }
  },
  created() {
    this.$store.dispatch("fetchShops");
  },
  beforeDestroy() {
    this.$store.dispatch("shopReset");
  },
  methods: {
    getCount(value) {
      return value ? value : 0;
    },
    getState(value) {
      return value ? "Yes" : "No";
    },
    getColumns(array) {
      if (array.length) {
        return array
          .map((row) => {
            return row.name;
          })
          .join(", ");
      } else {
        return "-";
      }
    },
    showStats(stat) {
      this.statData = stat;
      this.$store.dispatch("statsOpen");
    },
    pageChange(page) {
      this.$store.dispatch("shopPageChange", page);
    }
  }
};
</script>
