export const RECEIVE_BROCHURES = "RECEIVE_BROCHURES";
export const PUSH_BROCHURES = "PUSH_BROCHURES";
export const CREATE_BROCHURE = "CREATE_BROCHURE";
export const UPDATE_BROCHURE = "UPDATE_BROCHURE";
export const PUT_SORT_BROCHURES = "PUT_SORT_BROCHURES";
export const PUT_STASUSES_BROCHURES = "PUT_STASUSES_BROCHURES";
export const PUT_FILTER_BROCHURES = "PUT_FILTER_BROCHURES";
export const UPDATE_COUNTERS = "UPDATE_COUNTERS";
export const UPDATE_UTM_COUNTER = "UPDATE_UTM_COUNTER";

export const RECEIVE_BROCHURE_PAGES = "RECEIVE_BROCHURE_PAGES";
export const HIDE_BROCHURE_PAGES = "HIDE_BROCHURE_PAGES";
export const CHANGE_PAGE_INDEX = "CHANGE_PAGE_INDEX";
export const INCREMENT_PAGE_ZOOM = "INCREMENT_PAGE_ZOOM";
export const DECREMENT_PAGE_ZOOM = "DECREMENT_PAGE_ZOOM";
export const DEFAULT_PAGE_ZOOM = "DEFAULT_PAGE_ZOOM";
export const DECREMENT_PAGE_INDEX = "DECREMENT_PAGE_INDEX";
export const INCREMENT_PAGE_INDEX = "INCREMENT_PAGE_INDEX";
export const RESET_SLIDER = "RESET_SLIDER";

export const RECEIVE_LOCATIONS = "RECEIVE_LOCATIONS";
export const RECEIVE_LOCATIONS_REGIONS = "RECEIVE_LOCATIONS_REGIONS";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_ALL_LOCATIONS = "DELETE_ALL_LOCATIONS";
export const IMPORT_LOCATION = "IMPORT_LOCATION";

export const RECEIVE_LANGUAGES = "RECEIVE_LANGUAGES";
export const RECEIVE_REGIONS = "RECEIVE_REGIONS";

export const RECEIVE_CATEGORIES = "RECEIVE_CATEGORIES";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

export const RECEIVE_SHOPS = "RECEIVE_SHOPS";
export const RECEIVE_SHOP = "RECEIVE_SHOP";
export const RECEIVE_SHOP_BROCHURES = "RECEIVE_SHOP_BROCHURES";
export const SEARCH_SHOPS = "SEARCH_SHOPS";
export const RESET_SHOPS = "RESET_SHOPS";

export const OPEN_STATS = "OPEN_STATS";
export const CLOSE_STATS = "CLOSE_STATS";
export const GET_STATS = "GET_STATS";

export const RECEIVE_PARSERS_CLASSES = "RECEIVE_PARSERS_CLASSES";
export const RECEIVE_PARSERS = "RECEIVE_PARSERS";
export const RECEIVE_ACTIVE_PARSER_CLASS = "RECEIVE_ACTIVE_PARSER_CLASS";
export const RECEIVE_ACTIVE_PARSER = "RECEIVE_ACTIVE_PARSER";
export const RECEIVE_PARSER_ITERATIONS = "RECEIVE_PARSER_ITERATIONS";
export const RECEIVE_PARSER_ACTIVE_ITERATION =
  "RECEIVE_PARSER_ACTIVE_ITERATION";
export const CREATE_PARSER = "CREATE_PARSER";
export const RECEIVE_LAST_CREATED_PARSER_ITERATIONS =
  "RECEIVE_LAST_CREATED_PARSER_ITERATIONS";

export const RECEIVE_OFFERS = "RECEIVE_OFFERS";
export const RECEIVE_NEW_OFFERS = "RECEIVE_NEW_OFFERS";

export const RECEIVE_USERS = "RECEIVE_USERS";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
