<template>
  <img
    :src="mainPhoto"
    :style="sizeZoomStyle"
    :class="zoomStateClass"
    ref="mainPhoto"
    class="slider__mainPhoto"
    alt=""
  />
</template>

<script>
import defaultMixin from "./defaultMixin";

export default {
  name: "MainPhoto",
  mixins: [defaultMixin],
  computed: {
    pages() {
      return this.$store.getters.sliderPages;
    },
    mainPhoto() {
      if (this.pages) {
        return this.pages[this.index].file_medium_url;
      } else return "";
    },
    zoomStateClass() {
      return this.zoom ? "-moved" : "";
    },
    sizeZoomStyle() {
      if (this.zoom) {
        let scales = [65, 70, 75, 80, 85];
        return `height: ${scales[this.zoom]}vh`;
      } else return "";
    }
  }
};
</script>
