<template>
  <div draggable="true" class="draggable">
    <slot></slot>
  </div>
</template>

<script>
import { draggableMixin } from "../../mixins/draggableMixin.js";
import { api } from "../../store/api";

export default {
  mixins: [draggableMixin],
  name: "draggable",
  props: ["data", "moveable"],
  methods: {
    onDragstart(e) {
      let crt = this.$el.cloneNode(true);
      crt.style.maxWidth = this.$el.offsetWidth + "px";
      // crt.style.transform  = "rotate(7deg);"
      crt.classList.add("tmp-object");
      document.body.appendChild(crt);
      e.dataTransfer.setData("data", this.data);
      e.dataTransfer.setData("moveable", this.data);
      e.dataTransfer.setDragImage(crt, 0, 0);

      this.$children[0].$el.classList.add("dragstart");
    },
    onDragend(e) {
      let elements = document.getElementsByClassName("tmp-object");
      for (let i = 0; i < elements.length; i++) {
        elements[i].remove();
      }
      this.$children[0].$el.classList.remove("dragstart");
    },
    onDrop(e) {
      if (!this.checkMoveability(e)) {
        alert(
          "This brochure has currupted date. Edit it before changing status"
        );
        this.removeClasses(e);
        return;
      }
      if (this.checkBrochuresIDs(e)) {
        this.showRegionsModal(this.fetchBrochuresIDs(e));
        this.removeClasses(e);
      }
      this.removeClasses(e);
    },
    checkMoveability(e) {
      return e.dataTransfer.getData("moveable");
    },
    checkBrochuresIDs(e) {
      let brochures_ids = this.fetchBrochuresIDs(e);
      return brochures_ids.base_brochure_id != brochures_ids.merge_brochure_id;
    },
    fetchBrochuresIDs(e) {
      return {
        merge_brochure_id: e.dataTransfer.getData("data"),
        base_brochure_id:
          e.currentTarget.children[0].getAttribute("brochure-id")
      };
    },
    removeClasses(e) {
      this.$el.children[0].classList.remove("dragstart");
      e.currentTarget.children[0].classList.remove("dragover");
    },
    showRegionsModal(params) {
      api.getAvailableRegions(params, (response) => {
        let modal_params = {
          merge_brochure_id: params.merge_brochure_id,
          base_brochure_id: params.base_brochure_id,
          regions: response.regions
        };
        if (modal_params.regions.length > 0) {
          this.$emit("showRegionsModal", modal_params);
        }
      });
    }
  }
};
</script>
