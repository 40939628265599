import gql from "graphql-tag";

const identityUsers = gql`
  query IdentityUsers(
    $identityUsers: IdentityUsersInput!
    $pagination: PaginationInput
  ) {
    identityUsers(identityUsers: $identityUsers, pagination: $pagination) {
      entries {
        email
        firstName
        id
        lastName
        roles
      }
      pagination {
        currentPage
        nextPage
        previousPage
        totalPages
      }
    }
  }
`;

export default identityUsers;
