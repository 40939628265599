<template>
  <div class="content">
    <div class="content-toolbar">
      <span class="page-title">Ordered brochures</span>

      <ul class="tool-buttons pull-right">
        <li class="tool-button notification-filter-select">
          <multiselect
            v-model="filter.language"
            :disabled="brochuresLoading"
            @input="dispatchFilterChange"
            :options="languages"
            placeholder="Language"
            label="name"
            track-by="id"
            :allow-empty="false"
          >
          </multiselect>
        </li>
      </ul>
    </div>

    <div v-if="!brochuresLoading">
      <div
        v-if="!isOfferBrochures"
        class="panel-background radius-4 border mt-2 p-2"
      >
        You do not have ordered brochures
      </div>

      <draggable-offer
        @add="changeOffer($event)"
        @update="changeOffer($event)"
        @remove="removeOffer($event)"
        v-model="offerBrochures"
        :options="draggable"
        id="offerBrochures"
        class="brochures-list"
      >
        <div
          v-for="item in offerBrochures"
          class="col-md-4 col-lg-4 col-xl-3 mb-1"
          :key="item.id"
          :id="item.id"
        >
          <brochure-item :item="item" :options="options" />
        </div>
      </draggable-offer>

      <div class="content-toolbar mt-2">
        <div class="page-title">New brochures</div>
      </div>

      <div v-if="!isOfferNew" class="panel-background radius-4 border mt-2 p-2">
        You do not have brochures
      </div>

      <draggable-offer
        v-model="offerNew"
        :options="draggable"
        id="offerNew"
        class="brochures-list"
      >
        <div
          v-for="item in offerNew"
          class="col-md-4 col-lg-4 col-xl-3 mb-1"
          :id="item.id"
          :key="item.id"
        >
          <brochure-item :item="item" :options="options" />
        </div>
      </draggable-offer>
    </div>
    <div v-else class="panel-background radius-4 border mt-2 p-2">
      <loading> </loading>
    </div>
  </div>
</template>

<script>
import brochureItem from "../brochures/brochure-item.vue";
import loading from "../shared/loading";

export default {
  name: "offers",
  components: { brochureItem, loading },
  mixins: [],
  props: [],
  data() {
    return {
      options: false,
      draggable: {
        group: "brochures"
      },
      filter: {
        language: undefined
      }
    };
  },
  computed: {
    offerNew: {
      get() {
        return this.$store.getters.offerNew;
      },
      set(value) {
        this.$store.commit("RECEIVE_NEW_OFFERS", { new_brochures: value });
      }
    },
    offerBrochures: {
      get() {
        return this.$store.getters.offerBrochures;
      },
      set(value) {
        this.$store.commit("RECEIVE_OFFERS", { brochures: value });
      }
    },
    isOfferNew() {
      return this.offerNew && this.offerNew.length;
    },
    isOfferBrochures() {
      return this.offerBrochures && this.offerBrochures.length;
    },
    languages() {
      return this.$store.getters.allLanguages;
    },
    nolanguageSelected() {
      return this.filter.language === undefined;
    },
    brochuresLoading() {
      return (
        this.$store.getters.offerBrochuresLoading ||
        this.$store.getters.offerNewLoading
      );
    }
  },
  beforeDestroy() {
    this.$store.dispatch("offerFilterReset");
  },
  watch: {
    languages: function (languages) {
      if (this.nolanguageSelected && languages && languages.length > 0) {
        const [first] = languages;
        this.setLanguage(first);
        this.dispatchFilterChange();
      }
    }
  },
  methods: {
    setLanguage(language) {
      this.filter.language = language;
    },
    changeOffer(e) {
      let index = e.newIndex;
      let brochure_id = e.clone.id;
      let position = this.offerBrochures[index + 1].position;

      this.$store.dispatch("updateOfferPosition", { brochure_id, position });
    },
    removeOffer(e) {
      let position = null;
      let brochure_id = e.clone.id;

      this.$store.dispatch("updateOfferPosition", { brochure_id, position });
    },
    dispatchFilterChange() {
      this.$store.dispatch("offerFilterChange", this.filter);
    }
  }
};
</script>
