export default {
  computed: {
    index() {
      return this.$store.getters.pageIndex;
    },
    length() {
      return this.$store.getters.pagesLength;
    },
    zoom() {
      return this.$store.getters.zoom;
    },
    zoomMax() {
      return this.$store.getters.zoomMax;
    },
    zoomStateClass() {
      return this.zoom ? "-hide" : "";
    }
  }
};
