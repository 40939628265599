import gql from "graphql-tag";

const adsAdvertisement = gql`
  query adsAdvertisements(
    $adsAdvertisements: AdsAdvertisementsInput!
    $pagination: PaginationInput
  ) {
    adsAdvertisements(
      adsAdvertisements: $adsAdvertisements
      pagination: $pagination
    ) {
      entries {
        customerClient {
          id
          name
        }
        adType
        platformType
        displayAt
        displayStartAt
        displayStopAt
        id
        status
      }
    }
  }
`;

export default adsAdvertisement;
