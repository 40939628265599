<template>
  <div class="brochure-stats-modal">
    <modal :show.sync="show" @cancel="onClose" :large="true">
      <div slot="header">
        <span class="flag">
          <img :src="brochure.flag" />
        </span>
        <h3 class="title">{{ brochure.name }}</h3>
        <div class="list-status">
          <b>
            on
            <u class="text-capitalize">{{ getCurrentList }}</u> list
          </b>
        </div>
      </div>

      <section>
        <el-table :data="deeplinkStats" stripe border class="mt-2">
          <div slot="empty">Sorry, no hotspots</div>

          <el-table-column label="Hotspot" prop="url" sortable>
            <template slot-scope="scope">
              <a v-bind:href="scope.row.url">{{ scope.row.url }}</a>
            </template>
          </el-table-column>
        </el-table>
      </section>
    </modal>
  </div>
</template>

<script>
import modal from "../shared/modal";
import truncat from "../../mixins/truncatMixin";

export default {
  name: "brochure-stats",
  components: { modal },
  mixins: [truncat],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    brochure: {
      type: Object
    },
    version: {
      type: String
    }
  },
  computed: {
    getCurrentList() {
      return this.brochure.state;
    },
    stats() {
      let data = this.$store.getters.statsData;

      if (data) {
        return data.statistics;
      } else {
        return null;
      }
    },
    deeplinkStats() {
      if (this.stats) {
        if (this.stats.hotspots.length > 0) {
          return this.stats.hotspots;
        }
        return this.stats.hotspots;
      } else {
        return [];
      }
    }
  },
  created() {
    this.$store.dispatch("fetchStats", {
      type: "brochures",
      id: this.brochure.id,
      params: {
        version: this.version
      }
    });
  },
  methods: {
    copyToClipboard(url) {
      window.navigator.clipboard.writeText(url);
    },
    onClose() {
      this.$emit("close");
    }
  }
};
</script>
