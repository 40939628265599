<template>
  <section class="brochures">
    <brochure-merge-regions
      v-if="showRegions"
      :show.sync="showRegions"
      :mergeParams.sync="mergeParams"
      @close="onCloseRegions"
    />
    <div
      ref="brochureContainer"
      class="brochures-list"
      v-if="!showDuplicateBrochures"
    >
      <div
        v-for="item in filteredBrochures"
        :key="item.id"
        class="col-md-4 col-lg-4 col-xl-3 mb-1"
      >
        <draggable
          @showRegionsModal="onShowRegions"
          :data="item.id"
          :moveable="isBrochureMoveable(item)"
        >
          <brochure-item :item="item" />
        </draggable>
      </div>
    </div>
    <duplicate-brochures v-if="showDuplicateBrochures" />
    <brochures-toolbar
      @show-duplicate="showDuplicateBrochures = true"
      @hide-duplicate="showDuplicateBrochures = false"
    />
    <Slider />
  </section>
</template>

<script>
import brochureItem from "./brochure-item.vue";
import brochuresToolbar from "./brochures-toolbar.vue";
import duplicateBrochures from "./duplicate-brochures.vue";
import draggable from "../shared/draggable";
import Slider from "../slider/Slider";
import brochureMergeRegions from "./brochure-merge-regions.vue";

export default {
  name: "brochures",
  components: {
    brochureItem,
    brochuresToolbar,
    duplicateBrochures,
    draggable,
    Slider,
    brochureMergeRegions
  },
  computed: {
    filteredBrochures() {
      return this.$store.getters.filteredBrochures;
    }
  },
  data() {
    return {
      showDuplicateBrochures: false,
      showRegions: false,
      mergeParams: {}
    };
  },
  methods: {
    scrollHandler() {
      let rect = this.$refs.brochureContainer.getClientRects()[0];
      let trigger_point = rect.bottom - 300;

      if (trigger_point <= window.innerHeight) {
        this.fetchBrochures();
      }
    },
    fetchBrochures() {
      this.$store.dispatch("getAllBrochures");
    },
    isBrochureMoveable(item) {
      return item.published_at && item.active_from && item.expire_after;
    },
    onShowRegions(params) {
      this.mergeParams = params;
      this.showRegions = true;
    },
    onCloseRegions() {
      this.showRegions = false;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler, true);
    this.$store.dispatch("getAllRegions");
    this.$store.dispatch("fetchCategories");
    this.$store.dispatch("getShopOptions");
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandler, true);
  }
};
</script>
