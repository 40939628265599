<template>
  <div class="form parser-info">
    <div class="row">
      <div class="col-xs-6">
        <label>URL</label>

        <div class="form-control form-control-sm no-background">
          {{ parser.url }}
        </div>
      </div>

      <div class="col-xs-6">
        <label>Shop</label>

        <div class="form-control form-control-sm no-background">
          {{ parser.shop.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  props: ["parser"]
};
</script>
