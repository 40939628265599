<template>
  <div class="new-admin">
    <div slot="header">
      <h3 class="title">Edit Admin</h3>
    </div>
    <admin-form
      ref="adminForm"
      :struct="formStruct"
      @update="updateData"
      :admin="admin"
      :passwordRequired="false"
    />
    <div slot="footer">
      <b-button variant="modal" @click="back"> cancel </b-button>
      <b-button variant="modal" @click="onSubmit()"> save </b-button>
    </div>
  </div>
</template>

<script>
import adminForm from "../form";
import * as helper from "../helper.js";

export default {
  name: "edit-admin",
  components: { adminForm },
  data() {
    return {
      formStruct: helper.serialize(this.admin),
      data: {}
    };
  },

  methods: {
    onSubmit() {
      const form = this.$refs.adminForm;
      form.validateAll();
      if (form.isValid()) {
        const params = helper.deserialize(this.data);
        this.$store.dispatch("updateAdmin", {
          id: this.admin.id,
          params: params
        });
      }
    },
    updateData(data) {
      this.data = data;
    },
    back() {
      this.$router.go(-1);
    }
  },
  props: {
    admin: {
      type: Object,
      required: true
    }
  }
};
</script>
