<template>
  <div class="categories content">
    <Breadcrumb v-if="breadcrumbData" :data="breadcrumbData" />

    <Info :parser="activeParser" />

    <el-table v-if="isIterations" :data="iterations" stripe border class="mt-2">
      <el-table-column
        :resizable="resizableColumn"
        label="Started At"
        prop="id"
        width="219"
        sortable
      >
        <template slot-scope="scope">
          {{ fetchLastDate(scope.row.messages) }}
        </template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="History Item"
        prop="message"
        class-name="border-r-n"
      >
        <template slot-scope="scope">
          {{ fetchLastMessage(scope.row.messages) }}
        </template>
      </el-table-column>

      <el-table-column :resizable="resizableColumn" label="State" width="180">
        <template slot-scope="scope">
          <State
            @show="onShow($event)"
            :state="fetchLastMessageState(scope.row.messages)"
            :iterationId="scope.row.id"
          />
        </template>
      </el-table-column>
    </el-table>

    <Iteration
      v-if="isIteration"
      :messages="activeIteration.messages"
      :show="isShowIteration"
      @cancel="onCancel()"
    />

    <div v-if="!isIterations" class="panel-background radius-4 border mt-2 p-2">
      Your iterations is empty!
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../shared/breadcrumb";
import Info from "../elements/info";
import State from "../elements/state";
import Iteration from "../elements/iteration.vue";
import toLocalDate from "../../../mixins/toLocalDate";
import * as _ from "lodash";
import moment from "moment";
export default {
  name: "Parser",
  components: { Breadcrumb, Info, State, Iteration },
  mixins: [toLocalDate],
  data() {
    return {
      showIteration: false,
      parserId: false,
      resizableColumn: false
    };
  },
  computed: {
    activeParser() {
      return this.$store.getters.activeParser;
    },
    iterations() {
      return this.$store.getters.allParserIterations;
    },
    activeIteration() {
      return this.iterations.find((iteration) => {
        return iteration.id == this.parserId;
      });
    },
    activeParserClass() {
      return this.$store.getters.activeParserClass;
    },
    isIterations() {
      return this.iterations && this.iterations.length;
    },
    isIteration() {
      return this.showIteration && this.activeIteration.messages;
    },
    isShowIteration() {
      return this.showIteration;
    },
    breadcrumbData() {
      return [
        {
          name: "Parser:" + this.activeParserClass.class,
          url: "/parsers/"
        },
        {
          name: this.activeParser.name,
          url: "/parsers/" + this.$route.params.class,
          flag: this.activeParser.language.file_small_url
        }
      ];
    }
  },
  beforeCreate() {
    this.$store.dispatch("fetchParserIterations", this.$route.params.id);
    this.$store.dispatch("fetchActiveParser", this.$route.params);
  },
  methods: {
    fetchLastMessage(messages) {
      return _.last(messages).message;
    },
    fetchLastMessageState(messages) {
      return _.last(messages).state;
    },
    fetchLastDate(messages) {
      let date = this.toLocalDate(_.last(messages).inserted_at);
      return moment(date).format("HH:mm DD-MM-YYYY");
    },
    onShow(id) {
      this.parserId = id;
      this.showIteration = true;
    },
    onCancel() {
      this.showIteration = false;
    }
  }
};
</script>
