import { api } from "../api";

const state = {
  languages: {}
};

const mutations = {
  setDisclaimers(state, { languages }) {
    state.languages = languages;
  }
};

const actions = {
  load({ commit }) {
    api.fetch("/config", {}, (data) => commit("setDisclaimers", data));
  },

  save({ commit }, data) {
    const { language } = data;

    api.update("/config", { languages: { [language]: data } }, (data) =>
      commit("setDisclaimers", data)
    );
  }
};

export default {
  namespaced: true,

  state,
  mutations,
  actions
};
