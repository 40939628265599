<template>
  <div class="import-location location-form">
    <div class="header">
      <span class="page-title">Import Shop Location</span>
    </div>
    <div v-if="showWorkInProgress" class="work-in-progress-bar">
      Job for import locations has been created. Please wait a while and refresh
      this page. New locations will be accessible after job is finished. The
      process can take few minutes.
    </div>
    <table v-if="importLog.length > 0">
      <tr
        v-for="(importLogItem, index) in importLog"
        :key="`import-log-${index}`"
      >
        <td :class="`table-${importLogItem.type}`">
          {{ importLogItem.message }}
        </td>
      </tr>
    </table>
    <form @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-xs-12 col-md-12 col-lg-5 big-input">
          <fieldset class="form-group row">
            <label class="col-form-label col-xs-12">Region *</label>
            <div :class="selectStateClass($v.region.$error)" class="col-xs-12">
              <multiselect
                v-model="region"
                :options="regions"
                @input="$v.region.$touch()"
                placeholder="Select Region"
                label="name"
                track-by="name"
              >
              </multiselect>
              <label
                v-if="$v.region.$error"
                class="col-form-label col-xs-12 error-label"
              >
                You must select at least one
              </label>
            </div>
          </fieldset>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-2">
          <div class="form-group row">
            <label class="col-form-label col-xs-12">Choose import option</label>
            <div role="group col-md-12" class="btn-group form-button-group">
              <div class="row">
                <div class="col-xs-6">
                  <div class="btn btn-tool btn-sm">
                    <input
                      v-model="state"
                      type="radio"
                      class="btn btn-tool btn-sm"
                      :value="states[0]"
                    />
                    <span class="text-uppercase">from url</span>
                  </div>
                </div>
                <div class="col-xs-6">
                  <div class="btn btn-tool btn-sm">
                    <input
                      v-model="state"
                      type="radio"
                      class="btn btn-tool btn-sm"
                      :value="states[1]"
                    />
                    <span class="text-uppercase">from csv</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="state == 'fromLink'"
          class="col-xs-12 col-md-6 col-lg-5 big-input"
        >
          <b-form-input
            v-model="openingsurengids"
            type="text"
            label="Openingsurengids"
            placeholder="Type text..."
            size="sm"
            :state="urlError ? 'danger' : ''"
          />
          <label v-if="urlError">{{ urlError }}</label>
        </div>
        <template v-if="state == 'fromFile'">
          <div class="col-lg-2 upload-icon-form">
            <div class="form-group row">
              <label class="col-form-label col-lg-12">CSV file</label>
              <div class="col-xs-12 col-lg-7">
                <input
                  id="icon_input"
                  @change="changeFile"
                  type="file"
                  hidden
                />
                <b-button
                  variant="modal"
                  @click="chooseFile('icon_input')"
                  class="btn-narrow font-small"
                >
                  choose file
                </b-button>
                <label v-if="csvError">{{ csvError }}</label>
              </div>
              <div class="col-xs-12 col-lg-5 input-value pr-0 pl-0">
                <label v-if="!csv">No file selected</label>
                <label v-if="csv">{{ csv.name }}</label>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="footer">
        <b-button variant="modal" @click="discard" class="ml-1">
          discard
        </b-button>
        <button type="submit" class="btn btn-modal btn-md ml-1">create</button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import formStateMixin from "../../mixins/formStateMixin";
import { Socket } from "phoenix";

export default {
  name: "import-location",
  mixins: [validationMixin, formStateMixin],
  props: ["shop"],
  data() {
    return {
      states: ["fromLink", "fromFile"],
      state: "fromLink", //["fromLink", "fromFile"]
      openingsurengids: "",
      urlError: null,
      csv: null,
      csvError: null,
      region: null,
      showWorkInProgress: false,
      socket: null,
      importLog: []
    };
  },
  computed: {
    regions() {
      return this.$store.getters.allLocationsRegions;
    },
    importFromUrl() {
      return {
        region_id: this.region.id,
        openingsurengids: this.openingsurengids,
        shop_id: this.shop.id
      };
    },
    importFromCsv() {
      let fd = new FormData();

      fd.append("region_id", this.region.id);
      fd.append("csv_file", this.csv);
      fd.append("shop_id", this.shop.id);

      return fd;
    }
  },
  validations: {
    region: {
      required
    }
  },
  methods: {
    discard() {
      this.$emit("discard");
    },
    chooseFile(inputId) {
      document.getElementById(inputId).click();
    },
    changeFile(event) {
      this.csv = event.target.files[0];
    },
    onSubmit() {
      if (this.state == "fromLink") {
        if (this.openingsurengids.length != 0) {
          this.showWorkInProgress = true;
          this.$store
            .dispatch("importLocation", this.importFromUrl)
            .finally(() => (this.showWorkInProgress = false));
        } else {
          this.urlError = "Can't be blank";
        }
      } else {
        if (this.csv) {
          this.showWorkInProgress = true;
          this.$store
            .dispatch("importLocationFromCsv", this.importFromCsv)
            .finally(() => (this.showWorkInProgress = false));
        } else {
          this.csvError = "Can not be empty";
        }
      }
    }
  },
  created() {
    const socket = new Socket(process.env.VUE_APP_WSS_HOST + "/socket");
    socket.connect();

    const channelName = `locations:${this.shop.id}`;
    const channel = socket.channel(channelName);
    channel
      .join()
      .receive("ok", (resp) =>
        console.info(`Successfully joined ${channelName}`, resp)
      )
      .receive("error", (resp) =>
        console.info(`Failed to join ${channelName}`, resp)
      );

    channel.on("status", (data) => {
      this.importLog.push(data);
    });
  }
};
</script>
