<template>
  <div class="admin-form">
    <form @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-md-5">
          <b-form-input
            v-model="data.email"
            @input="$v.data.email.$touch()"
            type="text"
            label="Email *"
            :state="$v.data.email.$error ? 'danger' : ''"
            placeholder="Type text..."
            size="sm"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <b-form-input
            v-model="data.first_name"
            @input="$v.data.first_name.$touch()"
            type="text"
            label="First name *"
            :state="$v.data.first_name.$error ? 'danger' : ''"
            placeholder="Type text..."
            size="sm"
          />
        </div>
        <div class="col-md-5">
          <b-form-input
            v-model="data.last_name"
            @input="$v.data.last_name.$touch()"
            type="text"
            label="Last name *"
            :state="$v.data.last_name.$error ? 'danger' : ''"
            placeholder="Type text..."
            size="sm"
          />
        </div>
      </div>

      <div class="row">
        <header class="col-xs-12 jaf-form-label">Admin roles</header>
        <div class="col-md-5" v-for="role in adminRoles" :key="role">
          <BaseCheckbox
            class="capitalize-label"
            :value="role"
            :label="readable(role)"
            v-model="data.roles"
          />
        </div>
      </div>
      <div class="row" v-if="passwordRequired">
        <div class="col-md-5">
          <b-form-input
            v-model="data.password"
            @input="$v.data.password.$touch()"
            type="password"
            label="Password *"
            :state="$v.data.password.$error ? 'danger' : ''"
            placeholder="Type text..."
            size="sm"
          />
        </div>
        <div class="col-md-5">
          <b-form-input
            v-model="data.password_confirmation"
            @input="$v.data.password_confirmation.$touch()"
            type="password"
            label="Password confirmation *"
            :state="$v.data.password_confirmation.$error ? 'danger' : ''"
            placeholder="Type text..."
            size="sm"
          />
        </div>
      </div>
      <div v-else><br /></div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import Vue from "vue";
import multiselectImg from "../../mixins/multiselectImg.js";
import { adminRoles } from "../../constants";
import BaseCheckbox from "../atoms/BaseCheckbox";

export default {
  name: "admin-form",
  mixins: [validationMixin, multiselectImg],
  computed: {
    languages() {
      return this.$store.getters.allLanguages;
    }
  },
  components: {
    BaseCheckbox
  },
  props: {
    struct: {
      type: Object
    },
    admin: {
      type: Object
    },
    passwordRequired: {
      type: Boolean,
      default: true
    }
  },
  validations: {
    data: {
      email: {
        required
      },
      first_name: {
        required
      },
      last_name: {
        required
      },
      password: {
        required: requiredIf(function () {
          return this.passwordRequired;
        })
      },
      password_confirmation: {
        required: requiredIf(function () {
          return this.passwordRequired;
        })
      }
    }
  },
  data() {
    return {
      data: Vue.util.extend({}, this.struct),
      adminRoles: adminRoles,
      selectedRoles: []
    };
  },
  methods: {
    isValid() {
      return !this.$v.$invalid;
    },
    validateAll() {
      this.$v.$touch();
    },
    readable(label) {
      return label.replace("_", " ");
    }
  },
  watch: {
    data: {
      handler: function (val) {
        this.$emit("update", val);
      },
      deep: true
    }
  }
};
</script>

<style>
.jaf-form-label {
  text-align: left;
  font-size: 11px;
  margin-bottom: 6px;
}
</style>
