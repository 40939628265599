<template>
  <div class="uploader" :id="wrapperId">
    <label class="col-form-label col-xs-12">{{ name }}</label>
    <div v-if="!isfile">
      <input @change="onFileChange" :id="inputId" type="file" hidden />
      <label for="highQuality" class="col-form-label col-xs-12"
        >High Quality</label
      >
      <div class="col-xs-12">
        <input type="checkbox" id="highQuality" v-model="highQuality" />
      </div>
      <label for="cropToTrimbox" class="col-form-label col-xs-12"
        >Crop to TrimBox</label
      >
      <div class="col-xs-12">
        <input type="checkbox" id="cropToTrimbox" v-model="cropToTrimbox" />
      </div>
      <b-button v-if="!message" @click="clickFileInput()" variant="modal">
        Add file
      </b-button>
    </div>

    <el-progress
      v-if="progress"
      :percentage="progress"
      :show-text="false"
      :stroke-width="10"
    />

    <label v-if="message">
      <span>{{ message }}</span>
      <a @click="killProcessing">
        <i class="fa fa-close" aria-hidden="true"></i>
      </a>
    </label>

    <div v-if="isfile" class="preview">
      <a class="close close__cover" @click="deleteFile">
        <i class="fa fa-close" aria-hidden="true"></i>
      </a>
      <img :src="file.cover_page.file_small_url" @click="onPreview()" />
    </div>
  </div>
</template>

<script>
import { api } from "../../store/api.js";
import * as common from "../../utils/common.js";
import { dragoverMixin } from "../../mixins/dragoverMixin.js";
import { Socket } from "phoenix";

export default {
  name: "uploader",
  mixins: [dragoverMixin],
  props: ["name", "regionId", "preview"],
  data() {
    return {
      uid: common.uniqueId(),
      file: undefined,
      message: undefined,
      socket: undefined,
      channel: undefined,
      progress: 0,
      highQuality: false,
      cropToTrimbox: false
    };
  },
  created() {
    this.setDefaults();
    this.socket = this.createSocketConnection();
  },
  mounted() {
    this.resolveInitialPreview();
  },
  computed: {
    wrapperId: function () {
      return "attachment-input-wrapper-" + this.uid;
    },
    inputId: function () {
      return "attachment-input-" + this.uid;
    },
    isfile() {
      return this.file && this.file.cover_page.file_small_url;
    }
  },
  watch: {
    regionId() {
      this.setDefaults();
    }
  },
  methods: {
    setDefaults() {
      this.file = {
        id: null,
        cover_page: {
          file_small_url: null
        }
      };
      this.message = undefined;
      this.progress = 0;
    },
    clickFileInput() {
      this.$emit("started", { id: this.regionId });
      document.getElementById(this.inputId).click();
    },
    onDrop(e) {
      this.onFileChange(e);
    },
    deleteFile() {
      var result = confirm("You want remove region?");
      if (result) {
        this.message = null;
        this.file.cover_page.file_small_url = null;
        this.$emit("delete-uploaded", this.regionId);
      }
    },
    killProcessing() {
      var result = confirm(
        "Do you want to interrupt the processing of this file?"
      );
      if (result) {
        this.message = null;
        this.file.cover_page.file_small_url = null;
        this.channel.push("kill", {});
      }
    },
    onFileChange(event) {
      var file;

      if (event.target.files) {
        file = event.target.files[0];
      } else if (event.dataTransfer.files) {
        file = event.dataTransfer.files[0];
      }

      const quality = this.highQuality ? "high" : "medium";
      const cropToTrimbox = this.cropToTrimbox;

      api.uploadPdf(
        { file, quality, cropToTrimbox },
        (res) => this.connect(res.id),
        (progress) => (this.progress = progress)
      );
    },
    resolveInitialPreview() {
      if (this.preview) {
        this.file.cover_page.file_small_url = this.preview;
      }
    },
    onPreview() {
      if (this.regionId) {
        this.$emit("preview", this.regionId);
      }
    },
    connect(id) {
      this.file.id = id;
      this.channel = this.createChannelConnection(this.socket, "pdf:" + id);
      this.bindChannelEventWithData(this.channel, "new_message");
    },
    createSocketConnection() {
      let socket = new Socket(process.env.VUE_APP_WSS_HOST + "/socket");
      socket.connect();

      return socket;
    },
    createChannelConnection(socket, channelName) {
      let channel = socket.channel(channelName, {});
      channel
        .join()
        .receive("ok", (resp) => {
          console.info("Joined successfully", resp);
        })
        .receive("error", (resp) => {
          console.info("Unable to join", resp);
        });

      return channel;
    },
    bindChannelEventWithData(channel, eventName) {
      channel.on(eventName, (item) => {
        this.progress = 0;
        this.message = item.message;

        if (item.cover_photo) {
          this.file.cover_page = item.cover_photo;
          this.file.pages_count = item.pages_count;
          this.$emit("uploaded", { file: this.file, id: this.regionId });
        }
      });
    }
  }
};
</script>
