<script>
export default {
  props: {
    show: {
      type: Boolean,
      twoWay: true,
      default: false
    },
    title: {
      type: String,
      default: "Modal"
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    },
    force: {
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      default: "modal"
    },
    okText: {
      type: String,
      default: "OK"
    },
    cancelText: {
      type: String,
      default: "Cancel"
    },
    okClass: {
      type: String,
      default: "btn blue"
    },
    cancelClass: {
      type: String,
      default: "btn red btn-outline"
    },
    closeWhenOK: {
      type: Boolean,
      default: false
    },
    customerClass: {
      type: String,
      default: ""
    },
    closeIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      duration: null
    };
  },
  computed: {
    modalClass() {
      return {
        "modal-lg": this.large,
        "modal-sm": this.small,
        "modal-full": this.full
      };
    }
  },
  created() {
    if (this.show) {
      document.body.className += " modal-open";
    }
  },
  beforeDestroy() {
    document.body.className = document.body.className.replace(
      /\s?modal-open/,
      ""
    );
  },
  watch: {
    show(value) {
      if (value) {
        document.body.className += " modal-open";
      } else {
        this.duration = 1000;

        window.setTimeout(() => {
          document.body.className = document.body.className.replace(
            /\s?modal-open/,
            ""
          );
        }, this.duration || 0);
      }
    }
  },
  methods: {
    ok() {
      this.$emit("ok");
      if (this.closeWhenOK) {
        this.cancel();
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    clickMask() {
      if (!this.force) {
        this.cancel();
      }
    }
  }
};
</script>

<template>
  <div v-show="show" :transition="transition" class="modal-wrapper">
    <div class="modal" @click.self="clickMask">
      <div class="modal-dialog" :class="[modalClass, customerClass]">
        <div class="modal-content">
          <!--Header-->
          <div class="modal-header">
            <a v-if="closeIcon" class="close" @click="cancel">
              <div class="custom-icon clear-icon"></div>
            </a>
            <slot name="header">
              <h4 class="modal-title">
                {{ title }}
              </h4>
            </slot>
          </div>
          <!--Container-->
          <div class="modal-body">
            <slot></slot>
          </div>
          <!--Footer-->
          <div class="modal-footer">
            <slot name="footer">
              <button type="button" :class="cancelClass" @click="cancel">
                {{ cancelText }}
              </button>
              <button type="button" :class="okClass" @click="ok">
                {{ okText }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop in"></div>
  </div>
</template>
