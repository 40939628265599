import gql from "graphql-tag";

const updateAdsAdvertisement = gql`
  mutation updateAdsAdvertisement(
    $updateAdsAdvertisement: UpdateAdsAdvertisementImageAdInput!
  ) {
    updateAdsAdvertisementImageAd(
      updateAdsAdvertisementImageAd: $updateAdsAdvertisement
    ) {
      id
    }
  }
`;

export default updateAdsAdvertisement;
