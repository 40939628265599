import dateFormat from "dateformat";

export function getSubdomain() {
  var reg = new RegExp("www|jafolders");
  var parts = window.location.host.split(".");
  return reg.test(parts[0]) ? "" : parts[0];
}

export function formatDate(timestamp, mask) {
  var date = new Date(timestamp);
  if (!mask) {
    mask = "dd-mm-yyyy";
  }
  return dateFormat(date, mask);
}

export function uniqueId() {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 5; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function deserializeRelations(data, item, name, id_key) {
  let res = [];
  if (item) {
    for (let i = 0; i < data.length; i++) {
      let selected = item.find((el) => {
        return data[i].id == el.id;
      });

      if (!selected) {
        let tmp = {};
        tmp[name + "_id"] = data[i].id;
        res.push(tmp);
      }
    }

    for (let i = 0; i < item.length; i++) {
      let selected = data.find((el) => {
        return item[i].id == el.id;
      });

      if (!selected) {
        res.push({ id: item[i][id_key], delete: true });
      } else {
        res.push({ id: item[i][id_key] });
      }
    }
  } else {
    for (let i = 0; i < data.length; i++) {
      let tmp = {};
      tmp[name + "_id"] = data[i].id;
      res.push(tmp);
    }
  }

  return res;
}
