<template>
  <nav class="side side-nav" id="sidebar-wrapper">
    <div class="sidebar">
      <section id="brand">
        <logo />
      </section>

      <div v-if="me">
        <NavLinks :currentUser="me" />
      </div>

      <section id="auth">
        <ul>
          <li class="user-name" v-if="me">
            {{ me.firstName }}
          </li>
          <li class="user-email" v-if="me">
            {{ me.email }}
          </li>
          <li>
            <button @click="logout" class="logout mt-2">logout</button>
          </li>
        </ul>
      </section>
    </div>
  </nav>
</template>

<script>
import auth from "../../utils/auth";
import logo from "../shared/logo";
import NavLinks from "./nav_links.vue";
import { CURRENT_USER } from "../../constants";
import gql from "graphql-tag";

export default {
  apollo: {
    me: gql`
      query Me {
        me {
          id
          email
          firstName
          lastName
          roles
        }
      }
    `
  },
  components: { logo, NavLinks },
  computed: {
    currentUser() {
      return JSON.parse(localStorage.getItem(CURRENT_USER));
    }
  },
  methods: {
    logout() {
      auth.signOut();
      this.$router.push({ path: "/auth/login" });
    }
  }
};
</script>

<style lang="postcss">
.logout {
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 18%);
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  padding: 0.4rem 2.5rem;
  line-height: 13px;
  background-color: #2c2e2f;
  color: #afaeae;
  border: solid 1px #afaeae;
}
.logout:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}
</style>
