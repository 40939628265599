import * as _ from "lodash";
import { api } from "../api";

import * as types from "../mutation-types";

const state = {
  allParserClasses: [],
  allParsers: [],
  activeParserClass: [],
  activeParser: [],
  lastCreatedParser: undefined,
  lastCreatedParserIterations: [],
  allParserIterations: [],
  activeIteration: []
};

const getters = {
  allParserClasses: (state) => state.allParserClasses,
  allParsers: (state) => state.allParsers,
  activeParserClass: (state) => state.activeParserClass,
  activeParser: (state) => state.activeParser,
  lastCreatedParser: (state) => state.lastCreatedParser,
  lastCreatedParserIterations: (state) => state.lastCreatedParserIterations,
  allParserIterations: (state) => state.allParserIterations,
  activeIteration: (state) => state.activeIteration
};

const actions = {
  fetchParserClasses({ commit }) {
    api.getParserClasses((parsers) => {
      commit(types.RECEIVE_PARSERS_CLASSES, { parsers });
    });
  },
  fetchParserIterations({ commit }, id) {
    api.getParserIterations(id, (iterations) => {
      commit(types.RECEIVE_PARSER_ITERATIONS, { iterations });
    });
  },
  fetchParserActiveIteration({ commit }, parserId, iterationId) {
    api.getParserIteration(parserId, iterationId, (activeIteration) => {
      commit(types.RECEIVE_PARSER_ACTIVE_ITERATION, { activeIteration });
    });
  },
  fetchParsers({ commit }, id) {
    api.getParserClasses((parsers) => {
      let activeClass = parsers.find((el) => {
        return el.id == id;
      });
      commit(types.RECEIVE_PARSERS_CLASSES, { parsers });
      commit(types.RECEIVE_ACTIVE_PARSER_CLASS, { activeClass });
    });

    api.getParsers(id, (parsers) => {
      commit(types.RECEIVE_PARSERS, { parsers });
    });
  },
  fetchActiveParser({ commit }, routerParams) {
    if (!state.allParsers.length) {
      actions.fetchParsers({ commit }, routerParams.class);
    } else {
      let parser = state.allParsers.find((el) => {
        return el.id == routerParams.id;
      });

      commit(types.RECEIVE_ACTIVE_PARSER, { parser });
    }
  },
  fetchLastCreatedParserIterations({ commit }, parserId) {
    api.fetchLastCreatedParserIterations(parserId, (messages) => {
      commit(types.RECEIVE_LAST_CREATED_PARSER_ITERATIONS, { messages });
    });
  },
  pushLastCreatedParserIteration({ commit }, messages) {
    commit(types.RECEIVE_LAST_CREATED_PARSER_ITERATIONS, { messages });
  },
  resetLastCreatedParserIteration({ commit }) {
    let messages = [];
    commit(types.RECEIVE_LAST_CREATED_PARSER_ITERATIONS, { messages });
  },
  createParser({ commit }, params) {
    api.createParser(params, (parser) => {
      commit(types.CREATE_PARSER, { parser });
    });
  }
};

const mutations = {
  [types.RECEIVE_PARSERS_CLASSES](state, { parsers }) {
    state.allParserClasses = parsers;
  },
  [types.RECEIVE_PARSERS](state, { parsers }) {
    state.allParsers = parsers;
  },
  [types.RECEIVE_ACTIVE_PARSER_CLASS](state, { activeClass }) {
    state.activeParserClass = activeClass;
  },
  [types.RECEIVE_PARSER_ITERATIONS](state, { iterations }) {
    state.allParserIterations = iterations;
  },
  [types.RECEIVE_PARSER_ACTIVE_ITERATION](state, { activeIteration }) {
    state.activeIteration = activeIteration;
  },
  [types.RECEIVE_ACTIVE_PARSER](state, { parser }) {
    state.activeParser = parser;
  },
  [types.CREATE_PARSER](state, { parser }) {
    state.lastCreatedParser = parser;
    state.allParsers.unshift(parser);
  },
  [types.RECEIVE_LAST_CREATED_PARSER_ITERATIONS](state, { messages }) {
    if (_.isArray(messages)) state.lastCreatedParserIterations = messages;
    else state.lastCreatedParserIterations.push(messages);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
