<template>
  <div class="slider__split">
    <input
      type="text"
      placeholder="Split String: 1,2-5,9"
      v-model="splitString"
    />
    <div class="slider__split-save" @click="split">save</div>
  </div>
</template>

<script>
import defaultMixin from "./defaultMixin";

export default {
  name: "Delete",
  mixins: [defaultMixin],
  data() {
    return {
      splitString: ""
    };
  },
  computed: {
    regionId() {
      return this.$store.getters.sliderRegionId;
    }
  },
  methods: {
    split() {
      let conf = confirm("Do you want to split this pages permanently?");
      if (conf) {
        this.$store.dispatch("splitPages", {
          brochure_region_id: this.regionId,
          split_string: this.splitString
        });
        this.$store.dispatch("hidePreview");
      }
    }
  }
};
</script>
