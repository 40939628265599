<template>
  <button @click="$emit('click')" class="jaf-button">
    <slot />
  </button>
</template>

<script>
export default {
  name: "base-button",
  data() {
    return {
      showNewForm: false
    };
  },
  methods: {},
  computed: {}
};
</script>

<style>
.jaf-button {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  border-bottom-color: rgb(51, 51, 51);
  border-radius: 4px;
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue",
    Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.75rem 0.7rem;
  text-align: left;
  border: none;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 18%);
}
.jaf-button.leading-icon {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.jaf-button:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}
</style>
