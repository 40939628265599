<template>
  <div
    :style="movePositionState"
    :class="zoomStateClass"
    class="slider__thumbBox"
  >
    <div
      v-for="(page, i) in pages"
      @click="onChange(i)"
      :style="thumbImg(page)"
      :class="isActiveStateClass(i)"
      ref="thumbImg"
      class="slider__thumbImg"
      v-bind:key="page.id"
    ></div>
  </div>
</template>

<script>
import { api } from "../../store/api";
import defaultMixin from "./defaultMixin";

export default {
  name: "Thumbs",
  mixins: [defaultMixin],
  computed: {
    pages() {
      return this.$store.getters.sliderPages;
    },
    movePositionState() {
      let thumbs = this.$refs.thumbImg;
      if (this.index && thumbs) {
        let countOnPage = 12;
        if (this.index <= this.pages.length - countOnPage) {
          return this.moveThumbStyle(thumbs, this.index);
        } else {
          return this.moveThumbStyle(thumbs, this.pages.length - countOnPage);
        }
      } else return {};
    }
  },
  methods: {
    thumbImg(url) {
      return "background-image: url(" + url.file_medium_url + ")";
    },
    onChange(index) {
      this.$store.dispatch("changePageIndex", index);
    },
    isActiveStateClass(index) {
      return this.index == index ? "-active" : "";
    },
    moveThumbStyle(thumbs, index) {
      let thumbMargin = 16;
      let thumbWidth = thumbs[0].offsetWidth + thumbMargin;
      let position = index * thumbWidth;
      return `transform: translateX(-${position}px)`;
    }
  }
};
</script>

<style scoped>
.product-tags-enabled {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 0;
  top: 0;
}
</style>
