<template>
  <div class="slider__zoom">
    <i
      @click="zoomMinus"
      :class="stateClassMinus"
      class="slider__zoomIcon fa fa-search-minus"
    ></i>
    <div @click="clear" class="slider__zoomDefault">auto</div>
    <i
      @click="zoomPlus"
      :class="stateClassPlus"
      class="slider__zoomIcon fa fa-search-plus"
    ></i>
  </div>
</template>

<script>
import defaultMixin from "./defaultMixin";

export default {
  name: "Zoom",
  mixins: [defaultMixin],
  computed: {
    stateClassMinus() {
      return this.zoom > 0 ? "" : "-hide";
    },
    stateClassPlus() {
      return this.zoom < this.zoomMax ? "" : "-hide";
    }
  },
  created() {
    window.addEventListener("keydown", this.handleKeyDown, true);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown, true);
  },
  methods: {
    handleKeyDown(e) {
      e = e || window.event;

      switch (e.keyCode) {
        case 38:
          this.zoomMinus();
          break;
        case 40:
          this.zoomPlus();
          break;
      }
    },
    zoomMinus() {
      this.$store.dispatch("decrementZoom");
    },
    zoomPlus() {
      this.$store.dispatch("incrementZoom");
    },
    clear() {
      this.$store.dispatch("defaultZoom");
    }
  }
};
</script>
