<template>
  <div class="content-toolbar">
    <span class="page-title">Admins</span>
    <ul class="tools pull-right">
      <li>
        <BaseButton @click="showNewForm = true" class="leading-icon"
          ><div class="custom-icon add-circle-icon"></div>
          Add admin</BaseButton
        >
      </li>
    </ul>
    <new-admin
      v-if="showNewForm"
      :show.sync="showNewForm"
      @close="showNewForm = false"
    />
  </div>
</template>

<script>
import newAdmin from "./new";
import BaseButton from "../atoms/BaseButton.vue";

export default {
  name: "admin-toolbar",
  components: { newAdmin, BaseButton },
  data() {
    return {
      showNewForm: false
    };
  },
  methods: {},
  computed: {}
};
</script>
