import google, { GOOGLE_PROVIDER } from "./google";
import store from "../store";

export const LOCAL_STORAGE_PROVIDER_KEY = "provider";
const allProviders = Object.freeze([GOOGLE_PROVIDER]);

const clearCache = () => {
  sessionStorage.clear(), localStorage.clear(), store.dispatch("logout");
};

const getProvider = () => localStorage.getItem(LOCAL_STORAGE_PROVIDER_KEY);

export const setProvider = (provider) => {
  localStorage.setItem(LOCAL_STORAGE_PROVIDER_KEY, provider);
};

export const signIn = async (provider, credential) => {
  if (provider === GOOGLE_PROVIDER) {
    await google.googleSignIn(credential);
  }
};

export const signOut = () => {
  const provider = getProvider();
  if (provider === GOOGLE_PROVIDER) {
    google.googleSignOut();
  }
};

export const getToken = () => {
  const provider = getProvider();
  if (provider === GOOGLE_PROVIDER) {
    return new Promise((resolve) => {
      resolve(google.getToken());
    }).catch(() => signOut());
  }

  return null;
};

export const loggedIn = async () => {
  const token = await getToken();

  return new Promise((resolve) => {
    resolve(Boolean(token));
  });
};

export default {
  signIn,
  signOut,
  getProvider,
  getToken,
  setProvider,
  loggedIn,
  clearCache
};
