<template>
  <div>
    <div class="file-upload" :class="{ 'flex-container': label }">
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        @change="onFileSelected"
        v-bind:style="{ color: filenameColor }"
      />
      <label v-if="resolution" for="fileInput"
        >The display position specified above requires a resolution of
        {{ resolution }}</label
      >
    </div>
    <img :src="currentFileUrl" class="image" />
  </div>
</template>

<script>
export default {
  name: "FileUpload",
  props: { currentFileUrl: null, resolution: null },

  data() {
    return {
      selectedFile: null
    };
  },
  computed: {
    filenameColor() {
      if (this.currentFileUrl && !this.selectedFile) {
        return "rgba(0, 0, 0, 0)";
      } else {
        return "rgba(0, 0 , 0, 1)";
      }
    }
  },
  methods: {
    onFileSelected(event) {
      const target = event.target;
      const file = target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (theFile) => {
          const image = new Image();
          image.src = theFile.target?.result;
          image.onload = (e) => {
            this.selectedFile = file;
            this.currentFileUrl = e.target.src;
            this.$emit("file", file);
          };
        };
        reader.readAsDataURL(file);
      }
    }
  }
};
</script>

<style scoped>
.image {
  width: 25%;
  margin-top: 1rem;
}
</style>
