<template>
  <div class="shop-modal">
    <modal :show.sync="show" :large="true" @cancel="onClose">
      <div slot="header">
        <h6 class="secondary mb-0">All Brochures in</h6>
        <h3 class="title">{{ item.name }}</h3>
      </div>

      <box :brochures="inbox" title="Inbox" />

      <box :brochures="forApproval" title="Ready for Approval" />

      <box :brochures="waiting" title="Waiting for Publication" />

      <box :brochures="active" title="Active" />

      <div slot="footer"></div>
    </modal>
  </div>
</template>

<script>
import modal from "../shared/modal";
import box from "./box.vue";
import moment from "moment";
export default {
  name: "shop-brochures",
  components: { modal, box },
  props: ["show", "item"],
  computed: {
    brochures() {
      return this.$store.getters.activeShopBrochures;
    },
    inbox() {
      return this.filterBrochuresByState("inbox");
    },
    forApproval() {
      return this.filterBrochuresByState("for_approval");
    },
    waiting() {
      let filteredBrochures = this.filterBrochuresByState("published");
      return this.filterByWaiting(filteredBrochures);
    },
    active() {
      let filteredBrochures = this.filterBrochuresByState("published");
      return this.filterByActive(filteredBrochures);
    }
  },
  created() {
    this.$store.dispatch("fetchShopBrochures", this.item.id);
  },
  beforeDestroy() {
    this.$store.dispatch("resetShopBrochures");
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    filterBrochuresByState(name) {
      let brochures = this.brochures;

      if (brochures) {
        return brochures.filter((brochure) => brochure.state === name);
      }
    },
    filterByWaiting(brochures) {
      let now = moment().unix();

      if (brochures) {
        return brochures.filter((brochure) => brochure.published_at > now);
      }
    },
    filterByActive(brochures) {
      let now = moment().unix();

      if (brochures) {
        return brochures.filter(
          (brochure) =>
            brochure.published_at <= now && brochure.expire_after >= now
        );
      }
    }
  }
};
</script>
