<template>
  <div class="categories content">
    <Breadcrumb :data="breadcrumbData" />

    <el-table
      v-if="isParserClasses"
      :data="parserClasses"
      :default-sort="{ prop: 'class', order: 'descending' }"
      stripe
      border
      class="mt-2"
    >
      <el-table-column
        :resizable="resizableColumn"
        label="Parser"
        prop="class"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.class }}
          <TableControls :id="scope.row.id" class="pull-right" />
        </template>
      </el-table-column>
    </el-table>

    <div
      v-if="!isParserClasses"
      class="panel-background radius-4 border mt-2 p-2"
    >
      Your parser classes is empty!
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../shared/breadcrumb";
import TableControls from "../elements/table-controls";

export default {
  name: "ParserClasses",
  components: { Breadcrumb, TableControls },
  data() {
    return {
      resizableColumn: false
    };
  },
  computed: {
    parserClasses() {
      let classCount = this.$store.getters.allParserClasses;
      return classCount ? classCount : 0;
    },
    isParserClasses() {
      return this.parserClasses && this.parserClasses.length;
    },
    breadcrumbData() {
      return [
        {
          name: "Parsers"
        }
      ];
    }
  },
  beforeCreate() {
    this.$store.dispatch("fetchParserClasses");
  },
  methods: {
    countParser(count) {
      return count ? count : 0;
    }
  }
};
</script>
