export function brochuresByStauses(brochures, statuses) {
  var res = [];
  statuses.forEach((status) => {
    byStatus(brochures, status).forEach((item) => {
      if (res.indexOf(item) == -1) {
        res.push(item);
      }
    });
  });
  return res;
}
// processing: 0, inbox: 1, for_approval: 2, published: 3, trash

export function byStatus(res, status) {
  switch (status) {
    case "INBOX":
      res = res.filter((el) => {
        return el.state == "inbox";
      });
      break;
    case "READY_FOR_APPROVAL":
      res = res.filter((el) => {
        return el.state == "for_approval";
      });
      break;
    case "WAITING_FOR_PUBLICATION":
    case "ACTIVE":
      res = res.filter((el) => {
        return (
          el.active_from < new Date().getTime() / 1000 &&
          el.expire_after > new Date().getTime() / 1000
        );
      });
      break;
    case "ARCHIVE":
    case "TRASH":
      res = res.filter((el) => {
        return el.state == "trash";
      });
      break;
    case "EXPIRED":
      res = res.filter((el) => {
        return el.expire_after < new Date().getTime() / 1000;
      });
      break;
  }
  return res;
}

export function filterBrochures(brochures, filterBy) {
  var res = brochures;
  for (let key in filterBy) {
    if (filterBy[key]) {
      res = res.filter((el) => {
        if (Array.isArray(el[key])) {
          return el[key].find((el) => {
            if (key == "regions") {
              if (el.region.length) {
                return el.region.id == filterBy[key].id;
              } else {
                return false;
              }
            } else {
              return el.id == filterBy[key].id;
            }
          });
        } else if (el[key + "_id"]) {
          return el[key + "_id"] == filterBy[key].id;
        }
      });
    }
  }
  return res;
}
