<template>
  <PageLayout class="identity-users">
    <ToolBar title="Users">
      <template slot="actions">
        <BaseButton @click="addUser" class="leading-icon"
          ><div class="custom-icon add-circle-icon"></div>
          Add user</BaseButton
        >
      </template>
    </ToolBar>
    <UsersOverview />
  </PageLayout>
</template>

<script>
import UsersOverview from "./users-overview.vue";
import BaseButton from "../atoms/BaseButton.vue";
import ToolBar from "../layout/tool-bar.vue";
import PageLayout from "../layout/page-layout.vue";

export default {
  name: "users",
  components: {
    UsersOverview,
    BaseButton,
    ToolBar,
    PageLayout
  },
  methods: {
    addUser() {
      this.$router.push("/users/new/");
    }
  }
};
</script>
