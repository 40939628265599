<template>
  <div class="new-brochure-modal">
    <modal
      :customerClass="'brochure-modal'"
      :show.sync="show"
      @cancel="onClose"
      :large="true"
    >
      <div slot="header">
        <div class="brochure-picture">
          <img :src="brochureCover" />
          <div
            :style="brochureCoverBackground"
            class="brochure-cover-blur"
          ></div>
        </div>
      </div>
      <img
        v-if="newDataObject.language"
        :src="newDataObject.language.file_small_url"
        class="title-flag"
      />
      <h3 class="title">{{ newDataObject.name }}</h3>
      <div class="row">
        <div v-if="showAttachementWarning" class="error-message">
          You need to provide pdf files for all regions before submitting.
        </div>
        <brochure-form
          v-if="mounted"
          ref="form"
          :struct="emptyFormStruct"
          v-on:update-data="updateData"
          v-on:update-attachments="updateAttachments"
        />
      </div>
      <div slot="footer">
        <b-button variant="modal" @click="submit()">
          create on this list
        </b-button>
        <b-button variant="modal" @click="submit('for_approval')">
          create and move to waiting for approval
        </b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import modal from "../shared/modal";
import brochureForm from "./form";
const helper = require("./helper");
import { interruptMixin } from "../../mixins/interruptMixin.js";
import * as _ from "lodash";

export default {
  name: "new-brochure",
  mixins: [interruptMixin],
  components: { modal, brochureForm },
  computed: {
    showAttachementWarning() {
      if (this.newDataObject.shop) {
        return !this.isAllPdfs();
      } else {
        return false;
      }
    },
    brochureCover() {
      if (_.keys(this.attachments).length) {
        return this.attachments[_.keys(this.attachments)[0]].cover_page
          .file_small_url;
      } else {
        return "";
      }
    },
    brochureCoverBackground() {
      if (this.brochureCover.length) {
        return `background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.brochureCover})`;
      } else {
        return "";
      }
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    updateData(newDataObject) {
      this.newDataObject = newDataObject;
    },
    updateAttachments(attachments) {
      this.attachments = attachments;
    },
    isAllPdfs() {
      return (
        this.newDataObject.shop.regions.length ==
        _.keysIn(this.attachments).length
      );
    },
    submit(type) {
      var form = this.$refs.form;
      form.validateAll();
      if (form.isValid()) {
        var params = helper.deserialize(
          this.newDataObject,
          this.attachments,
          {}
        );
        if (type) {
          params.state = type;
        }
        this.$store.dispatch("createBrochure", params);
        this.onClose();
      }
    }
  },
  data() {
    return {
      emptyFormStruct: {},
      newDataObject: {},
      attachments: [],
      mounted: false,
      showAttachmentWarning: false
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$set(this, "emptyFormStruct", helper.cloneFormStruct());
    this.$set(this, "mounted", true);
  }
};
</script>
