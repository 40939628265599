<template>
  <div class="content-toolbar">
    <div class="left">
      <div class="back" v-if="to">
        <router-link :to="to">
          <div class="custom-icon back-icon"></div>
        </router-link>
      </div>
      <span class="page-title">{{ title }}</span>
    </div>
    <slot name="actions" />
  </div>
</template>

<script>
export default {
  name: "ToolBar",
  props: ["to", "title"]
};
</script>

<style scoped>
.content-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.left {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}
.custom-icon {
  height: 100%;
}
</style>
