import { api } from "../api";
import * as types from "../mutation-types";

const state = {
  all: []
};

const getters = {
  getLanguageById: (state) => (id) => {
    return state.all.find((el) => {
      return el.id == id;
    });
  },
  allLanguages: (state) => state.all
};

const actions = {
  getAllLanguages({ commit }) {
    if (!state.all.length) {
      api.getLanguages((languages) => {
        commit(types.RECEIVE_LANGUAGES, { languages });
      });
    }
  }
};

const mutations = {
  [types.RECEIVE_LANGUAGES](state, { languages }) {
    state.all = languages;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
