import gql from "graphql-tag";

const deleteAdsAdvertisement = gql`
  mutation deleteAdsAdvertisement(
    $deleteAdsAdvertisement: DeleteAdsAdvertisementInput!
  ) {
    deleteAdsAdvertisement(deleteAdsAdvertisement: $deleteAdsAdvertisement) {
      status
    }
  }
`;

export default deleteAdsAdvertisement;
