import { api } from "../api";
import * as types from "../mutation-types";

const state = {
  open: null,
  all: []
};

const getters = {
  statsActive: (state) => state.open,
  statsData: (state) => state.all
};

const actions = {
  statsOpen({ commit }) {
    commit(types.OPEN_STATS);
  },
  statsClose({ commit }) {
    commit(types.CLOSE_STATS);
  },
  fetchStats({ commit }, { type, id, params }) {
    if (!id) {
      api.getAllStats(params, (stats) => {
        commit(types.GET_STATS, { stats });
      });
    } else {
      api.getStats(type, id, params, (stats) => {
        commit(types.GET_STATS, { stats });
      });
    }
  }
};

const mutations = {
  [types.OPEN_STATS](state) {
    state.open = true;
  },
  [types.CLOSE_STATS](state) {
    state.open = false;
  },
  [types.GET_STATS](state, { stats }) {
    state.all = stats;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
