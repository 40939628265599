<template>
  <tr class="table-data-row">
    <td v-for="column in columns" :key="column.value">
      {{ cellData(column.value) }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "table-data-cell",
  props: ["data", "columns"],
  methods: {
    cellData(value) {
      const dataValue = this.data[value];
      return dataValue || "";
    }
  }
};
</script>

<style scoped>
.table-data-row {
  cursor: pointer;
}
.table-data-row:nth-child(even) {
  background-color: #fff;
  transition: background-color 200ms;
}
.table-data-row:nth-child(odd) {
  background-color: #eee;
  transition: background-color 200ms;
}
.table-data-row:hover {
  background-color: #eef1f6;
}
.table-data-row td {
  padding: 6px 12px;
  white-space: normal;
  word-break: break-all;
  line-height: 24px;
  overflow: hidden;

  border: 1px solid #dfe6ec;
}
</style>
