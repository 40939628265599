import gql from "graphql-tag";

const createIdentityUser = gql`
  mutation createIdentityUser($createIdentityUser: CreateIdentityUserInput!) {
    createIdentityUser(createIdentityUser: $createIdentityUser) {
      identityUser {
        id
      }
    }
  }
`;

export default createIdentityUser;
