import Vuex from "vuex";
import Vue from "vue";

import slider from "./modules/slider";
import stats from "./modules/stats";
import categories from "./modules/categories";
import parsers from "./modules/parsers";
import brochures from "./modules/brochures";
import languages from "./modules/languages";
import locations from "./modules/locations";
import regions from "./modules/regions";
import shops from "./modules/shops";
import auth from "./modules/auth";
import offers from "./modules/offers";
import config from "./modules/config";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    categories,
    parsers,
    brochures,
    languages,
    locations,
    regions,
    shops,
    auth,
    stats,
    slider,
    offers,
    config
  }
});
