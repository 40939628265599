import { LOCAL_STORAGE_PROVIDER_KEY } from "@/utils/auth";

export const GOOGLE_PROVIDER = "GOOGLE";
export const TOKEN = "token";
export const EXPIRY = "expiry";

export const googleClientId = () => {
  if (window.location.host.includes("allefolders")) {
    return "250405777892-scid1ujm27dev561f1q8jeop3lb8kf4k.apps.googleusercontent.com";
  } else if (window.location.host.includes("promopromo")) {
    return "250405777892-tv1g7oakrpmag90jmg8adt8t6mocmn6j.apps.googleusercontent.com";
  } else {
    return "250405777892-tv1g7oakrpmag90jmg8adt8t6mocmn6j.apps.googleusercontent.com";
  }
};

export const googleSignIn = async (credentials) => {
  // Now we have a google credential (JWT) we can use that to authenticate with our backend,
  // because google stopped supporting refresh tokens, we have to use our own logic
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      google_jwt: credentials
    })
  };

  let response;
  try {
    const request = await fetch(
      process.env.VUE_APP_API_HOST + "/api/auth/login",
      requestOptions
    );

    response = await request.json();
  } catch (error) {
    return false;
  }

  const expiryDate = new Date();
  expiryDate.setSeconds(expiryDate.getSeconds() + response.expiry);

  // Store to localstorage
  localStorage.setItem(LOCAL_STORAGE_PROVIDER_KEY, GOOGLE_PROVIDER);
  localStorage.setItem(TOKEN, response.access_token);
  localStorage.setItem(EXPIRY, expiryDate.toISOString());
};

export const googleSignOut = async () => {
  const requestOptions = {
    method: "DELETE",
    credentials: "include",
    headers: { "Content-Type": "application/json" }
  };

  await fetch(
    process.env.VUE_APP_API_HOST + "/api/auth/logout",
    requestOptions
  );
  localStorage.removeItem(LOCAL_STORAGE_PROVIDER_KEY);
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(EXPIRY);
};

export const getToken = async () => {
  const token = localStorage.getItem(TOKEN);
  const expiry = localStorage.getItem(EXPIRY);

  if (token && expiry) {
    const now = new Date();
    const expiryString = localStorage.getItem(EXPIRY) ?? now;

    if (now < new Date(expiryString)) {
      return localStorage.getItem(TOKEN);
    }
  }

  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "application/json" }
  };

  let response;

  try {
    const request = await fetch(
      process.env.VUE_APP_API_HOST + "/api/auth/access_token",
      requestOptions
    );
    response = await request.json();
  } catch (error) {
    return false;
  }

  const expiryDate = new Date();
  expiryDate.setSeconds(expiryDate.getSeconds() + response.expiry);
  localStorage.setItem(TOKEN, response.access_token);
  localStorage.setItem(EXPIRY, expiryDate.toISOString());
  return response.access_token;
};

export default {
  googleSignIn,
  googleSignOut,
  getToken
};
