<template>
  <section class="brochure" v-bind:brochure-id="item.id">
    <div
      v-if="['visible', 'in_progress'].includes(recreateParserStatus)"
      class="modal-wrapper"
    >
      <div class="modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Recreating brochure using parser</h4>
            </div>
            <div class="modal-body">
              <select class="form-control" v-model="recreateParserRegion">
                <option
                  v-for="(region, index) in recreateParserRegions"
                  :key="`recreate-parser-region-${index}`"
                  :value="region"
                >
                  {{ region.region.name }}
                </option>
              </select>
              <button
                class="btn"
                @click="recreateParser"
                :disabled="!recreateParserRegion"
              >
                Start
              </button>
              <div
                v-for="(message, index) in recreateParserMessages"
                :key="`recreate-parser-message-${index}`"
              >
                {{ message }}
              </div>
              <button
                class="btn"
                @click="closeRecreateParser"
                v-if="recreateParserStatus != 'in_progress'"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop in"></div>
    </div>
    <edit-brochure
      v-if="showEditForm"
      :item="item"
      :show.sync="showEditForm"
      @close="onCloseEdit"
    />
    <brochure-stats
      v-if="showStats"
      :brochure="item"
      :version="statsVersion"
      :show.sync="showStats"
      @close="onCloseStats"
    />
    <div
      class="brochure-dropdown-wrapper"
      @mouseleave="hideDropdown"
      v-if="showDropdown"
    >
      <div class="brochure-dropdown-backdrop" @click="hideDropdown"></div>
      <div class="brochure-dropdown">
        <div class="dropdown-button cover" @click="hideDropdown">
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
        <ul>
          <li class="title">Status:</li>
          <div v-if="isDisabledCurrentStatus">"Please set dates!"</div>
          <div v-else>
            <b-form-select
              :value="currentStatus"
              ref="currentStatus"
              :options="dropdownStatuses"
              stacked
              :disabled="isDisabledCurrentStatus"
              class="mb-3"
            />
          </div>
          <li>
            <div class="row">
              <div class="col-xs-12">
                <b-button
                  :href="build_dashboard_url(item.shop_id, item.id)"
                  target="_blank"
                  variant="modal"
                >
                  statistics
                </b-button>
              </div>
              <div class="split-row"></div>
              <div class="col-xs-12">
                <b-button variant="modal" @click="onShowStats('old')">
                  hotspots
                </b-button>
              </div>
              <div class="split-row"></div>
              <div class="col-xs-12">
                <b-button variant="modal" @click="onConfirm">
                  confirm
                </b-button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <ul class="mb-0" @click="onShowEdit($event)">
      <div
        v-if="options"
        class="dropdown-button"
        @click="onShowDropdown($event)"
      >
        <i class="fa fa-chevron-down" aria-hidden="true"></i>
      </div>
      <li class="brochure__title">
        {{ croppedName() }}
      </li>
      <li class="brochure__labels">
        <span class="flag">
          <img :src="item.flag" />
        </span>
        <span class="label label-custom text-uppercase">{{ shopLabel }}</span>
      </li>
      <li class="brochure__body">
        <div class="row">
          <div class="col-xs-4">
            <div
              :style="thumbnailUrl(item.cover_photo)"
              class="brochure__picture radius-4"
            ></div>
            <div
              v-if="isFrozen"
              class="brochure-position-form__tag mt-2"
              :class="{ frozen: isFrozen }"
            >
              Frozen
            </div>
          </div>
          <div class="col-xs-8">
            <ul class="brochure__info">
              <li v-if="item.categories && item.categories.length">
                <span class="brochure__infoTitle">Categorie:</span>
                <span class="brochure__infoDesc">{{
                  item.brochure_categories[0].name
                }}</span>
              </li>
              <hr />
              <li>
                <span class="brochure__infoTitle">Valid from:</span>
                <span class="brochure__infoDesc">{{
                  dateFromTimestamp(item.active_from)
                }}</span>
              </li>
              <li>
                <span class="brochure__infoTitle">Valid till:</span>
                <span class="brochure__infoDesc">{{
                  dateFromTimestamp(item.expire_after)
                }}</span>
              </li>
              <li>
                <span class="brochure__infoTitle">Publication:</span>
                <span class="brochure__infoDesc">{{
                  timeFromTimestamp(item.published_at)
                }}</span>
              </li>
              <hr />
              <li v-if="item.parser_used">
                <i class="fa fa-folder" aria-hidden="true"></i>
                <a href="#">{{ item.parser_used }}</a>
                <el-tooltip
                  v-if="item.parsed_url"
                  class="item"
                  effect="dark"
                  content="Click to follow parsed url"
                  placement="top"
                >
                  <a :href="item.parsed_url" @click.stop target="_blank">
                    <i class="fa fa-link"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  v-if="recreateParserRegions.length"
                  class="item"
                  effect="dark"
                  content="Click to recreate from parsed url"
                  placement="top"
                >
                  <a href="#" @click.stop="showRecreateParser()">
                    <i class="fa fa-refresh"></i>
                  </a>
                </el-tooltip>
              </li>
              <li v-if="item.author">
                <i :class="getAuthorIcon(item.author)" aria-hidden="true"></i>
                <a href="#">{{
                  getAuthorNameFromUsedDestiny(
                    item.parser_used,
                    item.author,
                    item.last_updated
                  )
                }}</a>
              </li>
              <li>
                <i class="fa fa-link"></i>
                <a :href="website_url(item)" @click.stop target="_blank">
                  <span>Website url</span>
                </a>
              </li>
              <li>
                <span class="brochure__infoTitle">Upload:</span>
                <span class="brochure__infoDesc">{{
                  timeFromTimestamp(item.inserted_at)
                }}</span>
              </li>
              <li>
                <form
                  class="brochure-position-form"
                  @submit.prevent="onPositionSubmit(item)"
                  @click="dontShowEdit"
                >
                  <span class="brochure__infoTitle">Position:</span>
                  <input
                    class="brochure-position-form__input"
                    type="number"
                    v-model="item.position"
                    @focus="togglePositionSubmit(true)"
                  />
                  <button
                    v-if="this.positionSubmitVisible"
                    class="brochure-position-form__button"
                    type="submit"
                    name="button"
                  >
                    save
                  </button>
                </form>
              </li>
              <li>
                <form
                  class="brochure-position-form--frozen"
                  @submit.prevent="onFrozenSubmit(item)"
                  @click="dontShowEdit"
                >
                  <span class="brochure__infoTitle">Frozen till:</span>
                  <button
                    v-if="frozenSubmitVisible"
                    class="brochure-position-form__button"
                    type="submit"
                    name="button"
                  >
                    save
                  </button>
                  <shared-datepicker
                    v-model="frozen"
                    input-class="brochure-position-form__input--frozen"
                    @selected="toggleFrozenSubmit(true)"
                  >
                    <div slot="icon">
                      <div
                        class="brochure-position-form__input__icon custom-icon calendar-icon form-control-feedback"
                      ></div>
                    </div>
                  </shared-datepicker>
                </form>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import brochureStats from "./brochure-stats";
import editBrochure from "./edit";
import { formatDate } from "../../utils";
import sharedDatepicker from "../shared/datepicker";
import * as helper from "./helper";
import { api } from "../../store/api";
import { orderBy } from "lodash";
import * as _ from "lodash";
import moment from "moment";
export default {
  name: "brochure-item",
  props: {
    item: {
      type: Object
    },
    options: {
      type: Boolean,
      default: true
    }
  },
  components: {
    brochureStats,
    editBrochure,
    sharedDatepicker
  },
  created() {
    this.initFrozenDate();
  },
  methods: {
    build_dashboard_url(shop_id, id) {
      let host = window.location.host;
      let url = "https://";
      if (process.env.NODE_ENV !== "production") {
        url = url + "staging.";
      }
      if (host.includes("allefolders")) {
        return `${url}dashboard.allefolders.nl/${shop_id}/overview/${id}`;
      } else {
        return `${url}dashboard.promopromo.be/${shop_id}/overview/${id}`;
      }
    },
    onCloseEdit() {
      this.showEditForm = false;
    },
    onCloseStats() {
      this.showStats = false;
    },
    dontShowEdit(e) {
      e.stopPropagation();
    },
    onPositionSubmit(item) {
      const { id, position } = item;
      this.$store.dispatch("updateOfferPosition", {
        brochure_id: id,
        position: parseInt(position)
      });
      this.togglePositionSubmit(false);
    },
    onFrozenSubmit(item) {
      const { id } = item;
      this.$store.dispatch("updateOfferFreeze", {
        brochure_id: id,
        position_frozen_to: this.frozen.toJSON()
      });

      this.toggleFrozenSubmit(false);
    },
    togglePositionSubmit(isShown) {
      this.positionSubmitVisible = isShown;
    },
    toggleFrozenSubmit(isShown) {
      this.frozenSubmitVisible = isShown;
    },

    onShowEdit(e) {
      if (this.options) {
        e.stopPropagation();
        this.showEditForm = true;
      }
    },
    onShowDropdown(e) {
      e.stopPropagation();
      this.showDropdown = true;
    },
    hideDropdown() {
      this.showDropdown = false;
    },
    onShowStats(version) {
      this.statsVersion = version;
      this.showStats = true;
      this.showDropdown = false;
    },
    onShowRegions() {
      this.showRegions = true;
    },
    onConfirm() {
      var status = this.$refs.currentStatus.localValue;
      var state = { state: helper.BACKEND_BROCHURE_STATUSES[status] };
      this.$store.dispatch("updateBrochureState", {
        id: this.item.id,
        params: state
      });
    },
    thumbnailUrl(url) {
      return `background-image: url(${url})`;
    },
    timeFromTimestamp(tmp) {
      return moment.unix(tmp).format("DD-MM-YYYY HH:mm");
    },
    dateFromTimestamp(tmp) {
      return tmp ? formatDate(tmp * 1000) : "Not set";
    },
    croppedName() {
      let name = this.item.name ? this.item.name : "Not set name";

      return _.truncate(name, {
        length: 25,
        separator: " "
      });
    },
    getAuthorIcon(author) {
      return author == "Parser" ? "fa fa-upload" : "fa fa-pencil";
    },
    getAuthorNameFromUsedDestiny(destiny, author, last_updated) {
      if (last_updated) return last_updated;
      return destiny ? "Parser:" + destiny : author;
    },
    initFrozenDate() {
      this.frozen = new Date(this.item.position_frozen_to * 1000);
    },
    showRecreateParser() {
      this.recreateParserStatus = "visible";
    },
    updateFrozenValue(value) {
      this.frozen = value;
    },
    recreateParser() {
      this.recreateParserStatus = "in_progress";
      this.recreateParserMessages = [];

      api.createParser(
        {
          name: this.item.name,
          shop_id: this.item.shop_id,
          region_id: this.recreateParserRegion.region.id,
          language_id: this.item.language_id,
          parser_id: this.recreateParserRegion.parser_id,
          recreate_brochure_id: this.item.id
        },
        (parser) => {
          this.autoUpdateRecreateParserMessages(parser.id);
        }
      );
    },
    autoUpdateRecreateParserMessages(parserId) {
      api.fetchLastCreatedParserIterations(parserId, (entries) => {
        this.recreateParserMessages = orderBy(
          entries,
          ["inserted_at"],
          ["asc"]
        ).map((e) => e.message);

        if (entries.find((e) => ["finished", "failed"].includes(e.state))) {
          this.recreateParserStatus = "visible";
        } else {
          setTimeout(
            () => this.autoUpdateRecreateParserMessages(parserId),
            2500
          );
        }
      });
    },
    closeRecreateParser() {
      this.recreateParserStatus = "hidden";
    },
    website_url(item) {
      let host = window.location.host;
      if (host.includes("allefolders")) {
        return (
          process.env.VUE_APP_HTTP_HOST_ALLE_FOLDERS +
          this.shop_slug(item.shop.name) +
          "-aanbiedingen/" +
          item.id +
          "/0"
        );
      } else {
        let language = this.$store.getters.getLanguageById(item.language_id);
        let locale = language.name == "Dutch" ? "nl" : "fr";
        return (
          process.env.VUE_APP_HTTP_HOST_PROMO_PROMO +
          locale +
          "/" +
          this.shop_slug(item.shop.name) +
          "-folder/" +
          item.id +
          "/0"
        );
      }
    },
    shop_slug(shop_name) {
      return shop_name.toLowerCase().replaceAll(" ", "-");
    }
  },
  computed: {
    shopLabel() {
      return this.item.shop ? this.item.shop.name : "No shop";
    },
    isDisabledCurrentStatus() {
      let status =
        this.item.active_from ||
        this.item.expire_after ||
        this.item.published_at;
      return !status;
    },
    recreateParserRegions() {
      return this.item.regions.filter((r) => r.parser_id);
    },
    isFrozen() {
      return this.frozen.getTime() > new Date().getTime();
    }
  },
  data() {
    return {
      showDropdown: false,
      showStats: false,
      statsVersion: "old",
      showRegions: false,
      showEditForm: false,
      positionSubmitVisible: false,
      frozen: new Date(),
      frozenSubmitVisible: false,
      currentStatus: this.item.state,
      recreateParserRegion: null,
      recreateParserMessages: [],
      recreateParserStatus: "hidden",
      dropdownStatuses: [
        { text: "Ready For Approval", value: "for_approval" },
        { text: "Active", value: "published" },
        { text: "Archive", value: "archived" }
      ]
    };
  }
};
</script>
