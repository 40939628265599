<template>
  <PageLayout class="identity-user-new">
    <ToolBar to="/users" title="New user" />
    <NewForm />
  </PageLayout>
</template>

<script>
import NewForm from "./new-user-form.vue";
import ToolBar from "../../layout/tool-bar.vue";
import PageLayout from "../../layout/page-layout.vue";

export default {
  name: "identity-user-new",
  components: { NewForm, ToolBar, PageLayout }
};
</script>
