<template>
  <div class="brochure-toolbar-wrapper">
    <new-brochure
      v-if="showNewBrochure"
      :show.sync="showNewBrochure"
      v-on:close="showNewBrochure = false"
    />
    <div id="brochures-toolbar">
      <section id="tools">
        <ul>
          <li>
            <b-button
              @click="showNewBrochure = true"
              variant="tool"
              class="btn-plus lowercase"
            >
              <div class="custom-icon add-circle-icon"></div>
              Add new brochure...
            </b-button>
          </li>
        </ul>
      </section>
      <section id="statuses">
        <ul>
          <label>Status:</label>
          <li
            class="btn btn-tool btn-md"
            v-for="(status, index) in statuses"
            :key="index"
          >
            <input
              @click="onClickStaus(status)"
              v-model="selectedStatus"
              type="radio"
              class="btn btn-tool btn-md"
              :value="status"
            />
            <span>
              {{ transformStatus(status) }}
              ({{ statusesCount(status) }})
            </span>
          </li>
        </ul>
      </section>
      <section id="sort">
        <ul>
          <label>Sort:</label>
          <li>
            <multiselect
              v-model="sortBy"
              :options="sortOptions"
              placeholder="Sort by"
              label="text"
              :show-labels="false"
              track-by="value"
            >
            </multiselect>
          </li>
        </ul>
      </section>
      <section id="filter">
        <ul>
          <label>Filter:</label>
          <a @click.prevent="clearFilters" href="#" class="pull-right"
            >Clear All Filters
          </a>
          <li>
            <multiselect
              v-model="filterBy.language"
              :options="languages"
              placeholder="All languages"
              label="name"
              :show-labels="false"
              track-by="id"
            >
            </multiselect>
          </li>
          <li>
            <multiselect
              v-model="filterBy.category"
              :options="categories"
              placeholder="All categories"
              label="name"
              :show-labels="false"
              track-by="id"
            >
            </multiselect>
          </li>
          <li>
            <multiselect
              v-model="filterBy.shop"
              :options="shops"
              placeholder="All shops"
              label="name"
              :show-labels="false"
              track-by="id"
            >
            </multiselect>
          </li>
          <li>
            <multiselect
              v-model="filterBy.top"
              :options="topOptions"
              placeholder="All top of category"
              label="name"
              :show-labels="false"
              track-by="id"
            >
            </multiselect>
          </li>
          <li>
            <multiselect
              v-model="filterBy.parserClasses"
              :options="parserClasses"
              placeholder="All parser classes"
              label="class"
              :show-labels="false"
              track-by="class"
            >
            </multiselect>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import * as helper from "./helper";
import { mapGetters } from "vuex";

import newBrochure from "./new";
import { setEvents } from "../../mixins/dragoverMixin.js";

export default {
  name: "brochures-toolbar",
  components: { newBrochure },
  data() {
    return {
      sortOptions: helper.BROCHURES_SORT_OPTIONS,
      topOptions: [
        { name: "True", value: true },
        { name: "False", value: false }
      ],
      featuredOptions: [],
      filterBy: {
        language: undefined,
        category: undefined,
        region: undefined,
        shop: undefined,
        top: undefined,
        parserClasses: undefined
      },
      sortBy: helper.BROCHURES_SORT_OPTIONS[0],
      showNewBrochure: false,
      showDuplicateBrochures: false,
      statuses: helper.BROCHURE_STATUSES,
      selectedStatus: helper.BROCHURE_STATUSES[5],
      prevStatus: ""
    };
  },
  computed: {
    languages() {
      return this.$store.getters.allLanguages;
    },
    categories() {
      return this.$store.getters.allCategories;
    },
    regions() {
      return this.$store.getters.allRegions;
    },
    shops() {
      return this.$store.getters.allShops;
    },
    queryParams() {
      return {
        language_ids: this.languageFilterParams,
        region_ids: this.regionFilterParams,
        shop_ids: this.shopFilterParams,
        category_ids: this.categoryFilterParams,
        top: this.topFilterParams,
        parser_classes: this.parserClassesFilterParams
      };
    },
    languageFilterParams() {
      if (this.filterBy.language) {
        return [this.filterBy.language.id];
      } else {
        return undefined;
      }
    },
    regionFilterParams() {
      if (this.filterBy.region) {
        return [this.filterBy.region.id];
      } else {
        return undefined;
      }
    },
    categoryFilterParams() {
      if (this.filterBy.category) {
        return [this.filterBy.category.id];
      } else {
        return undefined;
      }
    },
    shopFilterParams() {
      if (this.filterBy.shop) {
        return [this.filterBy.shop.id];
      } else {
        return undefined;
      }
    },
    topFilterParams() {
      if (this.filterBy.top) {
        return this.filterBy.top.value;
      } else {
        return undefined;
      }
    },
    parserClassesFilterParams() {
      if (this.filterBy.parserClasses) {
        return [this.filterBy.parserClasses.class];
      } else {
        return undefined;
      }
    },
    parserClasses() {
      return this.$store.getters.allParserClasses;
    }
  },
  methods: {
    onClickStaus(status) {
      this.showDuplicateBrochures = false;
      this.$emit("hide-duplicate");

      if (this.prevStatus == status) {
        this.prevStatus = "switched";
        this.selectedStatus = status;
      }
    },
    onDrop(e) {
      var status = e.target.defaultValue;
      var brochure_id = e.dataTransfer.getData("data");
      var moveable = e.dataTransfer.getData("moveable");

      if (!moveable) {
        alert(
          "This brochure has currupted date. Edit it before changing status"
        );
        return;
      }

      if (helper.BROCHURE_STATUSES.indexOf(status) != -1 && brochure_id) {
        var backend_status = helper.FRONTEND_TO_BACKEND_STATUSES[status];

        var state = { state: helper.BACKEND_BROCHURE_STATUSES[backend_status] };
        this.$store.dispatch("updateBrochureState", {
          id: brochure_id,
          params: state
        });
      }
    },
    transformStatus(status) {
      return status.toLowerCase().replace(/_/g, " ");
    },
    statusesCount(status) {
      return this.$store.getters.brochureCounters[helper.COUNTER_NAME[status]];
    },
    clearFilters() {
      for (let key in this.filterBy) {
        this.filterBy[key] = undefined;
      }
    },
    onShowDuplicateBrochures() {
      this.showDuplicateBrochures = !this.showDuplicateBrochures;
      if (this.showDuplicateBrochures) {
        this.$emit("show-duplicate");
      } else {
        this.$emit("hide-duplicate");
      }
    }
  },
  mounted() {
    let val = helper.FRONTEND_TO_BACKEND_STATUSES[this.selectedStatus];
    this.$store.dispatch("changeSelectedStatus", val);
    this.$store.dispatch("getBrochureCounters");
    this.$store.dispatch("fetchParserClasses");
    var element = document.getElementById("statuses");
    var elements = element.children[0].children;

    for (var i = 0; i < elements.length; i++) {
      if (elements[i].nodeName == "LI") {
        setEvents(elements[i], this);
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch("resetFilter");
  },
  watch: {
    sortBy: function (val) {
      this.$store.dispatch("putSortKey", val);
    },
    selectedStatus: function (val, old) {
      if (old == "" || val != "") {
        this.prevStatus = val;
      }

      val = helper.FRONTEND_TO_BACKEND_STATUSES[val];

      this.$store.dispatch("changeSelectedStatus", val);
    },
    filterBy: {
      handler: function (val) {
        this.$set(this, "filterBy", val);
        this.$store.dispatch("changeFilterBy", this.queryParams);
      },
      deep: true
    }
  }
};
</script>
