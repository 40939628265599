<template>
  <div
    id="content-layout"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
  >
    <sidebar />
    <div id="content-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import sidebar from "../components/sidebar/index.vue";

export default {
  name: "content-layout",
  components: { sidebar },
  created() {
    this.$store.dispatch("getAllLanguages");
  }
};
</script>
