<template>
  <div class="logo">
    <img :src="logoUrl" />
    <!-- <object type="image/svg+xml" data="images/logo.svg" class="logo">
    </object> -->
  </div>
</template>

<script>
import isMainDomain from "../../mixins/isMainDomain";

export default {
  name: "logo",
  mixins: [isMainDomain],
  computed: {
    logoUrl() {
      if (this.isMainDomain()) {
        return "images/logo.png";
      } else {
        return "images/promopromo_logo.png";
      }
    }
  }
};
</script>
