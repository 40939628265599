<template>
  <div @click="hide" class="slider__hide"></div>
</template>

<script>
export default {
  name: "Hide",
  created() {
    window.addEventListener("keydown", this.handleKeyDown, true);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown, true);
  },
  methods: {
    handleKeyDown(e) {
      e = e || window.event;

      switch (e.keyCode) {
        case 27:
          this.hide();
          break;
      }
    },
    hide() {
      this.$store.dispatch("hidePreview");
    }
  }
};
</script>
