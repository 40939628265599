<template>
  <section class="content-toolbar">
    <new-shop
      v-if="showNewShopForm"
      :show.sync="showNewShopForm"
      @close="showNewShopForm = false"
    />
    <span class="page-title align-center"
      >Shops
      <el-tag v-if="shopsCounts" class="ml-1">{{ shopsCounts }}</el-tag>
    </span>
    <ul class="tools pull-right">
      <li class="ml-2 mr-2">
        <div role="group" class="btn-group" id="shops-filter-group">
          <div
            class="btn btn-tool btn-md"
            v-for="(item, index) in availableFilters"
            :key="index"
          >
            <input
              v-model="filter"
              @click="filterClick()"
              type="radio"
              class="btn btn-tool btn-md"
              :value="item"
            />
            <span
              :class="isActiveItemClassName(item)"
              class="text-capitalize"
              >{{ item.name }}</span
            >
          </div>
        </div>
      </li>
      <li class="ml-1">
        <div class="input-group toolbar-search">
          <input
            v-model="search"
            type="text"
            class="form-control"
            placeholder="Search"
            aria-describedby="btnGroupAddon"
          />
          <span class="input-group-addon" id="btnGroupAddon">
            <div class="custom-icon search-icon"></div>
          </span>
        </div>
      </li>
      <li class="ml-2">
        <b-button
          @click="showNewShopForm = true"
          variant="tool"
          class="btn-plus"
        >
          <div class="custom-icon add-circle-icon"></div>
          Add shop...
        </b-button>
      </li>
    </ul>
  </section>
</template>

<script>
import * as configs from "../../config";
import newShop from "./new";
import * as _ from "lodash";

export default {
  name: "shops-toolbar",
  components: { newShop },
  data() {
    return {
      showNewShopForm: false,
      availableFilters: configs.SHOPS_FILTERS,
      filter: "",
      lastFilter: "",
      search: "",
      lastSearched: ""
    };
  },
  computed: {
    shopsCounts() {
      return this.$store.getters.shopsCounts;
    }
  },
  destroyed() {
    this.$store.dispatch("shopReset");
  },
  watch: {
    search(query) {
      this.onSearch(query);
    }
  },
  methods: {
    onSearch: _.debounce(function (query) {
      if (this.lastSearched != query) {
        this.$store.dispatch("shopSearchChange", query);
        this.lastFilter = this.filter;
        this.filter = undefined;
      }
      this.lastSearched = query;
    }, 500),
    filterClick() {
      let dispatched = this.filter.value;

      if (dispatched == this.lastFilter) {
        dispatched = undefined;
        this.filter = undefined;
      }

      this.$store.dispatch("shopFilterChange", dispatched);
      this.lastFilter = dispatched;
    },
    isActiveItemClassName(item) {
      if (this.filter) {
        return this.filter.name == item.name ? "active" : "";
      }
    }
  }
};
</script>
