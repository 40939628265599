<template>
  <div class="content-toolbar">
    <span
      v-if="isData"
      v-for="(item, index) in data"
      class="page-title"
      :key="index"
    >
      <router-link v-if="item.url" :to="item.url" class="d-inline-block mr-1">
        <div class="custom-icon back-icon"></div>
      </router-link>

      <span class="mr-1">
        <img
          v-if="item.flag"
          :src="item.flag"
          alt="flag"
          class="breadcrumb-language-img mr-1"
        />
        <span v-if="item.name">{{ item.name }}</span>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ["data"],
  computed: {
    isData() {
      return this.data && this.data.length;
    }
  }
};
</script>
