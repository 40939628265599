import { api } from "../api";
import * as _ from "lodash";

import * as types from "../mutation-types";

const state = {
  all: []
};

const getters = {
  allCategories: (state) => state.all,
  categoriesByLanguage: (state) => (language) => {
    if (!language) return state.all;
    return state.all.filter((category) => {
      return category.language && category.language.id == language.id;
    });
  }
};

const actions = {
  fetchCategories({ commit }) {
    if (!state.all.length) {
      api.getCategories((categories) => {
        commit(types.RECEIVE_CATEGORIES, { categories });
      });
    }
  },
  createCategory({ commit }, params) {
    api.createCategory(params, (category) => {
      commit(types.CREATE_CATEGORY, { category });
    });
  },
  updateCategory({ commit }, { id, params }) {
    api.updateCategory(id, params, (category) => {
      commit(types.UPDATE_CATEGORY, { category });
    });
  },
  deleteCategory: ({ commit }, id) => {
    api.deleteCategory(id, (ok) => {
      if (ok) {
        commit(types.DELETE_CATEGORY, id);
      }
    });
  }
};

const mutations = {
  [types.RECEIVE_CATEGORIES](state, { categories }) {
    state.all = categories;
  },
  [types.CREATE_CATEGORY](state, { category }) {
    state.all.push(category);
  },
  [types.UPDATE_CATEGORY](state, { category }) {
    let index = _.findIndex(state.all, (c) => {
      return c.id == category.id;
    });
    let oldObject = state.all[index];

    category.active_count = oldObject.active_count;
    category.not_published_count = oldObject.not_published_count;
    category.expired_count = oldObject.expired_count;
    category.shops_count = oldObject.shops_count;

    state.all.splice(index, 1, category);
  },
  [types.DELETE_CATEGORY](state, id) {
    state.all = state.all.filter((category) => {
      return category.id != id;
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
