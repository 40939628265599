import gql from "graphql-tag";

const adsAdvertisement = gql`
  query adsAdvertisement($adsAdvertisement: AdsAdvertisementInput!) {
    adsAdvertisement(adsAdvertisement: $adsAdvertisement) {
      id
      adType
      customerClient {
        id
        name
      }
      displayAt
      displayStartAt
      displayStopAt
      displayUrl
      id
      impressionsLimit
      language {
        id
        name
        symbol
      }
      platformType
      shops {
        name
        id
      }
      categories {
        name
        id
      }
      status
      weight

      ... on AdsAdvertisementImage {
        fileUrl
      }
    }
  }
`;

export default adsAdvertisement;
