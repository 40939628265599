import { render, staticRenderFns } from "./page-layout.vue?vue&type=template&id=854f77c2&scoped=true&"
var script = {}
import style0 from "./page-layout.vue?vue&type=style&index=0&id=854f77c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "854f77c2",
  null
  
)

export default component.exports