import * as _ from "lodash";

export default {
  methods: {
    filterArrayByText(array, text) {
      return array.filter((row) => {
        let compareValues = _.values(row);

        return compareValues.some((item) => {
          if (_.includes(_.toLower(item), _.toLower(text))) return true;
        });
      });
    },
    filterArrayByType(array, type) {
      return array.filter((row) => row.customer == type);
    }
  }
};
