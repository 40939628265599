<template>
  <div class="ads-overview">
    <DataTable>
      <TableHeader :headers="columns" />
      <TableBody :columns="columns" :data="adsList" :rowCallback="onAdClick" />
    </DataTable>
    <Pagination
      @update="paginationTo"
      v-if="adsAdvertisements && adsAdvertisements.pagination"
      :pagination="adsAdvertisements.pagination"
    />
  </div>
</template>

<script>
import DataTable from "../dataTable/table.vue";
import TableBody from "../dataTable/table-body.vue";
import TableHeader from "../dataTable/table-header.vue";
import Pagination from "../dataTable/pagination.vue";

import AdsAdvertisementsQuery from "../../graphql/queries/AdsAdvertisements";

const PAGE_SIZE = 25;

export default {
  name: "ads-overview",
  components: {
    DataTable,
    TableHeader,
    TableBody,
    Pagination
  },
  apollo: {
    adsAdvertisements: {
      query: AdsAdvertisementsQuery,
      deep: true,
      variables() {
        return {
          adsAdvertisements: this.adsVariables,
          pagination: this.pagination
        };
      },
      result({ data }) {
        return data;
      }
    }
  },
  data() {
    return {
      pagination: {
        page: 1,
        pageSize: PAGE_SIZE
      },
      adsVariables: {
        orderBy: "DISPLAY_START_AT_DESC"
      },
      columns: [
        { label: "Customer", value: "customerClient" },
        { label: "Ad", value: "adType" },
        { label: "Start", value: "startAt" },
        { label: "End", value: "stopAt" },
        { label: "Status", value: "status" },
        { label: "Platform", value: "platformType" },
        { label: "DisplayAt", value: "displayAt" }
      ]
    };
  },
  methods: {
    paginationTo(page) {
      this.pagination.page = page;
    },
    onAdClick({ data, _event }) {
      if (data && data.id && data.adType == "IMAGE_AD")
        this.$router.push({ path: "/ads/edit/", query: { id: data.id } });
      if (data && data.id && data.adType == "HTML_EMBED")
        this.$router.push({
          path: "/ads/edit-html-embed/",
          query: { id: data.id }
        });
    }
  },
  computed: {
    tableColumns() {
      return this.columns.length;
    },
    adsList() {
      if (
        this.adsAdvertisements &&
        this.adsAdvertisements.entries &&
        this.adsAdvertisements.entries.length
      )
        return this.adsAdvertisements.entries.map((entry) => {
          const ad = {};
          ad.customerClient = entry.customerClient.name;
          ad.adType = entry.adType;
          ad.startAt = new Date(entry.displayStartAt).toLocaleDateString();
          ad.stopAt = new Date(entry.displayStopAt).toLocaleDateString();
          ad.status = entry.status;
          ad.platformType = entry.platformType;
          ad.displayAt = entry.displayAt;
          ad.id = entry.id;
          return ad;
        });
      else return [];
    }
  }
};
</script>
