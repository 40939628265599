import gql from "graphql-tag";

const updateIdentityUser = gql`
  mutation UpdateIdentityUser($updateIdentityUser: UpdateIdentityUserInput!) {
    updateIdentityUser(updateIdentityUser: $updateIdentityUser) {
      identityUser {
        id
        email
        firstName
        lastName
        roles
        customerClients {
          id
          name
        }
      }
    }
  }
`;

export default updateIdentityUser;
