export const draggableMixin = {
  mounted() {
    var el = this.$el;
    var self = this;
    var counter = 0;
    el.addEventListener("dragstart", function (e) {
      counter == 0;
      if (self.onDragstart) self.onDragstart(e);
    });
    el.addEventListener("dragend", function (e) {
      counter = 0;
      if (self.onDragend) self.onDragend(e);
    });

    el.addEventListener("dragenter", function (e) {
      counter++;
      e.preventDefault();
      e.stopPropagation();
      if (!self.$el.children[0].classList.contains("dragstart")) {
        self.$el.children[0].classList.add("dragover");
      }
    });
    el.addEventListener("dragleave", function (e) {
      counter--;
      e.preventDefault();
      e.stopPropagation();
      if (counter == 0) {
        self.$el.children[0].classList.remove("dragover");
      }
    });
    el.addEventListener("dragover", function (e) {
      e.preventDefault();
      e.stopPropagation();
    });

    el.addEventListener("drop", function (e) {
      e.preventDefault();
      e.stopPropagation();
      counter = 0;
      self.onDrop(e);
    });
  }
};
