<template>
  <div v-if="lastIterations.length" class="manual-parser">
    <h2 class="text-xs-center mb-1">Manual Upload Report</h2>

    <div class="panel-background border p-1 radius-4">
      <div
        v-for="(iteration, index) in lastIterations"
        class="row"
        :key="index"
      >
        <div class="col-xs-12 col-sm-6 col-lg-2">
          {{ changeToTime(iteration.inserted_at) }}
        </div>
        <div class="col-xs-12 col-sm-6 col-lg-10">{{ iteration.message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Socket } from "phoenix";
import moment from "moment";

export default {
  name: "Manual",
  components: {},
  data() {
    return {
      socket: undefined,
      channel: undefined
    };
  },
  computed: {
    lastParser() {
      return this.$store.getters.lastCreatedParser;
    },
    lastIterations() {
      return this.$store.getters.lastCreatedParserIterations;
    }
  },
  watch: {
    lastParser() {
      this.$store.dispatch(
        "fetchLastCreatedParserIterations",
        this.lastParser.id
      );

      this.socket = this.createSocketConnection();
      this.channel = this.createChannelConnection(
        this.socket,
        "parser:" + this.lastParser.id
      );
      this.bindChannelEventWithData(this.channel, "new_message");
    }
  },
  destroyed() {
    this.$store.dispatch("resetLastCreatedParserIteration");
  },
  methods: {
    createSocketConnection() {
      let socket = new Socket(process.env.VUE_APP_WSS_HOST + "/socket");
      socket.connect();

      return socket;
    },
    createChannelConnection(socket, channelName) {
      let channel = socket.channel(channelName, {});
      channel
        .join()
        .receive("ok", (resp) => {
          console.info("Joined successfully", resp);
        })
        .receive("error", (resp) => {
          console.info("Unable to join", resp);
        });

      return channel;
    },
    bindChannelEventWithData(channel, eventName) {
      channel.on(eventName, (iteration) => {
        this.$store.dispatch("pushLastCreatedParserIteration", iteration);
      });
    },
    changeToTime(timestamp) {
      return moment.unix(timestamp).format("HH:mm DD-MM-YYYY");
    }
  }
};
</script>
