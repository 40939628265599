<template>
  <section class="container">
    <div v-for="(value, lang) in languages" :key="lang">
      <h3>{{ lang }}</h3>
      <DescriptionForm
        class="row Config__language-form"
        :language="lang"
        :description="value.description"
      />
      <DisclaimerForm
        class="row Config__language-form"
        :language="lang"
        :disclaimer="value.disclaimer"
      />
      <InfoForm
        class="row Config__language-form"
        :language="lang"
        :info="value.info"
      />
    </div>
  </section>
</template>

<script>
import DescriptionForm from "./description";
import DisclaimerForm from "./disclaimer";
import InfoForm from "./info";

export default {
  name: "config",

  components: { DescriptionForm, DisclaimerForm, InfoForm },

  computed: {
    languages() {
      return this.$store.state.config.languages;
    }
  },

  created() {
    this.$store.dispatch("config/load");
  },

  methods: {
    save() {
      console.debug("Submitting");
    }
  }
};
</script>
