<template>
  <form class="brochure-form row">
    <div class="col-md-9">
      <div class="row">
        <div class="col-md-4">
          <div
            :class="[
              'form-group',
              'has-feedback',
              $v.dataObject.active_from.$error ? 'has-danger' : ''
            ]"
          >
            <label class="control-label col-form-label">Valid from:</label>
            <shared-datepicker
              v-model="dataObject.active_from"
              input-class="form-control form-control-sm"
            >
              <div slot="icon">
                <div
                  class="custom-icon calendar-icon form-control-feedback"
                ></div>
              </div>
            </shared-datepicker>
          </div>
        </div>
        <div class="col-md-4">
          <div
            :class="[
              'form-group',
              'has-feedback',
              !this.isValidTillDateValid || $v.dataObject.expire_after.$error
                ? 'has-danger'
                : ''
            ]"
          >
            <label class="control-label col-form-label">Valid till:</label>
            <shared-datepicker
              v-model="dataObject.expire_after"
              :baseDate="dataObject.active_from"
              baseTime="finish"
              type="defaults"
              input-class="form-control form-control-sm"
            >
              <div slot="icon">
                <div
                  class="custom-icon calendar-icon form-control-feedback"
                ></div>
              </div>
            </shared-datepicker>
          </div>
          <label
            v-if="!isValidTillDateValid"
            class="col-form-label col-xs-12 error-label"
          >
            Valid from date cannot be greater than Valid till date.
          </label>
        </div>
        <div class="col-md-4">
          <div
            :class="[
              'form-group',
              'has-feedback',
              this.isPublishedDateValid ? '' : 'has-danger'
            ]"
          >
            <label class="control-label col-form-label">Publication:</label>
            <shared-datepicker
              v-model="dataObject.published_at"
              :baseDate="dataObject.active_from"
              input-class="form-control form-control-sm"
            >
              <div slot="icon">
                <div
                  class="custom-icon calendar-icon form-control-feedback"
                ></div>
              </div>
            </shared-datepicker>
          </div>
          <label
            v-if="!isPublishedDateValid"
            class="col-form-label col-xs-12 error-label"
          >
            Publication date cannot be greater than Valid till date.
          </label>
        </div>
      </div>
      <fieldset class="form-group row">
        <label class="col-form-label col-xs-12">Shop *</label>
        <div :class="['col-xs-12', $v.dataObject.shop.$error ? 'invalid' : '']">
          <multiselect
            v-model="dataObject.shop"
            :options="shops"
            :disabled="isUploaded"
            placeholder="Select shop"
            @input="onChangeShop"
            label="name"
            track-by="name"
          >
          </multiselect>
          <label
            v-if="$v.dataObject.shop.$error"
            class="col-form-label col-xs-12 error-label"
          >
            You must select at least one
          </label>
        </div>
      </fieldset>
      <b-form-input
        v-model="dataObject.name"
        @input="$v.dataObject.name.$touch()"
        type="text"
        label="Name *"
        :state="$v.dataObject.name.$error ? 'danger' : ''"
        placeholder="Title Of Brochure"
        size="sm"
      />

      <div class="form-group row">
        <label class="col-form-label col-xs-12">Customer Level</label>
        <div class="col-xs-12">
          <multiselect
            v-model="dataObject.customer_level"
            :options="customer_levels"
            placeholder="Select customer level"
          >
          </multiselect>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <fieldset class="form-group row">
            <label class="col-form-label col-xs-12">Language *</label>
            <div
              :class="[
                'col-xs-12',
                $v.dataObject.language.$error ? 'invalid' : ''
              ]"
            >
              <multiselect
                v-model="dataObject.language"
                @input="$v.dataObject.language.$touch()"
                :options="languages"
                :hide-selected="true"
                :class="multiselectFlagState(dataObject.language)"
                placeholder="Select language"
                label="name"
                track-by="id"
              >
                <div slot="loading">
                  <img
                    v-if="dataObject.language"
                    :src="dataObject.language.file_small_url"
                    class="table-language-img select__icon"
                  />
                </div>
              </multiselect>
              <label
                v-if="$v.dataObject.language.$error"
                class="col-form-label col-xs-12 error-label"
              >
                You must select at least one
              </label>
            </div>
          </fieldset>
        </div>
        <div class="col-md-6">
          <fieldset class="form-group row">
            <label class="col-form-label col-xs-12">Hotspots</label>
            <div class="col-xs-12">
              <multiselect
                v-model="dataObject.hotspot_mode"
                :options="hotspot_mode"
                placeholder="Select hotspot"
                label="name"
                track-by="value"
              >
              </multiselect>
            </div>
          </fieldset>
        </div>
      </div>
      <fieldset class="form-group row">
        <label class="col-form-label col-xs-12">Categories</label>
        <div
          :class="[
            'col-xs-12',
            $v.dataObject.brochure_categories.$error ? 'invalid' : ''
          ]"
        >
          <multiselect
            v-model="dataObject.brochure_categories"
            :options="categories"
            :multiple="true"
            @input="$v.dataObject.brochure_categories.$touch()"
            placeholder="Select categories"
            label="name"
            track-by="id"
          >
          </multiselect>
          <label
            v-if="$v.dataObject.brochure_categories.$error"
            class="col-form-label col-xs-12 error-label"
          >
            You must select at least one
          </label>
        </div>
      </fieldset>
      <div class="row">
        <div class="col-md-6">
          <fieldset class="form-group row">
            <label class="col-form-label col-xs-12"
              >Featured In Top Category Pile</label
            >
            <div class="col-xs-12">
              <multiselect
                v-model="dataObject.brochure_toplevel"
                :options="categories"
                :multiple="true"
                placeholder="Select categories"
                label="name"
                track-by="id"
              >
              </multiselect>
            </div>
          </fieldset>
        </div>
        <div class="col-md-6">
          <fieldset class="form-group row">
            <label class="col-form-label col-xs-12"
              >Featured In Afterpage</label
            >
            <div class="col-xs-12">
              <multiselect
                v-model="dataObject.brochure_afterpage"
                :options="categories"
                :multiple="true"
                placeholder="Select categories"
                label="name"
                track-by="id"
              >
              </multiselect>
            </div>
          </fieldset>
        </div>
      </div>
      <b-form-input
        v-model="dataObject.original_link"
        type="text"
        label="Original link"
        placeholder="Type URL..."
        size="sm"
      />
      <b-form-input
        v-model="dataObject.utm_campaign"
        @input="$v.dataObject.utm_campaign.$touch()"
        :state="$v.dataObject.utm_campaign.$error ? 'danger' : ''"
        type="text"
        label="UTM Campaign"
        placeholder="Type UTM..."
        size="sm"
      />
      <div class="col-md-12">
        <fieldset class="form-group row">
          <label for="notify" class="col-form-label col-xs-12"
            >Notify users</label
          >
          <div class="col-xs-12">
            <input type="checkbox" id="notify" v-model="dataObject.notify" />
          </div>
        </fieldset>
        <fieldset class="form-group row">
          <label for="append" class="col-form-label col-xs-12"
            >Append original link with utms</label
          >
          <div class="col-xs-12">
            <input
              type="checkbox"
              id="append"
              v-model="dataObject.append_original_link"
            />
          </div>
        </fieldset>
      </div>
    </div>
    <div class="col-md-3 pl-2">
      <div class="brochure-attachment">
        <div class="title text-xs-right">Attachment *</div>
        <ul v-if="isAttachment">
          <li v-for="region in dataObject.regions" :key="region.id">
            <uploader
              @preview="showRegionPages($event)"
              @uploaded="onAttachmentUploaded"
              @delete-uploaded="onDeleteUploaded"
              @started="onUploadStarted"
              :name="getRegionName(region)"
              :regionId="region.id"
              :preview="fetchPreviewFromRegion(region)"
            />
          </li>

          <div
            v-if="emptyShopRegions"
            class="panel-background radius-4 border mt-1 p-1"
          >
            Empty shop regions!
          </div>
        </ul>
      </div>

      <div
        v-if="!isAttachment"
        class="panel-background radius-4 border mt-1 p-1"
      >
        To show attachment please select shop!
      </div>
    </div>
  </form>
</template>

<script>
const helper = require("./helper");
import sharedDatepicker from "../shared/datepicker";
import uploader from "../shared/uploader";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import multiselectImg from "../../mixins/multiselectImg.js";
import moment from "moment";
import * as _ from "lodash";

export default {
  name: "brochure-form",
  mixins: [validationMixin, multiselectImg],
  components: { sharedDatepicker, uploader },
  props: {
    struct: {
      type: Object
    }
  },
  data() {
    return {
      customer_levels: [1, 2, 3, 4, 5],
      isUploadingNow: false,
      formLayout: "vertical",
      dataObject: {},
      attachments: {},
      isUploaded: false,
      uploaded: [],
      uploading: [],
      hotspot_mode: helper.hotspot_mode
    };
  },
  computed: {
    shops() {
      return this.$store.getters.allShops;
    },
    categories() {
      return this.$store.getters.categoriesByLanguage(this.dataObject.language);
    },
    languages() {
      return this.$store.getters.allLanguages;
    },
    isAttachment() {
      return this.dataObject.shop && this.dataObject.regions;
    },
    emptyShopRegions() {
      return !this.dataObject.shop.regions.length;
    },
    isValidTillDateValid() {
      if (!this.dataObject.active_from) {
        return true;
      } else {
        return this.dataObject.expire_after >= this.dataObject.active_from;
      }
    },
    isPublishedDateValid() {
      if (!this.dataObject.expire_after) {
        return true;
      } else {
        return this.dataObject.expire_after >= this.dataObject.published_at;
      }
    },
    utmCounter() {
      return this.$store.getters.utmCounter;
    },
    defaultUtmCampaign() {
      let year = moment().format("YYYY");
      let week = moment().format("W");
      let lang = this.dataObject.language || this.languages[0];

      return `${year}${week}${lang.symbol}${this.utmCounter + 1}`;
    }
  },
  mounted() {
    this.$store.dispatch("getShopOptions");
    this.$set(this, "dataObject", this.struct);
    this.setDefaultUtm();
    this.checkUploadedPdf(this.dataObject.shop);
  },
  methods: {
    isValid() {
      return (
        !this.$v.$invalid &&
        !this.isUploadingNow &&
        this.isValidTillDateValid &&
        this.isPublishedDateValid &&
        !this.uploading.length
      );
    },
    validateAll() {
      this.$v.$touch();
    },
    onUploadStarted({ id }) {
      this.uploading.push(id);
    },
    onDeleteUploaded(region_id) {
      delete this.attachments[region_id];
      this.$emit("update-attachments", this.attachments);
    },
    onAttachmentUploaded({ file, id }) {
      this.isUploaded = true;
      this.isUploadingNow = false;

      this.uploaded.push(id);
      this.uploading = this.uploading.filter((item) => item !== id);

      this.attachments[id] = file;
      this.$emit("update-attachments", this.attachments);
    },
    fetchPreviewFromRegion(region) {
      if (region && region.pages && region.pages.length) {
        return region.pages[0].file_small_url;
      } else {
        return null;
      }
    },
    onChangeShop(val) {
      this.attachments = {};
      this.$emit("update-attachments", this.attachments);
      if (!this.dataObject.shop) return;

      this.$store.dispatch("getBrochureUtmCounter", {
        shop_id: this.dataObject.shop.id
      });

      if (this.dataObject.shop) {
        this.dataObject.customer_level = this.dataObject.shop.customer_level;
        this.dataObject.brochure_categories = this.setValueWhenEmpty(
          this.dataObject.brochure_categories,
          this.dataObject.shop.categories
        );
        this.dataObject.brochure_toplevel = this.setValueWhenEmpty(
          this.dataObject.brochure_toplevel,
          this.dataObject.shop.shop_toplevel
        );
        this.dataObject.brochure_afterpage = this.setValueWhenEmpty(
          this.dataObject.brochure_afterpage,
          this.dataObject.shop.shop_afterpage
        );
        this.dataObject.hotspot_mode = this.hotspot_mode.filter((option) => {
          return option.value === this.dataObject.shop.hotspot_mode;
        })[0];
      }
      this.dataObject.regions = this.resolveRegionsFromShop(val);
      this.$v.dataObject.shop.$touch();
    },
    resolveRegionsFromShop(val) {
      let regions = val.regions.map((reg) => {
        return reg;
      });
      return _.sortBy(regions, ["name"]);
    },
    setDefaultUtm() {
      if (!this.dataObject.utm_campaign.length) {
        this.dataObject.utm_campaign = this.defaultUtmCampaign;
      }
    },
    showRegionPages(regionId) {
      if (this.dataObject.id) {
        this.$store.dispatch("showPreview", {
          brochureId: this.dataObject.id,
          regionId: regionId
        });
      }
    },
    getRegionName(region) {
      return region.name ? region.name : region.region.name;
    },
    setValueWhenEmpty(item, value) {
      return !item.length ? value : item;
    },
    checkUploadedPdf(attachments) {
      this.isUploaded = !_.isEmpty(attachments);
    }
  },
  validations: {
    dataObject: {
      active_from: {
        required
      },
      expire_after: {
        required
      },
      name: {
        required
      },
      shop: {
        required
      },
      utm_campaign: {
        required
      },
      language: {
        required
      },
      brochure_categories: {
        required
      }
    }
  },
  watch: {
    dataObject: {
      handler: function (val) {
        this.$emit("update-data", val);
      },
      deep: true
    },
    defaultUtmCampaign(val, oldVal) {
      if (this.dataObject.utm_campaign == oldVal) {
        this.dataObject.utm_campaign = val;
      }
    }
  }
};
</script>
