<template>
  <div class="table-extra" id="table-shop-controls">
    <shop-brochures
      v-if="showShopBrochures"
      :show.sync="showShopBrochures"
      @close="showShopBrochures = false"
      :item="item"
    />
    <edit-shop
      v-if="showEditForm"
      :show.sync="showEditForm"
      @close="showEditForm = false"
      :shop="item"
    />
    <modal
      v-if="showDeleteModal"
      :customerClass="'modal-type-dialog'"
      :show.sync="showDeleteModal"
      @cancel="onHideDelete"
      :closeIcon="false"
      :large="true"
    >
      <div slot="header">
        <h3><b>Delete</b></h3>
      </div>
      <h6>Are you sure? You couldn't restore it back.</h6>
      <div slot="footer">
        <b-button
          class="active btn-ln-md"
          variant="modal"
          @click="onHideDelete"
        >
          No
        </b-button>
        <b-button class="btn-ln-md" variant="modal" @click="onDelete">
          Yes
        </b-button>
      </div>
    </modal>
    <ul class="table-controls">
      <li @click="showShopBrochures = true">
        <div class="custom-icon list-icon"></div>
      </li>
      <li>
        <router-link :to="{ path: item.id + '/locations' }" append>
          <div class="custom-icon location-icon"></div>
        </router-link>
      </li>
      <li @click="onStats()">
        <div class="custom-icon stats-icon"></div>
      </li>
      <li @click="showEditForm = true">
        <div class="custom-icon pen-icon"></div>
      </li>
      <li @click="updateRemovedFromSearchResults">
        <div class="custom-icon search-icon">
          <div
            v-if="!this.item.removed_from_search"
            class="custom-icon crossed"
          ></div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import modal from "../shared/modal";
import shopBrochures from "./shop-brochures";
import editShop from "./edit";

export default {
  name: "table-shop-controls",
  components: { modal, shopBrochures, editShop },
  props: ["item"],
  data() {
    return {
      showDeleteModal: false,
      showEditForm: false,
      showShopBrochures: false
    };
  },
  methods: {
    onShowDelete() {
      this.showDeleteModal = true;
    },
    onHideDelete() {
      this.showDeleteModal = false;
    },
    onDelete() {
      this.$store.dispatch("deleteShop", this.item.id);
      this.onHideDelete();
    },
    updateRemovedFromSearchResults() {
      this.$store.dispatch("updateShop", {
        id: this.item.id,
        params: { removed_from_search: !this.item.removed_from_search }
      });
    },
    onStats() {
      this.$emit("onStats", this.item);
    }
  }
};
</script>
