export const RESOURCES_URL = process.env.VUE_APP_API_HOST + "/api/admin";

export const SHOPS_FILTERS = [
  {
    name: "Shops",
    value: "customer"
  },
  {
    name: "Vital Shops",
    value: "vital"
  },
  {
    name: "Non-Vital Shops",
    value: "non_customer"
  }
];

export const BROCHURES_DEF_SORT_KEY = "published_at";
