<template>
  <div class="category-form">
    <form @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-md-5">
          <b-form-input
            v-model="data.name"
            @input="$v.data.name.$touch()"
            type="text"
            label="Name *"
            :state="$v.data.name.$error ? 'danger' : ''"
            placeholder="Type text..."
            size="sm"
          />
        </div>
        <div class="col-md-3">
          <div class="form-group row">
            <label class="col-form-label col-xs-12">Language</label>
            <div
              :class="['col-xs-12', $v.data.language.$error ? 'invalid' : '']"
            >
              <multiselect
                v-model="data.language"
                @input="$v.data.language.$touch()"
                :options="languages"
                :class="multiselectFlagState(data.language)"
                :show-labels="false"
                placeholder="Select"
                label="name"
                track-by="name"
              >
                <div slot="loading">
                  <img
                    v-if="data.language"
                    :src="data.language.file_small_url"
                    class="table-language-img select__icon"
                  />
                </div>
              </multiselect>
              <label
                v-if="$v.data.language.$error"
                class="col-form-label col-xs-12 error-label"
              >
                You must select at least one
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-4 upload-icon-form">
          <div class="form-group row">
            <label class="col-form-label col-xs-12">Icon</label>
            <div class="col-md-6">
              <input :id="uid" @change="changeIcon" type="file" hidden />
              <b-button
                variant="modal"
                @click="chooseFile(uid)"
                class="btn-narrow"
              >
                choose file
              </b-button>
            </div>
            <div class="col-md-6 input-value">
              <label v-if="!data.icon">No file selected</label>
              <label v-if="data.icon">{{ displayIconName(data.icon) }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <textarea
          placeholder="Type About Category..."
          class="form-full-width"
          name="about"
          v-model="data.about"
        ></textarea>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-input
            v-model="data.title_main"
            type="text"
            label="Meta - Title Main"
            placeholder="Type Title..."
            size="sm"
          />
        </div>
        <div class="col-md-6">
          <b-form-input
            v-model="data.title_secondary"
            type="text"
            label="Meta - Title Secondary"
            placeholder="Type Secondary Title..."
            size="sm"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-input
            v-model="data.meta_description"
            type="text"
            label="Meta - Description"
            placeholder="Type Meta Description..."
            size="sm"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import * as common from "../../utils/common.js";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Vue from "vue";
import multiselectImg from "../../mixins/multiselectImg.js";
import * as _ from "lodash";

export default {
  name: "category-form",
  mixins: [validationMixin, multiselectImg],
  computed: {
    languages() {
      return this.$store.getters.allLanguages;
    }
  },
  props: {
    struct: {
      type: Object
    }
  },
  validations: {
    data: {
      name: {
        required
      },
      language: {
        required
      }
    }
  },
  data() {
    return {
      uid: common.uniqueId(),
      data: Vue.util.extend({}, this.struct)
    };
  },
  methods: {
    isValid() {
      return !this.$v.$invalid;
    },
    validateAll() {
      this.$v.$touch();
    },
    chooseFile(inputId) {
      document.getElementById(inputId).click();
    },
    changeIcon(event) {
      this.data.icon = event.target.files[0];
    },
    displayIconName(icon) {
      let name = icon.name ? icon.name : icon;

      return _.truncate(name, {
        length: 15
      });
    }
  },
  watch: {
    data: {
      handler: function (val) {
        this.$emit("update", val);
      },
      deep: true
    }
  }
};
</script>
