import gql from "graphql-tag";

const updateAdsAdvertisement = gql`
  mutation updateAdsAdvertisement(
    $updateAdsAdvertisement: UpdateAdsAdvertisementHtmlEmbedInput!
  ) {
    updateAdsAdvertisementHtmlEmbed(
      updateAdsAdvertisementHtmlEmbed: $updateAdsAdvertisement
    ) {
      id
    }
  }
`;

export default updateAdsAdvertisement;
