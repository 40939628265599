<template>
  <div class="slider__delete" @click="displayAlert"></div>
</template>

<script>
import defaultMixin from "./defaultMixin";

export default {
  name: "Delete",
  mixins: [defaultMixin],
  computed: {
    pages() {
      return this.$store.getters.sliderPages;
    },
    index() {
      return this.$store.getters.pageIndex;
    },
    pageToDelete() {
      return this.pages[this.index];
    }
  },
  methods: {
    displayAlert() {
      let conf = confirm("Do you want to delete this page permanently?");
      if (conf) {
        this.$store.dispatch("deletePage", this.pageToDelete.id);
        this.$store.dispatch("hidePreview");
      }
    }
  }
};
</script>
