import Vue from "vue";
import * as configs from "../config";
import auth from "../utils/auth";
import * as _ from "lodash";

export const api = {
  fetch(url, params, callback) {
    auth.getToken().then((token) => {
      Vue.http
        .get(configs.RESOURCES_URL + url, {
          params: params,
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((response) => {
          callback(response.body);
        }, this.error);
    });
  },
  create(url, params, callback) {
    auth.getToken().then((token) => {
      Vue.http
        .post(configs.RESOURCES_URL + url, params, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((response) => {
          callback(response.body);
          return response;
        }, this.error);
    });
  },
  delete(url, params, callback) {
    auth.getToken().then((token) => {
      Vue.http
        .delete(configs.RESOURCES_URL + url, {
          params: params,
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((response) => {
          callback(response.ok);
        }, this.error);
    });
  },
  update(url, params, callback) {
    auth.getToken().then((token) => {
      Vue.http
        .put(configs.RESOURCES_URL + url, params, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((response) => {
          callback(response.body);
        }, this.error);
    });
  },
  patch(url, params, callback) {
    auth.getToken().then((token) => {
      Vue.http
        .patch(configs.RESOURCES_URL + url, params, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((response) => {
          callback(response.body);
        }, this.error);
    });
  },
  error(response) {
    let msg = `Problem with server: ${response.status} (${response.statusText})\n`;

    //TODO Need refactor fast as possible :)
    if (response.body.code == "401") {
      return (window.location.href = "#/auth/login");
    }

    if (response.body.errors) {
      if (_.isString(response.body.errors)) {
        msg += response.body.errors;
      } else {
        _.mapKeys(response.body.errors, (value, key) => {
          msg += _.capitalize(key) + " " + _.toString(value);
        });
      }
    } else if (response.body.code) {
      msg += response.body.code + " " + response.body.description;
    }

    alert(msg);
  },

  //COMMON

  uploadPdf(
    { file, quality, cropToTrimbox },
    callback,
    progressCallback,
    errorCallback
  ) {
    var data = new FormData();
    data.append("quality", quality);
    data.append("crop_to_trimbox", cropToTrimbox);

    auth.getToken().then((token) => {
      Vue.http
        .post(configs.RESOURCES_URL + "/pdfs", data, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(
          (response) => {
            callback(response.body);

            fetch(response.body.upload_url, {
              method: "PUT",
              headers: {
                "content-type": "application/pdf"
              },
              body: file
            }).then(
              (response) => {
                // console.log("response", response);
              },
              (error) => {
                errorCallback(error.body);
              }
            );
          },
          (error) => {
            errorCallback(error.body);
          }
        );
    });
  },
  createBrochure(params, callback) {
    this.create("/brochures", params, (data) => {
      callback(data.brochure);
    });
  },
  updateBrochure(id, params, callback) {
    this.patch("/brochures/" + id, params, (data) => {
      callback(data.brochure);
    });
  },
  updateBrochureState(id, params, callback) {
    this.patch("/brochures/" + id + "/state_update", params, (data) => {
      callback(data.brochure);
    });
  },
  getBrochures(params, callback) {
    this.fetch(`/brochures`, params, (data) => {
      callback(data.brochures);
    });
  },
  getBrochure(id, callback) {
    this.fetch("/brochures/" + id, {}, (data) => {
      callback(data.brochure);
    });
  },
  getBrochureCounters(params, callback) {
    this.fetch("/brochures/counters", params, (data) => {
      callback(data);
    });
  },
  getBrochureUtmCounter(params, callback) {
    this.fetch(`/brochures/utm_counter`, params, (data) => {
      callback(data);
    });
  },
  getRegions(callback) {
    this.fetch("/regions", {}, (data) => {
      callback(data.regions);
    });
  },
  getLanguages(callback) {
    this.fetch("/languages", {}, (data) => {
      callback(data.languages);
    });
  },
  getCategories(callback) {
    this.fetch("/categories", {}, (data) => {
      callback(data.categories);
    });
  },
  createCategory(params, callback) {
    this.create("/categories", params, (data) => {
      callback(data.category);
    });
  },
  updateCategory(id, params, callback) {
    this.update("/categories/" + id, params, (data) => {
      callback(data.category);
    });
  },
  deleteCategory(id, callback) {
    this.delete("/categories/" + id, {}, callback);
  },
  getParserClasses(callback) {
    this.fetch("/parser_classes", {}, (data) => {
      callback(data.parser_classes);
    });
  },
  getParsers(id, callback) {
    this.fetch("/parsers?parser_class_id=" + id, {}, (data) => {
      callback(data.parsers);
    });
  },
  getParserIterations(id, callback) {
    this.fetch("/parsers/" + id + "/iterations", {}, (data) => {
      callback(data.parser_iterations);
    });
  },
  getParserIteration(parserId, iterationId, callback) {
    this.fetch(
      "/parsers/" + parserId + "/iterations/" + iterationId,
      {},
      (data) => {
        callback(data.parser_iteration);
      }
    );
  },
  createParser(params, callback) {
    this.create("/parsers", params, (data) => {
      callback(data.parser);
    });
  },
  fetchLastCreatedParserIterations(id, callback) {
    this.fetch("/parsers/" + id + "/past_messages/", {}, (data) => {
      callback(data.messages);
    });
  },
  getLocations(params, callback) {
    this.fetch("/locations", params, (data) => {
      callback(data);
    });
  },
  getLocationsRegions(id, callback) {
    this.fetch("/regions", id, (data) => {
      callback(data.regions);
    });
  },
  deleteLocation(id, callback) {
    this.delete("/locations/" + id, {}, callback);
  },
  deleteAllLocations(params, callback) {
    this.delete(`/shops/${params.shop_id}/delete_locations`, params, callback);
  },
  createLocation(params, callback) {
    this.create("/locations", params, (data) => {
      callback(data.location);
    });
  },
  importLocation(params, callback) {
    return this.create("/locations/import", params, (data) => {
      callback(data);
    });
  },
  importLocationFromCsv(params, callback) {
    return this.create("/locations/import_csv", params, (data) => {
      callback(data.locations);
    });
  },
  getShops(params, callback) {
    this.fetch("/shops", params, (data) => {
      callback(data);
    });
  },
  getShop(id, callback) {
    this.fetch("/shops/" + id, {}, (data) => {
      callback(data.shop);
    });
  },
  getShopBrochures(id, callback) {
    this.fetch("/shops/" + id, {}, (data) => {
      callback(data.brochures);
    });
  },
  updateShop(id, params, callback) {
    this.patch("/shops/" + id, params, (data) => {
      callback(data.shop);
    });
  },
  deleteShop(id, callback) {
    this.delete("/shops/" + id, {}, callback);
  },
  createShop(params, callback) {
    this.create("/shops", params, (data) => {
      callback(data.shop);
    });
  },
  getShopOptions(callback) {
    this.fetch("/shops/shop_options", {}, (data) => {
      callback(data);
    });
  },
  getAllStats(params, callback) {
    this.fetch(`/shops/all_stats`, params, (data) => {
      callback(data);
    });
  },
  getStats(type, id, params, callback) {
    this.fetch(`/${type}/${id}/statistics`, params, (data) => {
      callback(data);
    });
  },
  getOffers(params, callback) {
    this.fetch(`/brochures/offers`, params, (data) => {
      callback(data);
    });
  },
  updateOfferPosition(id, params, callback) {
    this.patch(`/brochures/${id}/update_position`, params, (data) => {
      callback();
    });
  },
  updateOfferFreeze(id, params, callback) {
    this.patch(`/brochures/${id}/freeze_position`, params, (data) => {
      callback();
    });
  },
  deletePage(id, callback) {
    this.delete(`/pages/${id}`, {}, (data) => {
      callback(data.notification);
    });
  },
  splitPages(params, callback) {
    this.patch(`/pages/split`, params, (data) => {
      callback(data);
    });
  },
  mergeBrochures(params, callback) {
    this.patch("/brochures/merge", params, (data) => {
      callback(data);
    });
  },
  getAvailableRegions(params, callback) {
    this.fetch("/brochures/merge/avaliable_regions", params, (data) => {
      callback(data);
    });
  },
  getLocationsFile(shop_id, callback) {
    this.fetch(`/shops/${shop_id}/download_locations`, {}, (data) => {
      callback(data);
    });
  }
};
