<template>
  <div class="new-ad-form">
    <form v-on:submit.prevent="submitNewAd">
      <div class="form-field">
        <label for="roles">Client *</label>
        <div>
          <Multiselect
            v-model="$v.form.customerClient.$model"
            :options="clientsOptions"
            :class="{ 'input-error': $v.form.customerClient.$error }"
            label="name"
            track-by="id"
          >
          </Multiselect>
          <div class="error" v-if="$v.form.customerClient.$error">
            Client is required.
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field">
          <label for="adPlatformType">Platfom type *</label>
          <div>
            <Multiselect
              v-model="$v.form.platformType.$model"
              :options="adPlatformTypeOptions"
              :class="{ 'input-error': $v.form.platformType.$error }"
              label="label"
              track-by="value"
            >
            </Multiselect>
            <div class="error" v-if="$v.form.platformType.$error">
              Platform type is required.
            </div>
          </div>
        </div>
      </div>
      <div class="form-field">
        <label for="displayAt">Ad display position * </label>
        <div>
          <Multiselect
            v-model="$v.form.displayAt.$model"
            :options="adDisplayAtOptions"
            :class="{ 'input-error': $v.form.displayAt.$error }"
            label="label"
            track-by="value"
          >
          </Multiselect>
          <label for="displayAt"
            >(note: platform type has to be given first to display the options
            for display position)</label
          >
          <div class="error" v-if="$v.form.displayAt.$error">
            Ad display position is required.
          </div>
        </div>
      </div>
      <div class="ad-upload">
        <label for="add-upload">Ad upload</label>
        <FileUpload :resolution="adResolution" @file="setFile"></FileUpload>
      </div>

      <div class="dates">
        <div class="form-field">
          <label for="displayStartAt">Display start at *</label>
          <div>
            <SharedDatepicker
              v-model="$v.form.displayStartAt.$model"
              input-class="form-control form-control-sm"
              :class="{ 'input-error': $v.form.displayStartAt.$error }"
            >
              <div slot="icon">
                <div
                  class="custom-icon calendar-icon form-control-feedback"
                ></div>
              </div>
            </SharedDatepicker>
            <div class="error" v-if="$v.form.displayStartAt.$error">
              Display start at is required.
            </div>
          </div>
        </div>
        <div class="form-field">
          <label>Display stop at *</label>
          <div>
            <SharedDatepicker
              v-model="$v.form.displayStopAt.$model"
              input-class="form-control form-control-sm"
              :class="{ 'input-error': $v.form.displayStopAt.$error }"
            >
              <div slot="icon">
                <div
                  class="custom-icon calendar-icon form-control-feedback"
                ></div>
              </div>
            </SharedDatepicker>
            <div class="error" v-if="$v.form.displayStopAt.$error">
              Display stop at is required.
            </div>
          </div>
        </div>
      </div>
      <div class="form-field">
        <label for="status">Status *</label>
        <Multiselect
          v-model="$v.form.status.$model"
          :options="statusOptions"
          :class="{ 'input-error': $v.form.status.$error }"
          label="label"
          track-by="value"
        >
        </Multiselect>
      </div>
      <div class="form-field">
        <label for="displayUrl">Display url *</label>
        <input
          v-model="$v.form.displayUrl.$model"
          class="text-input"
          :class="{ 'input-error': $v.form.displayUrl.$error }"
          id="displayUrl"
          name="displayUrl"
          type="text"
          placeholder="Add url here"
        />
        <div class="error" v-if="$v.form.displayUrl.$error">
          Display url is required.
        </div>
      </div>
      <div class="impressions">
        <div class="form-field full-width">
          <label for="impressionsLimit">Impressions limit *</label>
          <input
            v-model="$v.form.impressionsLimit.$model"
            class="text-input"
            id="impressionsLimit"
            :class="{ 'input-error': $v.form.impressionsLimit.$error }"
            name="impressionsLimit"
            type="number"
            min="0"
          />
          <div class="error" v-if="$v.form.impressionsLimit.$error">
            Impressions limit is required.
          </div>
        </div>
      </div>
      <div class="form-field">
        <label for="languageSelect">Select a language *</label>
        <Multiselect
          v-model="$v.form.language.$model"
          :class="{ 'input-error': $v.form.language.$error }"
          :options="languages"
          placeholder="Select language"
          label="name"
          track-by="id"
        >
        </Multiselect>
        <div class="error" v-if="$v.form.language.$error">
          A language is required.
        </div>
      </div>
      <div class="form-field">
        <label>Shops</label>
        <div>
          <Multiselect
            v-model="$v.form.shops.$model"
            :options="shops"
            placeholder="Select shop"
            label="name"
            track-by="name"
            :multiple="true"
            :close-on-select="false"
          >
          </Multiselect>
        </div>
      </div>
      <div class="form-field">
        <label>Categories</label>
        <div>
          <Multiselect
            v-model="$v.form.categories.$model"
            :options="categories"
            placeholder="Select category"
            label="name"
            track-by="name"
            :multiple="true"
            :close-on-select="false"
          >
          </Multiselect>
        </div>
      </div>
      <div class="form-field">
        <label for="weight">Weight *</label>
        <input
          v-model="$v.form.weight.$model"
          class="text-input"
          :class="{ 'input-error': $v.form.weight.$error }"
          s
          id="weight"
          name="weight"
          type="number"
          min="0"
        />
        <div class="error" v-if="$v.form.weight.$error">
          Weight is required.
        </div>
      </div>
      <input class="jaf-submit" type="submit" value="Create new ad" />
    </form>
  </div>
</template>

<script>
import CreateAdsAdvertisementImageAdMutation from "../../../graphql/mutations/CreateAdsAdvertisementImageAd";
import { required, minLength } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import CustomerClientsQuery from "../../../graphql/queries/CustomerClients";
import SharedDatepicker from "../../shared/datepicker";
import FileUpload from "../../molecules/FileUpload.vue";

export default {
  name: "new-ad-form",
  components: {
    Multiselect,
    SharedDatepicker,
    FileUpload
  },
  mounted() {
    this.$v.$reset();
  },
  data() {
    return {
      form: {
        customerClient: null,
        displayAt: null,
        displayStartAt: null,
        displayStopAt: null,
        displayUrl: null,
        impressionsLimit: null,
        language: null,
        platformType: null,
        shops: null,
        categories: null,
        status: null,
        weight: null,
        file: null
      }
    };
  },
  apollo: {
    customerClients: {
      query: CustomerClientsQuery,
      deep: true,
      variables() {
        return {
          customerClients: {
            orderBy: "NAME_AT_ASC"
          },
          pagination: {
            page: 1,
            pageSize: 1000
          }
        };
      }
    }
  },
  computed: {
    formattedVariables() {
      const createAdsAdvertisement = {
        customerClientId: this.formValue(this.form.customerClient, "id"),
        displayAt: this.formValue(this.form.displayAt, "value"),
        displayStartAt: new Date(this.form.displayStartAt).toISOString(),
        displayStopAt: new Date(this.form.displayStopAt).toISOString(),
        displayUrl: this.form.displayUrl,
        impressionsLimit: Number(this.form.impressionsLimit),
        languageId: this.formValue(this.form.language, "id"),
        platformType: this.formValue(this.form.platformType, "value"),
        shopIds: this.form.shops ? this.form.shops.map((shop) => shop.id) : [],
        categoryIds: this.form.categories
          ? this.form.categories.map((category) => category.id)
          : [],
        status: this.formValue(this.form.status, "value"),
        weight: Number(this.form.weight),
        file: this.form.file
      };

      return { createAdsAdvertisement };
    },
    languages() {
      return this.$store.getters.allLanguages;
    },
    shops() {
      return this.$store.getters.allShops;
    },
    categories() {
      return this.$store.getters.allCategories;
    },
    adPlatformTypeOptions() {
      return [
        { label: "Android", value: "ANDROID" },
        { label: "iOS", value: "IOS" },
        { label: "Web", value: "WEB" }
      ];
    },
    statusOptions() {
      return [
        { label: "Enabled", value: "ENABLED" },
        { label: "Disabled", value: "DISABLED" }
      ];
    },
    adDisplayAtOptions() {
      const platformType = this.formValue(this.form.platformType, "value");

      if (!platformType) {
        return [];
      } else if (platformType === "WEB") {
        return [
          {
            label: "Brochure viewer interface",
            value: "BROCHURE_VIEWER_INTERFACE"
          },
          { label: "Homepage bottom", value: "HOMEPAGE_BOTTOM" },
          { label: "Homepage top", value: "HOMEPAGE_TOP" },
          { label: "Brochure start", value: "BROCHURE_START" },
          { label: "Brochure start (bottom)", value: "BROCHURE_START_BOTTOM" },
          { label: "Brochure end (left)", value: "BROCHURE_END_LEFT" },
          { label: "Brochure end (right)", value: "BROCHURE_END_RIGHT" },
          { label: "Blog detail top", value: "BLOG_DETAIL_TOP" },
          { label: "Blog detail bottom", value: "BLOG_DETAIL_BOTTOM" },
          { label: "New brochures", value: "NEW_BROCHURES" },
          { label: "Brand", value: "BRAND" },
          { label: "Shop", value: "SHOP" }
        ];
      } else {
        return [
          { label: "Brochure above", value: "BROCHURE_ABOVE" },
          {
            label: "Brochure end above google maps",
            value: "BROCHURE_END_ABOVE_GOOGLE_MAPS"
          },
          {
            label: "Brochure end interesting",
            value: "BROCHURE_END_INTERESTING"
          },
          { label: "Category", value: "CATEGORY" },
          { label: "Categories", value: "CATEGORIES" },
          { label: "Favorites end", value: "FAVOURITES_END" },
          { label: "New brochures", value: "NEW_BROCHURES" },
          { label: "New brochures bottom", value: "NEW_BROCHURES_BOTTOM" }
        ];
      }
    },
    adDisplaySize() {
      const displayAt = this.formValue(this.form.displayAt, "value");
      const displayAtToSize = {
        BROCHURE_ABOVE: "BANNER",
        BROCHURE_END_ABOVE_GOOGLE_MAPS: "MEDIUM_RECTANGLE",
        BROCHURE_END_INTERESTING: "MEDIUM_RECTANGLE",
        BROCHURE_VIEWER_INTERFACE: "UNKNOWN",
        CATEGORY: "LARGE_BANNER",
        FAVOURITES_END: "LARGE_BANNER",
        HOMEPAGE_BOTTOM: "UNKNOWN",
        HOMEPAGE_TOP: "UNKNOWN",
        NEW_BROCHURES: "LARGE_BANNER",
        NEW_BROCHURES_BOTTOM: "LARGE_BANNER",
        CATEGORIES: "LARGE_BANNER"
      };
      return displayAtToSize[displayAt];
    },
    adResolution() {
      const size = this.adDisplaySize;
      const sizeToResolution = {
        LARGE_BANNER: "640x200",
        BANNER: "640x50",
        MEDIUM_RECTANGLE: "600x500",
        UNKNOWN: null
      };
      return sizeToResolution[size];
    },
    clientsOptions() {
      if (this.customerClients && this.customerClients.entries.length > 0) {
        const { entries } = this.customerClients;
        return entries.map(({ id, name }) => ({ id, name }));
      }
      return [];
    }
  },
  validations: {
    form: {
      customerClient: { required },
      displayAt: { required },
      displayStartAt: { required },
      displayStopAt: { required },
      displayUrl: { required },
      impressionsLimit: { required },
      language: { required },
      platformType: { required },
      shops: {},
      categories: {},
      status: { required },
      weight: { required },
      file: { required }
    }
  },

  methods: {
    formValue(data, attribute) {
      if (data) return data[attribute];
    },
    submitNewAd() {
      const validated = this.validateFormInputs();
      if (validated === "OK") this.createAdsAdvertisement();
      if (validated === "ERROR") this.handleError();
    },
    handleError() {
      // console.log("error", this.$v.$error);
    },
    validateFormInputs() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return "ERROR";
      else return "OK";
    },
    createAdsAdvertisement() {
      const variables = this.formattedVariables;
      try {
        this.$apollo
          .mutate({
            mutation: CreateAdsAdvertisementImageAdMutation,
            variables,
            context: {
              hasUpload: true
            }
          })
          .then(() => this.$router.push("/ads"));
      } catch (e) {
        // console.error("error", e);
      }
    },
    setFile(file) {
      this.form.file = file;
    }
  }
};
</script>

<style scoped>
.new-ad-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.new-ad-form input {
  display: block;
  width: 50%;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);

  padding: 0.28rem 0.5rem;
  font-size: 0.8rem;
  border-radius: 0.2rem;
}
.new-ad-form .helper {
  font-size: 0.6rem;
  color: red;
}
.new-ad-form .dates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.new-ad-form .impressions {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  gap: 1rem;
}
@media only screen and (min-width: 1024px) {
  .new-ad-form .impressions {
    width: 100%;
  }
}
.jaf-submit {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  border-bottom-color: rgb(51, 51, 51);
  border-radius: 4px;
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue",
    Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.75rem 0.7rem;
  text-align: left;
  /* border: none; */
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 18%);
  width: max-content !important;
  transition: box-shadow 200ms, background-color 200ms;
}
.jaf-submit:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}
.jaf-submit:active:hover {
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 18%);
}
.full-width input {
  width: 100%;
}
.error {
  color: red;
  font-size: 0.75rem;
}
.form-field .input-error {
  border: 1px solid red;
}
.form-field.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
