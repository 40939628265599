import Vue from "vue";
import VueRouter from "vue-router";
import Admins from "./components/admins";
import AdminDetails from "./components/admins/details";
import Login from "./components/auth/login.vue";
import brochures from "./components/brochures";
import categories from "./components/categories";
import Config from "./components/config/index.vue";
import locations from "./components/locations";
import Offers from "./components/offers/index.vue";
import ParserClasses from "./components/parsers/sections/parser-classes.vue";
import Parser from "./components/parsers/sections/parser.vue";
import Parsers from "./components/parsers/sections/parsers.vue";
import shops from "./components/shops";
import IdentityUsers from "./components/users";
import IdentityUsersEdit from "./components/users/edit";
import IdentityUsersNew from "./components/users/new";
import Ads from "./components/ads";
import AdsEdit from "./components/ads/edit";
import AdsEditHtmlEmbed from "./components/ads/edit-html-embed";
import AdsNew from "./components/ads/new";
import AdsNewHtmlEmbed from "./components/ads/new-html-embed";
import AuthLayout from "./layouts/auth.vue";
import ContentLayout from "./layouts/content.vue";
import auth from "./utils/auth";
import isMainDomain from "./mixins/isMainDomain";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "/",
      redirect: "/brochures",
      component: ContentLayout,
      meta: { requiresAuth: true },
      children: [
        { path: "/brochures", component: brochures },
        { path: "/shops", component: shops },
        { path: "/shops/:shop_id/locations", component: locations },
        { path: "/categories", component: categories },
        { path: "/parsers", component: ParserClasses },
        { path: "/parsers/:class", component: Parsers },
        { path: "/parsers/:class/:id", component: Parser },
        { path: "/offers", component: Offers },
        { path: "/config", component: Config },
        { path: "/admins", component: Admins },
        { path: "/admins/:id", component: AdminDetails },
        { path: "/users", component: IdentityUsers },
        { path: "/users/new/", component: IdentityUsersNew },
        { path: "/users/edit/", component: IdentityUsersEdit },
        { path: "/ads", component: Ads },
        { path: "/ads/new/", component: AdsNew },
        { path: "/ads/new-html-embed/", component: AdsNewHtmlEmbed },
        { path: "/ads/edit/", component: AdsEdit },
        { path: "/ads/edit-html-embed/", component: AdsEditHtmlEmbed }
      ]
    },
    {
      path: "/auth",
      component: AuthLayout,
      children: [{ path: "login", component: Login }]
    }
  ]
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const unauthorizedNext = {
    path: "/auth/login",
    query: { redirect: to.fullPath }
  };

  auth.loggedIn().then((res) => {
    if (res && requiresAuth) next();
    else if (!res && requiresAuth) next(unauthorizedNext);
    else if (!requiresAuth) next();
    else next(unauthorizedNext);
  });
});

router.afterEach((to, from) => {
  if (!isMainDomain.methods.isMainDomain()) {
    document.getElementById("favicon").href = "/favicon.ico";
  }
});
export default router;
