<template>
  <PageLayout class="identity-ad-edit">
    <ToolBar to="/ads" title="Update image ad" />
    <PageContentContainer>
      <EditForm
        :ad="adsAdvertisement"
        v-if="adsAdvertisement"
        :shops="shops"
        :categories="categories"
        :clientsOptions="clientsOptions"
        :languages="languages"
      />
    </PageContentContainer>
  </PageLayout>
</template>

<script>
import EditForm from "./edit-ad-form.vue";
import AdsAdvertisementQuery from "../../../graphql/queries/AdsAdvertisement";
import ToolBar from "../../layout/tool-bar.vue";
import PageLayout from "../../layout/page-layout.vue";
import PageContentContainer from "../../layout/page-content-container.vue";
import CustomerClientsQuery from "../../../graphql/queries/CustomerClients";

export default {
  name: "ad-edit",
  components: {
    EditForm,
    ToolBar,
    PageLayout,
    PageContentContainer
  },
  mounted() {
    this.$store.dispatch("getShopOptions");
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    shops() {
      return this.$store.getters.allShops;
    },
    categories() {
      return this.$store.getters.allCategories;
    },
    languages() {
      return this.$store.getters.allLanguages;
    },
    clientsOptions() {
      if (this.customerClients && this.customerClients.entries.length > 0) {
        const { entries } = this.customerClients;
        return entries.map(({ id, name }) => ({ id, name }));
      }
      return null;
    },
    showForm() {
      const shops = this.$store.getters.allShops.length > 0;
      const categories = this.$store.getters.allCategories.length > 0;
      const ad = this.adsAdvertisement;
      const languages = this.languages.length > 0;
      const clients = this.clientsOptions && this.clientsOptions.length > 0;
      return shops && categories && ad && languages && clients;
    }
  },
  apollo: {
    customerClients: {
      query: CustomerClientsQuery,
      deep: true,
      variables() {
        return {
          customerClients: {
            orderBy: "NAME_AT_ASC"
          },
          pagination: {
            page: 1,
            pageSize: 1000
          }
        };
      }
    },
    adsAdvertisement: {
      query: AdsAdvertisementQuery,
      variables() {
        return {
          adsAdvertisement: { adsAdvertisementId: this.id }
        };
      },
      skip() {
        return !this || !this.id;
      }
    }
  }
};
</script>
