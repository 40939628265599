<template>
  <div class="admins-details content">
    <EditForm v-if="admin" :admin="admin" />
  </div>
</template>

<script>
import EditForm from "./edit.vue";

export default {
  name: "admin-details",
  components: { EditForm },
  data() {
    return {
      id: this.$route.params.id
    };
  },
  created() {
    if (this.id) this.$store.dispatch("fetchAdmin", this.id);
  },
  destroyed() {
    this.$store.dispatch("clearAdmin");
  },
  computed: {
    admin() {
      return this.$store.getters.currentAdmin;
    }
  },

  methods: {}
};
</script>
