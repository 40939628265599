<template>
  <div class="edit-user-form">
    <form v-on:submit.prevent="submit">
      <div class="form-field">
        <label for="name">Email</label>
        <input
          class="text-input"
          id="email"
          name="email"
          type="email"
          @input="inputEmail"
          :value="email"
          placeholder="Type email of user here"
        />
        <span class="helper" v-if="helper.email">{{ helper.email }}</span>
      </div>
      <div class="form-field">
        <label for="firstName">First name</label>
        <input
          class="text-input"
          id="firstName"
          name="firstName"
          type="text"
          @input="inputFirstName"
          :value="firstName"
          placeholder="Type first name of user here"
        />
        <span class="helper" v-if="helper.firstName">{{
          helper.firstName
        }}</span>
      </div>
      <div class="form-field">
        <label for="lastName">Last name</label>
        <input
          class="text-input"
          id="lastName"
          name="lastName"
          type="text"
          @input="inputLastName"
          :value="lastName"
          placeholder="Type last name of user here"
        />
        <span class="helper" v-if="helper.lastName">{{ helper.lastName }}</span>
      </div>
      <div class="form-field">
        <label for="roles">Roles</label>
        <div>
          <label class="typo__label">Select role(s)</label>
          <multiselect
            v-model="selectedRoles"
            :options="adminRolesOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick role(s)"
            label="label"
            track-by="value"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span class="multiselect__single" v-if="values.length && !isOpen"
                >{{ selectedRolesIds.length }} selected
              </span></template
            >
          </multiselect>
        </div>
      </div>
      <div class="form-field">
        <label for="clientsMultiselect">Clients</label>
        <div>
          <label class="typo__label">Select client(s)</label>
          <multiselect
            v-model="selectedCustomerClients"
            :options="clientsOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick client(s)"
            label="label"
            track-by="value"
            id="clientsMultiselect"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span class="multiselect__single" v-if="values.length && !isOpen"
                >{{ selectedCustomerClients.length }} selected</span
              ></template
            >
          </multiselect>
        </div>
      </div>
      <input class="jaf-submit" type="submit" value="Update user" />
    </form>
    <button class="delete" @click.prevent="deleteIdentityUser">
      Delete user
    </button>
  </div>
</template>

<script>
import UpdateIdentityUserMutation from "../../../graphql/mutations/UpdateIdentityUser";
import DeleteIdentityUserMutation from "../../../graphql/mutations/DeleteIdentityUser";
import { adminRoles } from "../../../constants";
import CustomerClientsQuery from "../../../graphql/queries/CustomerClients";

export default {
  name: "edit-user-form",
  props: ["user"],
  mounted() {
    const { id, email, firstName, lastName } = this.user;
    this.id = id;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.selectedRoles = this.getRoles();
    this.selectedCustomerClients = this.getClients();
  },
  data() {
    return {
      id: null,
      email: "",
      firstName: "",
      lastName: "",
      selectedRoles: [],
      selectedCustomerClients: [],
      helper: { email: null, firstName: null, lastName: null }
    };
  },
  watch: {
    email: function (email) {
      if (email && email.length > 0) this.helper.email = null;
    },
    firstName: function (firstName) {
      if (firstName && firstName.length > 0) this.helper.firstName = null;
    },
    lastName: function (lastName) {
      if (lastName && lastName.length > 0) this.helper.lastName = null;
    }
  },
  apollo: {
    customerClients: {
      query: CustomerClientsQuery,
      deep: true,
      variables() {
        return {
          customerClients: {
            orderBy: "NAME_AT_ASC"
          },
          pagination: {
            page: 1,
            pageSize: 1000
          }
        };
      }
    }
  },
  computed: {
    clientsOptions() {
      if (this.customerClients) {
        const { entries } = this.customerClients;
        if (!entries || !entries.length) return [];
        const options = entries.map((entry) => ({
          value: entry.id,
          label: entry.name
        }));
        return options;
      }
      return [];
    },
    adminRolesOptions() {
      const roles = Object.entries(adminRoles);
      const options = roles.map((role) => ({ value: role[1], label: role[0] }));
      return options;
    },
    selectedRolesIds() {
      if (!this.selectedRoles || !this.selectedRoles.length) return [];
      return this.selectedRoles.map(({ value }) => value.toUpperCase());
    }
  },
  methods: {
    getRoles() {
      const { roles } = this.user;
      if (!roles || !roles.length) return [];
      const compareValue = ({ value }) => roles.includes(value.toUpperCase());
      return this.adminRolesOptions.filter(compareValue);
    },
    getClients() {
      const { customerClients } = this.user;
      const filtered = customerClients.map((client) => ({
        value: client.id,
        label: client.name
      }));
      return filtered;
    },
    inputFirstName({ currentTarget }) {
      this.firstName = currentTarget.value;
    },
    inputLastName({ currentTarget }) {
      this.lastName = currentTarget.value;
    },
    inputEmail({ currentTarget }) {
      this.email = currentTarget.value;
    },
    submit() {
      const {
        email,
        firstName,
        lastName,
        selectedRolesIds,
        selectedCustomerClients
      } = this;

      const customerClientIds = selectedCustomerClients.map(
        (client) => client.value
      );

      if (email === "") this.helper.email = "Please add email";
      if (firstName === "") this.helper.firstName = "Please add firstName";
      if (lastName === "") this.helper.lastName = "Please add lastName";
      else if (
        email &&
        email.length > 0 &&
        firstName &&
        firstName.length > 0 &&
        lastName &&
        lastName.length > 0
      )
        this.updateIdentityUser({
          email,
          firstName,
          lastName,
          selectedRolesIds,
          customerClientIds
        });
    },
    updateIdentityUser({
      email,
      firstName,
      lastName,
      selectedRolesIds,
      customerClientIds
    }) {
      const variables = {
        updateIdentityUser: {
          id: this.user.id,
          email,
          firstName,
          lastName,
          roles: selectedRolesIds,
          customerClientIds
        }
      };
      this.$apollo
        .mutate({
          mutation: UpdateIdentityUserMutation,
          variables
        })
        .then(() => this.$router.push("/users"));
    },
    deleteIdentityUser() {
      const confirm = window.confirm(
        `Are you sure you want to delete: ${this.user.firstName} ${this.user.lastName}`
      );
      if (confirm) {
        const { id } = this.user;
        this.$apollo
          .mutate({
            mutation: DeleteIdentityUserMutation,
            variables: { deleteIdentityUser: { id } }
          })
          .then(() => this.$router.push("/users"));
      }
    }
  }
};
</script>

<style scoped>
.edit-user-form {
  position: relative;
}
.edit-user-form .delete {
  cursor: pointer;
  background-color: transparent;
  border-bottom-color: transparent;
  border-radius: 4px;
  color: red;
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue",
    Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.75rem 0.7rem;
  text-align: left;
  border: none;
  width: max-content;
  margin-top: 1rem;

  position: absolute;
  bottom: 0;
  right: 0;
}
.edit-user-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.edit-user-form .text-input {
  display: block;
  width: 50%;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);

  padding: 0.28rem 0.5rem;
  font-size: 0.8rem;
  border-radius: 0.2rem;
}
.edit-user-form .helper {
  font-size: 0.6rem;
  color: red;
}
.jaf-submit {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  border-bottom-color: rgb(51, 51, 51);
  border-radius: 4px;
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue",
    Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.75rem 0.7rem;
  text-align: left;
  border: none;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 18%);
  width: max-content;
}
.jaf-submit:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}
</style>
