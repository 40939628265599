<template>
  <div>
    <form class="form-full-width" @submit.prevent="save">
      <div class="form-group">
        <label class="control-label" :for="language">Description</label>
        <textarea
          class="form-control"
          rows="10"
          columns="120"
          ref="value"
          :value="description"
          :id="language"
        />
      </div>
      <button type="submit" class="btn btn-primary">Save</button>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    language: String,
    description: String
  },

  methods: {
    save() {
      this.$store.dispatch("config/save", {
        language: this.language,
        description: this.$refs.value.value
      });
    }
  }
};
</script>
