import { api } from "../api";

import * as types from "../mutation-types";

const state = {
  all: []
};

const getters = {
  allRegions: (state) => state.all,
  regionsSelect: (state) => {
    return state.all.map((el) => {
      return {
        text: el.name,
        value: el.id
      };
    });
  }
};

const actions = {
  getAllRegions({ commit }) {
    api.getRegions((regions) => {
      commit(types.RECEIVE_REGIONS, { regions });
    });
  }
};

const mutations = {
  [types.RECEIVE_REGIONS](state, { regions }) {
    state.all = regions;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
