import { api } from "../api";

import * as types from "../mutation-types";

const state = {
  active: false,
  zoom: 0,
  zoomMax: 4,
  pages: [],
  index: 0,
  sliderRegionId: undefined
};

const getters = {
  sliderActive: (state) => state.active,
  sliderPages: (state) => state.pages,
  zoom: (state) => state.zoom,
  zoomMax: (state) => state.zoomMax,
  pagesLength: (state) => state.pages.length,
  pageIndex: (state) => state.index,
  sliderRegionId: (state) => state.sliderRegionId
};

const actions = {
  hidePreview({ commit }) {
    commit(types.HIDE_BROCHURE_PAGES);
  },
  deletePage(state, page_id) {
    api.deletePage(page_id, () => {});
  },
  splitPages(state, params) {
    api.splitPages(params, () => {});
  },
  showPreview({ commit }, params) {
    api.getBrochure(params.brochureId, (brochure) => {
      let pages = actions.findPages(brochure, params);
      commit("sliderRegionId", params.regionId);
      commit(types.RECEIVE_BROCHURE_PAGES, { pages });
    });
  },
  findPages(brochure, params) {
    let pages = [];

    brochure.regions.find((region) => {
      if (region.id == params.regionId) {
        pages = region.pages;
      }
    });

    return pages;
  },
  defaultZoom({ commit }) {
    commit(types.DEFAULT_PAGE_ZOOM);
  },
  incrementZoom({ commit }) {
    if (state.zoom < state.zoomMax) {
      commit(types.INCREMENT_PAGE_ZOOM);
    }
  },
  decrementZoom({ commit }) {
    if (state.zoom > 0) {
      commit(types.DECREMENT_PAGE_ZOOM);
    }
  },
  changePageIndex({ commit }, index) {
    commit(types.CHANGE_PAGE_INDEX, { index });
  },
  incrementPageIndex({ commit }) {
    if (state.index < state.pages.length - 1) {
      commit(types.INCREMENT_PAGE_INDEX);
    }
  },
  decrementPageIndex({ commit }) {
    if (state.index > 0) {
      commit(types.DECREMENT_PAGE_INDEX);
    }
  },
  setFirstPageIndex({ commit }) {
    let index = 0;
    commit(types.CHANGE_PAGE_INDEX, { index });
  },
  setLastPageIndex({ commit }) {
    let index = state.pages.length - 1;
    commit(types.CHANGE_PAGE_INDEX, { index });
  },
  resetSlider({ commit }) {
    commit(types.RESET_SLIDER);
  }
};

const mutations = {
  sliderRegionId(state, regionId) {
    state.sliderRegionId = regionId;
  },
  [types.RECEIVE_BROCHURE_PAGES](state, { pages }) {
    state.pages = pages;
    state.active = true;
  },
  [types.HIDE_BROCHURE_PAGES](state) {
    state.active = false;
  },
  [types.DEFAULT_PAGE_ZOOM](state) {
    state.zoom = 0;
  },
  [types.INCREMENT_PAGE_ZOOM](state) {
    state.zoom++;
  },
  [types.DECREMENT_PAGE_ZOOM](state) {
    state.zoom--;
  },
  [types.CHANGE_PAGE_INDEX](state, { index }) {
    state.index = index;
  },
  [types.DECREMENT_PAGE_INDEX](state) {
    state.index--;
  },
  [types.INCREMENT_PAGE_INDEX](state) {
    state.index++;
  },
  [types.RESET_SLIDER](state) {
    state.zoom = 0;
    state.index = 0;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
