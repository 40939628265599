<template>
  <div class="users-overview">
    <DataTable>
      <TableHeader :headers="columns" />
      <TableBody
        :columns="columns"
        :data="usersList"
        :rowCallback="onUserClick"
      />
    </DataTable>
    <Pagination
      @update="paginationTo"
      v-if="identityUsers && identityUsers.pagination"
      :pagination="identityUsers.pagination"
    />
  </div>
</template>

<script>
import DataTable from "../dataTable/table.vue";
import TableBody from "../dataTable/table-body.vue";
import TableHeader from "../dataTable/table-header.vue";
import Pagination from "../dataTable/pagination.vue";

import IdentityUsersQuery from "../../graphql/queries/IdentityUsers";

const PAGE_SIZE = 25;

export default {
  name: "users-overview",
  components: {
    DataTable,
    TableHeader,
    TableBody,
    Pagination
  },
  apollo: {
    identityUsers: {
      query: IdentityUsersQuery,
      deep: true,
      variables() {
        return {
          identityUsers: this.varIdentityUsers,
          pagination: this.pagination
        };
      }
    }
  },
  data() {
    return {
      pagination: {
        page: 1,
        pageSize: PAGE_SIZE
      },
      varIdentityUsers: {
        orderBy: "EMAIL_AT_ASC",
        search: ""
      },
      columns: [
        { label: "First name", value: "firstName" },
        { label: "Last name", value: "lastName" },
        { label: "Email", value: "email" },
        { label: "Roles", value: "roles" }
      ]
    };
  },
  methods: {
    paginationTo(page) {
      this.pagination.page = page;
    },
    onUserClick({ data, _event }) {
      if (data && data.id)
        this.$router.push({ path: "/users/edit/", query: { id: data.id } });
    }
  },
  computed: {
    tableColumns() {
      return this.columns.length;
    },
    usersList() {
      if (
        this.identityUsers &&
        this.identityUsers.entries &&
        this.identityUsers.entries.length > 0
      )
        return this.identityUsers.entries.map((entry) => {
          const { roles } = entry;
          if (typeof roles === "object") {
            const joinedRoles = roles.join(", ");
            entry["roles"] = joinedRoles;
          }
          return entry;
        });
      else return [];
    }
  }
};
</script>
