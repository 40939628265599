<template>
  <div class="edit-brochure-modal">
    <modal
      :customerClass="'brochure-modal'"
      :show.sync="show"
      @cancel="onClose"
      :large="true"
    >
      <div slot="header">
        <div class="brochure-picture">
          <img :src="brochureCover" />
          <div
            :style="brochureCoverBackground"
            class="brochure-cover-blur"
          ></div>
        </div>
        <div>
          <p
            v-for="(update, index) in item.brochure_updates"
            :key="index + 'update'"
          >
            {{ fDate(update.inserted_at) }} - {{ update.caption }}
          </p>
        </div>
      </div>
      <img
        v-if="item.language"
        :src="item.language.file_small_url"
        class="title-flag"
      />
      <h3 class="title">{{ data.name ? data.name : item.name }}</h3>
      <div class="row">
        <brochure-form
          ref="form"
          :struct="formStruct"
          v-on:update-data="updateData"
          v-on:update-attachments="updateAttachments"
        />
      </div>
      <div slot="footer">
        <b-button variant="modal" @click="submit()"> save changes </b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import modal from "../shared/modal";
import brochureForm from "./form";
const helper = require("./helper");
import { formatDate } from "../../utils";
import * as _ from "lodash";

export default {
  name: "edit-brochure",
  components: { modal, brochureForm },
  computed: {
    brochureCover() {
      if (_.has(this.item, "cover_photo")) {
        return this.item.cover_photo;
      } else {
        return "";
      }
    },
    brochureCoverBackground() {
      if (this.brochureCover) {
        return `background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.brochureCover})`;
      } else {
        return "";
      }
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    fDate(timestamp) {
      return formatDate(timestamp * 1000, "dd-mm-yy 'at' hh:MM");
    },
    updateData(data) {
      this.data = data;
    },
    updateAttachments(attachments) {
      let current_attachments = this.item.regions.map((x) => {
        return { id: x.id, pages_count: x.pages.length };
      });
      current_attachments.forEach((element) => {
        let matching_attachment = attachments[element.id];
        if (
          matching_attachment &&
          matching_attachment.pages_count != element.pages_count
        ) {
          alert("Pages of this attachment have changed!");
        }
      });
      this.attachments = attachments;
    },
    submit() {
      var form = this.$refs.form;

      form.validateAll();

      if (form.isValid()) {
        var params = helper.deserialize(this.data, this.attachments, this.item);
        this.$store.dispatch("updateBrochure", {
          id: this.item.id,
          params: params
        });
        this.onClose();
      }
    }
  },
  data() {
    return {
      formStruct: helper.serialize(this.item, this),
      data: {}
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object
    }
  }
};
</script>
