export function setEvents(el, context) {
  el.addEventListener("dragenter", function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.classList.add("dragover");
  });
  el.addEventListener("dragleave", function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.classList.remove("dragover");
  });
  el.addEventListener("dragover", function (e) {
    e.preventDefault();
    e.stopPropagation();
  });

  el.addEventListener("drop", function (e) {
    e.preventDefault();
    e.stopPropagation();

    this.classList.remove("dragover");
    if (context.onDrop) context.onDrop(e);
  });
}

export const dragoverMixin = {
  mounted() {
    var el = this.$el;
    var self = this;

    setEvents(el, self);
  }
};
