<template>
  <div :class="zoomStateClass" class="slider__arrowBox">
    <div
      @click="prev"
      :class="stateClassPrev"
      class="slider__arrow -prev"
    ></div>
    <div
      @click="next"
      :class="stateClassNext"
      class="slider__arrow -next"
    ></div>
  </div>
</template>

<script>
import defaultMixin from "./defaultMixin";

export default {
  name: "Arrows",
  mixins: [defaultMixin],
  computed: {
    stateClassPrev() {
      return this.index > 0 ? "" : "-hide";
    },
    stateClassNext() {
      return this.index < this.length - 1 ? "" : "-hide";
    }
  },
  created() {
    window.addEventListener("keydown", this.handleKeyDown, true);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown, true);
  },
  methods: {
    handleKeyDown(e) {
      e = e || window.event;

      switch (e.keyCode) {
        case 37:
          this.prev();
          break;
        case 39:
          this.next();
          break;
      }
    },
    prev() {
      this.$store.dispatch("decrementPageIndex");
    },
    next() {
      this.$store.dispatch("incrementPageIndex");
    }
  }
};
</script>
