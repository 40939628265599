import gql from "graphql-tag";

const createAdsAdvertisement = gql`
  mutation createAdsAdvertisement(
    $createAdsAdvertisement: CreateAdsAdvertisementHtmlEmbedInput!
  ) {
    createAdsAdvertisementHtmlEmbed(
      createAdsAdvertisementHtmlEmbed: $createAdsAdvertisement
    ) {
      id
    }
  }
`;

export default createAdsAdvertisement;
