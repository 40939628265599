import { api } from "../api";
import * as _ from "lodash";

import * as types from "../mutation-types";
import * as configs from "../../config";
import { byStatus } from "../helpers";

const state = {
  sortBy: configs.BROCHURES_DEF_SORT_KEY,
  showStatus: "",
  filterBy: setDefaultFilter(),
  busy: false,
  exhausted: false,
  all: [],
  counters: {},
  utm_counter: 0
};

const getters = {
  allBrochures: (state) => state.all,
  sortBy: (state) => state.sortBy,
  filterBy: (state) => state.filterBy,
  filteredBrochures: (state) => state.all,
  countBrochuresByStauses: (state) => (status) => {
    return byStatus(state.all, status).length;
  },
  brochureCounters: (state) => state.counters,
  utmCounter: (state) => state.utm_counter
};

const actions = {
  getAllBrochures({ commit }) {
    if (state.busy || state.exhausted) return;
    state.busy = true;

    api.getBrochures(state.filterBy, (brochures) => {
      commit(types.PUSH_BROCHURES, { brochures });
    });
  },
  putSortKey({ commit }, sort_by) {
    state.exhausted = false;
    state.filterBy.next_token = 1;
    state.filterBy.order = sort_by.value;

    api.getBrochures(state.filterBy, (brochures) => {
      commit(types.RECEIVE_BROCHURES, { brochures });
    });
  },
  changeFilterBy({ commit }, filter) {
    state.exhausted = false;
    state.filterBy.next_token = 1;
    state.filterBy.language_ids = filter.language_ids;
    state.filterBy.region_ids = filter.region_ids;
    state.filterBy.category_ids = filter.category_ids;
    state.filterBy.shop_ids = filter.shop_ids;
    state.filterBy.top = filter.top;
    state.filterBy.parser_classes = filter.parser_classes;

    api.getBrochures(state.filterBy, (brochures) => {
      commit(types.RECEIVE_BROCHURES, { brochures });
      api.getBrochureCounters(state.filterBy, (response) => {
        commit(types.UPDATE_COUNTERS, { response });
      });
    });
  },
  resetFilter() {
    state.filterBy = setDefaultFilter();
  },
  changeSelectedStatus({ commit }, status) {
    state.exhausted = false;
    state.filterBy.status = status;
    state.filterBy.next_token = 1;
    state.filterBy.order = resolveDefaultOrder(status);

    api.getBrochures(state.filterBy, (brochures) => {
      commit(types.RECEIVE_BROCHURES, { brochures });
      api.getBrochureCounters(state.filterBy, (response) => {
        commit(types.UPDATE_COUNTERS, { response });
      });
    });
  },
  createBrochure({ commit }, params) {
    api.createBrochure(params, (brochure) => {
      commit(types.CREATE_BROCHURE, { brochure });
    });
  },
  updateBrochure({ commit }, { id, params }) {
    api.updateBrochure(id, params, (brochure) => {
      commit(types.UPDATE_BROCHURE, { brochure });
    });
  },
  updateBrochureState({ commit }, { id, params }) {
    api.updateBrochureState(id, params, () => {
      api.getBrochures(state.filterBy, (brochures) => {
        commit(types.RECEIVE_BROCHURES, { brochures });
      });
      api.getBrochureCounters(state.filterBy, (response) => {
        commit(types.UPDATE_COUNTERS, { response });
      });
    });
  },
  getBrochureCounters({ commit }) {
    api.getBrochureCounters(state.filterBy, (response) => {
      commit(types.UPDATE_COUNTERS, { response });
    });
  },
  getBrochureUtmCounter({ commit }, params) {
    api.getBrochureUtmCounter(params, (response) => {
      commit(types.UPDATE_UTM_COUNTER, { response });
    });
  },
  mergeBrochures({ commit }, params) {
    api.mergeBrochures(params, () => {
      api.getBrochures(state.filterBy, (brochures) => {
        commit(types.RECEIVE_BROCHURES, { brochures });
        api.getBrochureCounters(state.filterBy, (response) => {
          commit(types.UPDATE_COUNTERS, { response });
        });
      });
    });
  },
  getAvailableRegions(state, params) {
    return api.getAvailableRegions(params);
  }
};

const mutations = {
  [types.RECEIVE_BROCHURES](state, { brochures }) {
    state.all = brochures;
    state.busy = false;
  },
  [types.PUSH_BROCHURES](state, { brochures }) {
    if (brochures.length < 20) {
      state.exhausted = true;
    }

    state.all = _.unionBy(state.all, brochures, "id");

    state.filterBy.next_token += 1;
    state.busy = false;
  },
  [types.PUT_FILTER_BROCHURES](state, filter_by) {
    state.filterBy = filter_by;
  },
  [types.PUT_SORT_BROCHURES](state, { sort_by }) {
    state.sortBy = sort_by;
  },
  [types.PUT_STASUSES_BROCHURES](state, { status }) {
    state.showStatus = status;
  },
  [types.CREATE_BROCHURE](state, { brochure }) {
    state.all.push(brochure);
  },
  [types.UPDATE_BROCHURE](state, { brochure }) {
    let index = _.findIndex(state.all, (b) => {
      return b.id == brochure.id;
    });

    state.all.splice(index, 1, brochure);
  },
  [types.UPDATE_COUNTERS](state, { response }) {
    state.counters = response;
  },
  [types.UPDATE_UTM_COUNTER](state, { response }) {
    state.utm_counter = response.counter;
  }
};

function setDefaultFilter() {
  return {
    status: undefined,
    language_ids: undefined,
    regions_ids: undefined,
    categories_ids: undefined,
    shop_ids: undefined,
    top: undefined,
    order: undefined,
    next_token: 1,
    parser_classes_ids: undefined
  };
}

function resolveDefaultOrder(status) {
  if (status == "inbox" || status == "ready_for_approval") {
    return undefined;
  } else {
    return "published_at";
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};
