<template>
  <div class="row flex-items-xs-middle">
    <div :class="stateClassName" class="state-circle mr-1"></div>

    <b-button @click="show" variant="modal" class="text-capitalize">
      Show
    </b-button>
  </div>
</template>

<script>
export default {
  name: "State",
  props: ["state", "iterationId"],
  methods: {
    show() {
      this.$emit("show", this.iterationId);
    }
  },
  computed: {
    stateClassName() {
      switch (this.state) {
        case "finished":
          return "green";
        case "failed":
          return "red";
        default:
          return "orange";
      }
    }
  }
};
</script>
