import gql from "graphql-tag";

const identityUser = gql`
  query IdentityUser($identityUser: IdentityUserInput!) {
    identityUser(identityUser: $identityUser) {
      email
      firstName
      id
      lastName
      roles
      customerClients {
        id
        name
      }
    }
  }
`;

export default identityUser;
