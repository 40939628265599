<template>
  <div>
    <modal :show="isShow" :large="true" @cancel="onClose">
      <div slot="header">
        <h3 class="title">{{ statName }} Statistics</h3>
        <h6 v-if="isType" class="secondary">
          on <u class="text-capitalize">{{ secondaryInfo }}</u> list
        </h6>

        <div class="links">
          <a v-if="isType" :href="build_dashboard_url" target="_blank">
            Statistics Dashboard
          </a>
        </div>
      </div>

      <div slot="footer">
        <b-button @click="onClose()" variant="modal"> close </b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import modal from "../shared/modal";
import * as _ from "lodash";

export default {
  name: "Stats",
  components: { modal },
  props: ["data", "type"],
  data() {
    return {};
  },
  computed: {
    build_dashboard_url() {
      let host = window.location.host;
      let url = "https://";
      if (process.env.NODE_ENV !== "production") {
        url = url + "staging.";
      }
      if (host.includes("allefolders")) {
        return `${url}dashboard.allefolders.nl/${this.statId}/overview`;
      } else {
        return `${url}dashboard.promopromo.be/${this.statId}/overview`;
      }
    },
    statName() {
      let name = this.data ? this.data.name : null;

      return _.truncate(name, {
        length: 35
      });
    },
    statId() {
      return this.data ? this.data.id : null;
    },
    isShow() {
      return this.$store.getters.statsActive;
    },
    isType() {
      return this.type == "shops" && this.data;
    },
    secondaryInfo() {
      return _.startCase(this.data.customer);
    }
  },
  methods: {
    onClose() {
      this.$store.dispatch("statsClose");
    }
  }
};
</script>
<style>
.links {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
</style>
