<template>
  <div class="new-user-form">
    <form v-on:submit.prevent="submit">
      <div class="form-field">
        <label for="name">Email</label>
        <input
          class="text-input"
          id="email"
          name="email"
          type="email"
          @input="inputEmail"
          :value="email"
          placeholder="Type email of user here"
        />
        <span class="helper" v-if="helper.email">{{ helper.email }}</span>
      </div>
      <div class="form-field">
        <label for="firstName">First name</label>
        <input
          class="text-input"
          id="firstName"
          name="firstName"
          type="text"
          @input="inputFirstName"
          :value="firstName"
          placeholder="Type first name of user here"
        />
        <span class="helper" v-if="helper.firstName">{{
          helper.firstName
        }}</span>
      </div>
      <div class="form-field">
        <label for="lastName">Last name</label>
        <input
          class="text-input"
          id="lastName"
          name="lastName"
          type="text"
          @input="inputLastName"
          :value="lastName"
          placeholder="Type last name of user here"
        />
        <span class="helper" v-if="helper.lastName">{{ helper.lastName }}</span>
      </div>
      <div class="form-field">
        <label for="roles">Roles</label>
        <div>
          <label class="typo__label">Select role(s)</label>
          <multiselect
            v-model="roles"
            :options="adminRolesOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            label="label"
            track-by="value"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span class="multiselect__single" v-if="values.length && !isOpen"
                >Selected roles: {{ joinRoles(roles) }}</span
              ></template
            >
          </multiselect>
        </div>
      </div>
      <div class="form-field">
        <label for="roles">Clients</label>
        <div>
          <label class="typo__label">Select client(s)</label>
          <multiselect
            v-model="customerClientIds"
            :options="clientsOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            label="label"
            track-by="value"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span class="multiselect__single" v-if="values.length && !isOpen"
                >{{ customerClientIds.length }} selected</span
              ></template
            >
          </multiselect>
        </div>
      </div>
      <input class="jaf-submit" type="submit" value="Create new user" />
    </form>
  </div>
</template>

<script>
import CreatIdentityUserMutation from "../../../graphql/mutations/CreateIdentityUser";
import CustomerClientsQuery from "../../../graphql/queries/CustomerClients";
import { adminRoles } from "../../../constants";

export default {
  name: "new-user-form",
  data() {
    return {
      email: "",
      firstName: "",
      lastName: "",
      roles: [],
      customerClientIds: [],
      helper: { email: null, firstName: null, lastName: null }
    };
  },
  apollo: {
    customerClients: {
      query: CustomerClientsQuery,
      deep: true,
      variables() {
        return {
          customerClients: {
            orderBy: "NAME_AT_ASC"
          },
          pagination: {
            page: 1,
            pageSize: 1000
          }
        };
      }
    }
  },

  computed: {
    clientsOptions() {
      if (this.customerClients && this.customerClients.entries.length > 0) {
        const { entries } = this.customerClients;
        const options = entries.map((entry) => ({
          value: entry.id,
          label: entry.name
        }));
        return options;
      }
      return [];
    },
    adminRolesOptions() {
      const roles = Object.entries(adminRoles);
      const options = roles.map((role) => ({ value: role[1], label: role[0] }));
      return options;
    },
    selectedClientIds() {
      return this.customerClientIds.map((client) => client.value);
    },
    selectedRoles() {
      return this.roles.map((role) => role.value.toUpperCase());
    }
  },
  methods: {
    joinRoles(roles) {
      const joinedRolesValues = roles.map((role) => role.label);
      return joinedRolesValues.join(", ");
    },
    inputFirstName({ currentTarget }) {
      this.firstName = currentTarget.value;
    },
    inputLastName({ currentTarget }) {
      this.lastName = currentTarget.value;
    },
    inputEmail({ currentTarget }) {
      this.email = currentTarget.value;
    },
    submit() {
      const { email, firstName, lastName, selectedRoles, selectedClientIds } =
        this;
      if (email === "") this.helper.email = "Please add email";
      if (firstName === "") this.helper.firstName = "Please add firstName";
      if (lastName === "") this.helper.lastName = "Please add lastName";
      else if (
        email &&
        email.length > 0 &&
        firstName &&
        firstName.length > 0 &&
        lastName &&
        lastName.length > 0
      )
        this.createIdentityUser(
          email,
          firstName,
          lastName,
          selectedRoles,
          selectedClientIds
        );
    },
    createIdentityUser(
      email,
      firstName,
      lastName,
      selectedRoles,
      selectedClientIds
    ) {
      const variables = {
        createIdentityUser: {
          email,
          firstName,
          lastName,
          roles: selectedRoles,
          customerClientIds: selectedClientIds
        }
      };
      this.$apollo
        .mutate({
          mutation: CreatIdentityUserMutation,
          variables
        })
        .then(() => this.$router.push("/users"));
    }
  }
};
</script>

<style scoped>
.new-user-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.new-user-form .text-input {
  display: block;
  width: 50%;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);

  padding: 0.28rem 0.5rem;
  font-size: 0.8rem;
  border-radius: 0.2rem;
}
.new-user-form .helper {
  font-size: 0.6rem;
  color: red;
}
.jaf-submit {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  border-bottom-color: rgb(51, 51, 51);
  border-radius: 4px;
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue",
    Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.75rem 0.7rem;
  text-align: left;
  border: none;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 18%);
  width: max-content;
}
.jaf-submit:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}
</style>
