<template>
  <thead class="data-table-header">
    <tr>
      <th v-for="h in headers" :key="h.value">{{ h.label }}</th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: "table-data-cell",
  props: ["headers"]
};
</script>

<style scoped>
.data-table-header tr {
  background-color: #fff;
}

.data-table-header th {
  border: 1px solid #dfe6ec;
  line-height: 30px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 700;
  vertical-align: middle;
}
</style>
