<template>
  <div class="new-location location-form">
    <div class="header">
      <span class="page-title">New Shop Location</span>
    </div>
    <form>
      <div class="row">
        <div class="col-md-3">
          <fieldset class="form-group row">
            <label class="col-form-label col-xs-12">Region *</label>
            <div :class="selectStateClass($v.region.$error)" class="col-xs-12">
              <multiselect
                v-model="region"
                :options="regions"
                @input="$v.region.$touch()"
                placeholder="Select Region"
                label="name"
                track-by="name"
              >
              </multiselect>
              <label
                v-if="$v.region.$error"
                class="col-form-label col-xs-12 error-label"
              >
                You must select at least one
              </label>
            </div>
          </fieldset>
        </div>
        <div class="col-md-3">
          <b-form-input
            v-model="name"
            @input="$v.name.$touch()"
            :state="inputStateClass($v.name.$error)"
            type="text"
            label="Name *"
            placeholder="Type name"
            size="sm"
          />
        </div>
        <div class="col-md-2">
          <b-form-input
            v-model="address"
            @input="$v.address.$touch()"
            :state="inputStateClass($v.address.$error)"
            type="text"
            label="Address *"
            placeholder="Type address"
            size="sm"
          />
        </div>
        <div class="col-md-2">
          <b-form-input
            v-model="zip_code"
            @input="$v.zip_code.$touch()"
            :state="inputStateClass($v.zip_code.$error)"
            type="text"
            label="Zip code *"
            placeholder="Type zip code"
            size="sm"
          />
        </div>
        <div class="col-md-2">
          <b-form-input
            v-model="city"
            @input="$v.city.$touch()"
            :state="inputStateClass($v.city.$error)"
            type="text"
            label="City *"
            placeholder="Type city"
            size="sm"
          />
        </div>
      </div>
      <div class="footer">
        <b-button @click="onDiscard" variant="modal" class="ml-1">
          discard
        </b-button>
        <button
          @click="onSubmit"
          type="submit"
          class="btn btn-modal btn-md ml-1"
        >
          create
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import formStateMixin from "../../mixins/formStateMixin";

export default {
  name: "new-location",
  mixins: [validationMixin, formStateMixin],
  props: ["shop"],
  data() {
    return {
      region: null,
      name: "",
      address: "",
      zip_code: "",
      city: ""
    };
  },
  computed: {
    regions() {
      return this.$store.getters.allLocationsRegions;
    },
    object() {
      return {
        region_id: this.region.id,
        name: this.name,
        address: this.address,
        zip_code: this.zip_code,
        city: this.city,
        shop_id: this.shop.id
      };
    }
  },
  validations: {
    region: { required },
    name: { required },
    address: { required },
    zip_code: { required },
    city: { required }
  },
  methods: {
    onDiscard() {
      this.$emit("discard");
    },
    onSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$store.dispatch("createLocation", this.object);
        this.resetForm();
      }
    },
    resetForm() {
      this.region = null;
      this.name = "";
      this.address = "";
      this.zip_code = "";
      this.city = "";

      this.$v.$reset();
    }
  }
};
</script>
