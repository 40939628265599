import gql from "graphql-tag";

const createAdsAdvertisement = gql`
  mutation createAdsAdvertisement(
    $createAdsAdvertisement: CreateAdsAdvertisementImageAdInput!
  ) {
    createAdsAdvertisementImageAd(
      createAdsAdvertisementImageAd: $createAdsAdvertisement
    ) {
      id
    }
  }
`;

export default createAdsAdvertisement;
