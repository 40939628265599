<template>
  <div class="admins content admins-table">
    <toolbar />

    <el-table
      v-if="isAdmins"
      :data="admins"
      :default-sort="{ prop: 'first_name', order: 'ascending' }"
      stripe
      border
      class="mt-2"
    >
      <el-table-column
        :resizable="resizableColumn"
        label="First name"
        prop="first_name"
        min-width="150"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.first_name }}
        </template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Last name"
        prop="last_name"
        min-width="150"
      >
        <template slot-scope="scope">
          {{ scope.row.last_name }}
        </template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Email"
        prop="email"
        min-width="300"
        sortable
      >
        <template slot-scope="scope">
          {{ getCount(scope.row.email) }}
        </template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Roles"
        prop="roles"
        min-width="300"
        sortable
      >
        <template slot-scope="scope">
          <span v-for="role in scope.row.roles" v-bind:key="role">
            {{ role }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Created"
        prop="inserted_at"
        min-width="200"
        sortable
      >
        <template slot-scope="scope">
          <div class="row col-xs-12 flex-items-xs-middle flex-items-xs-between">
            <div>
              {{ displayDate(scope.row.inserted_at) }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :resizable="resizableColumn"
        label="Options"
        min-width="100"
      >
        <template slot-scope="scope">
          <div class="row col-xs-12 flex-items-xs-middle flex-items-xs-between">
            <div>
              <table-admin-controls :item="scope.row" />
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { formatDate } from "../../utils";
import tableAdminControls from "./table-controls";
import TableLanguage from "../shared/table-language";
import toolbar from "./toolbar";

export default {
  name: "admins",
  components: { TableLanguage, tableAdminControls, toolbar },
  data() {
    return {
      statData: null,
      statType: "admins",
      resizableColumn: false
    };
  },
  computed: {
    admins() {
      return this.$store.getters.allAdmins;
    },
    isAdmins() {
      return this.admins && this.admins.length;
    }
  },
  created() {
    this.$store.dispatch("fetchAdmins");
  },
  methods: {
    getCount(count) {
      return count ? count : 0;
    },
    displayDate(dataNumber) {
      return formatDate(Date.parse(dataNumber), "HH:MM dd-mm-yyyy");
    },
    myRowClickHandler(record, index) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      this.$router.push({ path: "/admins/" + record.id });
    }
  }
};
</script>

<style>
.admins-table .el-table__body tr {
  cursor: pointer;
}
</style>
