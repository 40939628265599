<template>
  <div class="new-category">
    <modal
      :customerClass="'modal-type-dialog'"
      :show.sync="show"
      :large="true"
      @cancel="onClose"
    >
      <div slot="header">
        <h3 class="title">New Category</h3>
      </div>
      <category-form :struct="formStruct" v-on:update="updateData" />
      <div slot="footer">
        <b-button class="btn-ln-md" variant="modal" @click="onClose()">
          discard
        </b-button>
        <b-button class="btn-ln-md" variant="modal" @click="onSubmit()">
          create
        </b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import categoryForm from "./form";
import modal from "../shared/modal";
const helper = require("./helper");

export default {
  name: "new-category",
  components: { categoryForm, modal },
  data() {
    return {
      formStruct: helper.formStruct,
      data: {}
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onSubmit() {
      var form = this.$children[0].$children[0];
      form.validateAll();
      if (form.isValid()) {
        var params = helper.deserialize(this.data);
        this.$store.dispatch("createCategory", params);
        this.onClose();
      }
    },
    updateData(data) {
      this.data = data;
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
};
</script>
