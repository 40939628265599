import { api } from "../api";
import * as _ from "lodash";
import * as types from "../mutation-types";

const state = {
  all: [],
  params: {
    shop_id: undefined,
    page: undefined,
    search: undefined
  },
  total_pages: undefined,
  regions: [],
  importMsg: undefined,
  busy: false,
  locationsFileBlob: undefined
};

const getters = {
  allLocations: (state) => state.all,
  locationsTotalPages: (state) => state.total_pages,
  allLocationsRegions: (state) => state.regions,
  locationImportMsg: (state) => state.importMsg,
  locationsLoading: (state) => state.busy,
  locationsFileBlob: (state) => state.locationsFileBlob
};

const actions = {
  getLocationBlob({ commit }, { shop_id }) {
    api.getLocationsFile(shop_id, (blob) => {
      commit("locationsFileBlob", { blob });
    });
  },
  getAllLocations({ commit }, id) {
    state.busy = true;
    state.params.shop_id = id;

    api.getLocations(state.params, (data) => {
      commit(types.RECEIVE_LOCATIONS, { data });
    });

    api.getLocationsRegions(state.params, (regions) => {
      commit(types.RECEIVE_LOCATIONS_REGIONS, { regions });
    });
  },
  locationSearchChange({ commit }, search) {
    state.busy = true;
    state.params.search = search;
    state.params.page = undefined;

    api.getLocations(state.params, (data) => {
      commit(types.RECEIVE_LOCATIONS, { data });
    });
  },
  createLocation({ commit }, params) {
    api.createLocation(params, (location) => {
      commit(types.CREATE_LOCATION, { location });
    });
  },
  importLocation({ commit }, params) {
    return api.importLocation(params, (location) => {
      commit(types.IMPORT_LOCATION, { location });
    });
  },
  importLocationFromCsv({ commit }, params) {
    return api.importLocationFromCsv(params, (locations) => {
      commit(types.IMPORT_LOCATION, { locations });
    });
  },
  deleteLocation({ commit }, id) {
    api.deleteLocation(id, (ok) => {
      if (ok) {
        commit(types.DELETE_LOCATION, { id });
      }
    });
  },
  deleteAllLocations({ commit }, id) {
    state.params.shop_id = id;
    api.deleteAllLocations(state.params, (ok) => {
      if (ok) {
        commit(types.DELETE_ALL_LOCATIONS);
      }
    });
  },
  locationPageChange({ commit }, page_number) {
    state.params.page = page_number;

    api.getLocations(state.params, (data) => {
      commit(types.RECEIVE_LOCATIONS, { data });
    });
  }
};

const mutations = {
  locationsFileBlob(state, { blob }) {
    state.locationsFileBlob = new Blob([blob], {
      type: "application/octet-stream"
    });
  },
  [types.RECEIVE_LOCATIONS](state, { data }) {
    state.all = data.locations;
    state.total_pages = data.total_pages;
    busyDelay();
  },
  [types.RECEIVE_LOCATIONS_REGIONS](state, { regions }) {
    state.regions = regions;
  },
  [types.CREATE_LOCATION](state, { location }) {
    state.all.unshift(location);
  },
  [types.IMPORT_LOCATION](state, { locations }) {
    if (!locations) return;

    locations.forEach((location) => {
      state.all.push(location);
    });
  },
  [types.DELETE_LOCATION](state, { id }) {
    let index = _.findIndex(state.all, (item) => {
      return item.id == id;
    });

    state.all.splice(index, 1);
  },
  [types.DELETE_ALL_LOCATIONS](state) {
    state.all = [];
    state.total_pages = 0;
    busyDelay();
  }
};

const busyDelay = () => {
  setTimeout(() => (state.busy = false), 300);
};

export default {
  state,
  getters,
  actions,
  mutations
};
