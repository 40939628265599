import { LOGIN, LOGOUT, SET_USER } from "../mutation-types";

const defaulAuthState = null;

const state = { user: defaulAuthState };

const getters = {
  user: (state) => state
};

const mutations = {
  [LOGIN](state, me) {
    const user = {};
    user.first_name = me.firstName;
    user.last_name = me.lastName;
    user.email = me.email;
    user.roles = me.roles;
    sessionStorage.setItem("session-user", JSON.stringify(user));

    state.user = user;
  },
  [LOGOUT](state) {
    state.user = defaulAuthState;
  },
  [SET_USER](state, user) {
    state.user = user;
  }
};

const actions = {
  login({ commit }, me) {
    commit(LOGIN, me);
  },
  logout({ commit }) {
    commit(LOGOUT);
  },
  setuser({ commit }, user) {
    commit(SET_USER, user);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
