import gql from "graphql-tag";

const customerClients = gql`
  query CustomerClients(
    $customerClients: CustomerClientsInput!
    $pagination: PaginationInput
  ) {
    customerClients(
      customerClients: $customerClients
      pagination: $pagination
    ) {
      entries {
        id
        name
      }
      pagination {
        currentPage
        nextPage
        previousPage
        totalPages
      }
    }
  }
`;

export default customerClients;
