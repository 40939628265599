<template>
  <div :class="zoomStateClass" class="slider__lastButtonBox">
    <div
      @click="first"
      :class="stateClassFirst"
      class="slider__lastButton -first"
    >
      First page
    </div>

    <div @click="last" :class="stateClassLast" class="slider__lastButton -last">
      Last page
    </div>
  </div>
</template>

<script>
import defaultMixin from "./defaultMixin";

export default {
  name: "LastButton",
  mixins: [defaultMixin],
  computed: {
    stateClassFirst() {
      return this.index > 2 ? "" : "-hide";
    },
    stateClassLast() {
      return this.index < this.length - 14 ? "" : "-hide";
    }
  },
  methods: {
    first() {
      this.$store.dispatch("setFirstPageIndex");
    },
    last() {
      this.$store.dispatch("setLastPageIndex");
    }
  }
};
</script>
