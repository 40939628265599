<template>
  <PageLayout class="identity-user-edit">
    <ToolBar to="/users" title="Update user" />
    <EditForm :user="identityUser" v-if="identityUser" />
  </PageLayout>
</template>

<script>
import EditForm from "./edit-user-form.vue";
import IdentityUserQuery from "../../../graphql/queries/IdentityUser";
import ToolBar from "../../layout/tool-bar.vue";
import PageLayout from "../../layout/page-layout.vue";

export default {
  name: "identity-user-edit",
  components: { EditForm, ToolBar, PageLayout },
  computed: {
    id() {
      return this.$route.query.id;
    }
  },
  apollo: {
    identityUser: {
      query: IdentityUserQuery,
      variables() {
        return {
          identityUser: { id: this.id }
        };
      },
      skip() {
        return !this || !this.id;
      }
    }
  }
};
</script>
