<template>
  <div class="shop-form">
    <form v-if="formDataLoaded">
      <div class="row">
        <div class="col-md-9 pr-1">
          <b-form-input
            v-model="data.name"
            @input="$v.data.name.$touch()"
            type="text"
            label="Name *"
            :state="$v.data.name.$error ? 'danger' : ''"
            placeholder="Type text..."
            size="sm"
          />
          <b-form-input
            v-model="data.tracking_uid"
            @input="$v.data.tracking_uid.$touch()"
            type="text"
            :state="$v.data.tracking_uid.$error ? 'danger' : ''"
            label="Tracking uid"
            placeholder="Type text..."
            size="sm"
          />
          <div class="form-group row">
            <label class="col-form-label col-xs-12">Customer Level</label>
            <div class="col-xs-12">
              <multiselect
                v-model="data.customer_level"
                :options="customer_levels"
                placeholder="Select customer level"
              >
              </multiselect>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-xs-12"
              >Automatic Parser Slug</label
            >
            <div :class="'col-xs-' + slug_languages.length">
              <div role="group" class="btn-group form-button-group">
                <div
                  class="btn btn-tool btn-sm"
                  v-for="lang in slug_languages"
                  :key="lang.symbol"
                >
                  <input
                    v-model="data.slug_languages"
                    type="radio"
                    class="btn btn-tool btn-sm"
                    :value="lang.symbol"
                  />
                  <span>{{ lang.symbol.toUpperCase() }}</span>
                </div>
              </div>
            </div>
            <div class="col-xs-5">
              <div role="group" class="btn-group form-button-group">
                <div
                  class="btn btn-tool btn-sm"
                  v-for="type in auto_parser_types"
                  :key="type.name"
                >
                  <input
                    v-model="data.auto_parser_type"
                    type="radio"
                    class="btn btn-tsool btn-sm"
                    :value="type.value"
                  />
                  <span>{{ type.name }}</span>
                </div>
              </div>
            </div>
            <div :class="'col-xs-' + (7 - languages.length)">
              <input
                v-model="data.slug_input[data.slug_languages.toUpperCase()]"
                type="text"
                name="name"
                placeholder="Type text..."
                class="form-control form-control-sm"
              />
            </div>
          </div>
          <b-form-input
            v-model="data.additional_ga_tracking_id"
            type="text"
            name="name"
            label="GA Tracking ID"
            placeholder="Type text..."
            size="sm"
          />
          <div class="form-group row">
            <label class="col-form-label col-xs-12">Categories</label>
            <div class="col-xs-12">
              <multiselect
                v-model="data.shop_categories"
                :options="categories"
                :multiple="true"
                placeholder="Select categories"
                label="name"
                track-by="id"
              >
              </multiselect>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fieldset class="form-group row">
                <label class="col-form-label col-xs-12"
                  >Featured In Top Category Pile</label
                >
                <div class="col-xs-12">
                  <multiselect
                    v-model="data.shop_toplevel"
                    :options="categories"
                    :multiple="true"
                    placeholder="Select categories"
                    label="name"
                    track-by="id"
                  >
                  </multiselect>
                </div>
              </fieldset>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-form-label col-xs-12">Afterpage</label>
                <div class="col-xs-12">
                  <multiselect
                    v-model="data.shop_afterpage"
                    :options="categories"
                    :multiple="true"
                    placeholder="Select categories"
                    label="name"
                    track-by="id"
                  >
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-xs-12">Search Keywords</label>
            <div class="col-xs-12">
              <textarea
                v-model="data.search_keywords"
                name="name"
                placeholder="Type text..."
                class="form-control form-control-sm"
              >
              </textarea>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-xs-12">Postcodes</label>
            <div class="col-xs-12">
              <textarea
                v-model="data.postcodes_text"
                name="name"
                placeholder="Separators: space, newline, coma"
                class="form-control form-control-sm"
              >
              </textarea>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-xs-12">Contact Emails</label>
            <div class="col-xs-12">
              <textarea
                v-model="data.contact_emails_text"
                name="name"
                placeholder="Separators: space, newline, coma"
                class="form-control form-control-sm"
              >
              </textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-form-label col-xs-12">Select Customer</label>
                <div
                  :class="selectStateClass($v.data.customer.$error)"
                  class="col-xs-12"
                >
                  <multiselect
                    v-model="data.customer"
                    :options="customers"
                    @input="$v.data.customer.$touch()"
                    label="text"
                    track-by="value"
                    placeholder="Select customer"
                  >
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <b-form-input
                v-model="data.minimum_brochures_no"
                @input="$v.data.minimum_brochures_no.$touch()"
                type="number"
                :state="$v.data.minimum_brochures_no.$error ? 'danger' : ''"
                label="Minimal Number Of Brochures"
                size="sm"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-input
                v-model="data.website_url"
                type="text"
                label="Website URL"
                placeholder="Type URL..."
                size="sm"
              />
            </div>
            <div class="col-md-6">
              <b-form-input
                v-model="data.email"
                type="text"
                label="Mail"
                placeholder="Type mail..."
                size="sm"
              />
            </div>
          </div>
          <div
            :class="[
              'form-group',
              'row',
              !validDescription ? 'has-danger' : ''
            ]"
          >
            <label class="col-form-label col-xs-12">Description</label>
            <div :class="'col-xs-' + languages.length">
              <div role="group" class="btn-group form-button-group">
                <div
                  v-for="lang in languages"
                  class="btn btn-tool btn-sm"
                  :key="lang.symbol"
                >
                  <input
                    v-model="currentDescriptionLang"
                    type="radio"
                    class="btn btn-tool btn-sm"
                    :value="lang.symbol"
                  />
                  <span class="text-uppercase">{{ lang.symbol }}</span>
                </div>
              </div>
            </div>
            <div
              :class="'col-xs-' + (12 - languages.length)"
              v-for="lang in languages"
              :key="lang.symbol"
              v-if="currentDescriptionLang == lang.symbol"
            >
              <input
                v-model="data.description[lang.id]"
                type="text"
                name="name"
                placeholder="Type description..."
                @input="validateDescription"
                class="form-control form-control-sm"
              />
              />
            </div>
            <label
              v-if="!validDescription"
              class="col-form-label col-xs-12 error-label"
            >
              Some description is too long, max length is 250 words.
            </label>
          </div>
          <div
            :class="['form-group', 'row', !validTitleMain ? 'has-danger' : '']"
          >
            <label class="col-form-label col-xs-12"
              >Meta description - Title Main</label
            >
            <div :class="'col-xs-' + languages.length">
              <div role="group" class="btn-group form-button-group">
                <div
                  class="btn btn-tool btn-sm"
                  v-for="lang in languages"
                  :key="lang.symbol"
                >
                  <input
                    v-model="currentTitleMainLang"
                    type="radio"
                    class="btn btn-tool btn-sm"
                    :value="lang.symbol"
                  />
                  <span class="text-uppercase">{{ lang.symbol }}</span>
                </div>
              </div>
            </div>
            <div
              :class="'col-xs-' + (12 - languages.length)"
              v-for="lang in languages"
              :key="lang.symbol"
              v-if="currentTitleMainLang == lang.symbol"
            >
              <input
                v-model="data.title_main[lang.id]"
                type="text"
                name="name"
                placeholder="Type meta description..."
                @input="validateTitleMain"
                class="form-control form-control-sm"
              />
              />
            </div>
            <label
              v-if="!validTitleMain"
              class="col-form-label col-xs-12 error-label"
            >
              Some description is too long, max length is 5 words.
            </label>
          </div>
          <div
            :class="[
              'form-group',
              'row',
              !validTitleSecondary ? 'has-danger' : ''
            ]"
          >
            <label class="col-form-label col-xs-12"
              >Meta description - Title Secondary</label
            >
            <div :class="'col-xs-' + languages.length">
              <div role="group" class="btn-group form-button-group">
                <div
                  class="btn btn-tool btn-sm"
                  v-for="lang in languages"
                  :key="lang.symbol"
                >
                  <input
                    v-model="currentTitleSecondaryLang"
                    type="radio"
                    class="btn btn-tool btn-sm"
                    :value="lang.symbol"
                  />
                  <span class="text-uppercase">{{ lang.symbol }}</span>
                </div>
              </div>
            </div>
            <div
              :class="'col-xs-' + (12 - languages.length)"
              v-for="lang in languages"
              :key="lang.symbol"
              v-if="currentTitleSecondaryLang == lang.symbol"
            >
              <input
                v-model="data.title_secondary[lang.id]"
                type="text"
                name="name"
                placeholder="Type meta description..."
                @input="validateTitleSecondary"
                class="form-control form-control-sm"
              />
              />
            </div>
            <label
              v-if="!validTitleSecondary"
              class="col-form-label col-xs-12 error-label"
            >
              Some description is too long, max length is 20 words.
            </label>
          </div>
          <div
            :class="[
              'form-group',
              'row',
              !validMetaDescription ? 'has-danger' : ''
            ]"
          >
            <label class="col-form-label col-xs-12"
              >Meta Description - Content</label
            >
            <div :class="'col-xs-' + languages.length">
              <div role="group" class="btn-group form-button-group">
                <div
                  class="btn btn-tool btn-sm"
                  v-for="lang in languages"
                  :key="lang.symbol"
                >
                  <input
                    v-model="currentMetaDescriptionLang"
                    type="radio"
                    class="btn btn-tool btn-sm"
                    :value="lang.symbol"
                  />
                  <span class="text-uppercase">{{ lang.symbol }}</span>
                </div>
              </div>
            </div>
            <div
              :class="'col-xs-' + (12 - languages.length)"
              v-for="lang in languages"
              :key="lang.symbol"
              v-if="currentMetaDescriptionLang == lang.symbol"
            >
              <input
                v-model="data.meta_description[lang.id]"
                type="text"
                name="name"
                placeholder="Type meta description..."
                @input="validateMetaDescription"
                class="form-control form-control-sm"
              />
              />
            </div>
            <label
              v-if="!validMetaDescription"
              class="col-form-label col-xs-12 error-label"
            >
              Some description is too long, max length is 2000 words.
            </label>
          </div>
        </div>
        <div class="col-md-3 pl-1">
          <ul class="right-tools text-right">
            <li class="mb-2">
              <h6 class="title"><b>Regions</b></h6>
              <div
                class="form-group row"
                :key="index"
                v-for="(region, index) in data.regions"
                v-show="!region.delete"
              >
                <div class="col-xs-10">
                  <input
                    v-model="region.name"
                    type="text"
                    placeholder="Type region..."
                    class="form-control form-control-sm"
                  />
                </div>
                <div
                  @click="deleteRegion(index)"
                  class="col-xs-2 delete-region-button"
                >
                  <div class="custom-icon delete-icon"></div>
                </div>
              </div>
              <b-button
                @click="addRegion"
                variant="modal"
                size="sm"
                :class="$v.data.regions.$error ? 'border-red' : ''"
              >
                Add another region
              </b-button>
            </li>
            <li class="mb-2">
              <h6 class="title"><b>Map Icon</b></h6>
              <img
                v-if="iconPath"
                :src="iconPath"
                class="shop-icon-img"
                alt="image"
                style="max-width: 154px"
              />
              <input id="shop_icon" @change="changeIcon" type="file" hidden />
              <b-button
                :class="$v.data.icon.$error ? 'border-red' : ''"
                variant="modal"
                @click="chooseFile('shop_icon')"
              >
                {{ iconButtonMsg }}
              </b-button>
              <label class="secondary">Geen Bestand Geselecteerd</label>
            </li>
            <li class="mb-2">
              <h6 class="title"><b>Radius (km)</b></h6>
              <input v-model="data.radius" type="number" min="0" />
            </li>
            <li class="mb-2">
              <h6 class="title"><b>Choose*</b></h6>
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-model="data.show_in_footer"
                />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Show In Footer</span>
              </label>
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-model="data.use_original_link"
                />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description"
                  >Use Original Link</span
                >
              </label>
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-model="data.show_ads"
                />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Show Ads</span>
              </label>
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-model="data.show_ads_2"
                />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Show Ads 2</span>
              </label>
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-model="data.local_brochures"
                />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Local brochures</span>
              </label>
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-model="data.overwrite_utms"
                />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Overwite Utms</span>
              </label>
            </li>
            <li class="mb-2">
              <h6 class="title"><b>Background Color</b></h6>
              <div
                class="background-color"
                @click="onColorPicker"
                :style="'background:' + data.background.hex"
              ></div>
              <Chrome
                v-if="showColorPicker"
                @change-color="onChangeColor"
                v-model="data.background"
              >
              </Chrome>
            </li>
            <li class="mb-2">
              <h6 class="title"><b>Hotspots</b></h6>
              <div
                :class="selectStateClass($v.data.hotspot_mode.$error)"
                class="col-xs-12"
              >
                <multiselect
                  v-model="data.hotspot_mode"
                  :options="hotspot_mode"
                  @input="$v.data.hotspot_mode.$touch()"
                  label="name"
                  track-by="value"
                  placeholder="Select hotspot mode"
                >
                </multiselect>
              </div>
            </li>
            <li class="mb-2">
              <h6 class="title"><b>Last page</b></h6>
              <div
                :class="selectStateClass($v.data.last_page.$error)"
                class="col-xs-12"
              >
                <multiselect
                  v-model="data.last_page"
                  :options="last_page"
                  @input="$v.data.last_page.$touch()"
                  label="name"
                  track-by="value"
                  placeholder="Select last page"
                >
                </multiselect>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
const helper = require("./helper");
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";
import { Chrome } from "vue-color";
import formStateMixin from "../../mixins/formStateMixin";
import * as _ from "lodash";

export default {
  name: "shop-form",

  mixins: [validationMixin, formStateMixin],

  components: { Chrome },

  computed: {
    categories() {
      return this.$store.getters.allCategories;
    },

    languages() {
      return this.$store.getters.allLanguages;
    },

    iconPath() {
      return _.isString(this.data.icon) ? this.data.icon : null;
    },

    iconButtonMsg() {
      let icon = this.data.icon;

      if (icon) {
        let value = icon.name ? icon.name : icon;
        return value.slice(-9);
      }

      return "Kies bestand";
    }
  },

  props: {
    struct: {
      type: Object
    }
  },

  validations: {
    data: {
      name: {
        required
      },
      tracking_uid: {
        required
      },
      regions: {
        required
      },
      customer: {
        required
      },
      minimum_brochures_no: {
        required,
        numeric
      },
      hotspot_mode: {
        required
      },
      last_page: {
        required
      },
      icon: {
        required
      },
      show_ads: {
        function() {
          return !(this.data.show_ads && this.data.show_ads_2);
        }
      }
    }
  },

  data() {
    return {
      customer_levels: [1, 2, 3, 4, 5],
      data: {},
      formDataLoaded: false,
      showColorPicker: false,
      currentDescriptionLang: "",
      currentMetaDescriptionLang: "",
      currentTitleMainLang: "",
      currentTitleSecondaryLang: "",
      validDescription: true,
      validMetaDescription: true,
      validTitleMain: true,
      validTitleSecondary: true,
      chooseOptions: helper.shopOptions,
      customers: helper.customers,
      slug_languages: [{ symbol: "NL" }, { symbol: "FR" }],
      auto_parser_types: [
        { name: "Publitas", value: "publitas" },
        { name: "PublitasV3", value: "publitasv3" },
        { name: "WePublish", value: "wepublish" },
        { name: "WePublishV3", value: "wepublishv3" }
      ],
      hotspot_mode: helper.hotspot_mode,
      last_page: helper.last_page
    };
  },

  methods: {
    addRegion() {
      this.data.regions.push({ name: "", delete: false });
    },

    changeIcon(event) {
      this.data.icon = event.target.files[0];
    },

    chooseFile(inputId) {
      document.getElementById(inputId).click();
    },

    deleteRegion(index) {
      if (this.data.regions[index].id) {
        this.data.regions[index].delete = true;
      } else {
        this.data.regions.splice(index, 1);
      }
    },

    onColorPicker() {
      var self = this;

      let listener = function (e) {
        if (e.target.className.indexOf("vue-color") == -1) {
          self.showColorPicker = false;
          removeListener();
        }
      };

      let removeListener = function () {
        document.removeEventListener("click", listener);
      };

      setTimeout(function () {
        document.addEventListener("click", listener);
      }, 500);

      self.showColorPicker = !self.showColorPicker;
    },

    onChangeColor(val) {
      this.data.background = val;
    },
    isValid() {
      return (
        !this.$v.$invalid && this.validDescription && this.validMetaDescription
      );
    },
    validateAll() {
      this.$v.$touch();
    },
    validateDescription() {
      let valid = true;

      _.forIn(this.data.description, (value) => {
        if (
          value &&
          value.split(/\s+/, 300).filter((e) => e.length > 0).length > 2000
        ) {
          valid = false;
        }
      });

      this.$set(this, "validDescription", valid);
    },
    validateMetaDescription() {
      let valid = true;
      _.forIn(this.data.meta_description, (value, key) => {
        if (
          value &&
          value.split(/\s+/, 300).filter((e) => e.length > 0).length > 250
        ) {
          valid = false;
        }
      });

      this.$set(this, "validMetaDescription", valid);
    },
    validateTitleMain() {
      let valid = true;
      _.forIn(this.data.title_main, (value, key) => {
        if (
          value &&
          value.split(/\s+/, 300).filter((e) => e.length > 0).length > 5
        ) {
          valid = false;
        }
      });

      this.$set(this, "validTitleMain", valid);
    },
    validateTitleSecondary() {
      let valid = true;
      _.forIn(this.data.title_secondary, (value, key) => {
        if (
          value &&
          value.split(/\s+/, 300).filter((e) => e.length > 0).length > 20
        ) {
          valid = false;
        }
      });

      this.$set(this, "validTitleSecondary", valid);
    }
  },
  watch: {
    data: {
      handler: function (val) {
        this.$emit("update", val);
      },
      deep: true
    }
  },
  mounted() {
    this.$set(this, "data", _.cloneDeep(this.struct));
    this.currentMetaDescriptionLang = this.languages[0].symbol;
    this.currentDescriptionLang = this.languages[0].symbol;
    this.currentTitleMainLang = this.languages[0].symbol;
    this.currentTitleSecondaryLang = this.languages[0].symbol;
    this.$set(this, "formDataLoaded", true);
    this.validateMetaDescription();
    this.validateDescription();
  }
};
</script>
