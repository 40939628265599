<template>
  <div class="locations content">
    <locations-toolbar
      :showNew="showNewForm"
      :showImport="showImportForm"
      :shop="shop"
      @show-new="isNew"
      @show-import="isImport"
    />

    <import-form v-if="showImportForm" :shop="shop" @discard="isImport" />

    <new-form v-if="showNewForm" :shop="shop" @discard="isNew" />

    <modal
      v-if="showDeleteModal"
      :customerClass="'modal-type-dialog'"
      :show.sync="showDeleteModal"
      @cancel="onHideDelete"
      :large="true"
      :closeIcon="false"
    >
      <div slot="header">
        <h3><b>Delete</b></h3>
      </div>
      <h6>Are you sure? You couldn't restore it back.</h6>
      <div slot="footer">
        <b-button
          class="active btn-ln-md"
          variant="modal"
          @click="onHideDelete"
        >
          No
        </b-button>
        <b-button class="btn-ln-md" variant="modal" @click="this.delete">
          Yes
        </b-button>
      </div>
    </modal>

    <div class="clearfix col-xs-12">
      <b-button
        v-if="isDeleteAllButton"
        @click="displayDeleteAllModal"
        variant="danger"
        size="sm"
        class="text-uppercase mt-1 pull-right"
      >
        Delete locations of this shop
      </b-button>
    </div>

    <el-table
      v-loading="locationsLoading"
      :data="locations"
      stripe
      border
      class="mt-2"
    >
      <div slot="empty">Sorry, empty table :(</div>

      <el-table-column label="Address" prop="address">
        <template slot-scope="scope">
          <div class="clearfix">
            <div class="pull-left">
              {{ rowText(scope.row) }}
            </div>

            <div class="pull-right">
              <div
                v-if="!locationIsCorrupted(scope.row)"
                class="display-inline"
              >
                <div
                  @click="openInGoogleMaps(scope.row)"
                  class="display-inline standard-spacing custom-icon location-icon"
                ></div>
                <div
                  class="display-inline standard-spacing custom-icon tick-icon"
                ></div>
                <div
                  @click="displayDeleteModal(scope.row.id)"
                  class="display-inline standard-spacing custom-icon delete-icon"
                ></div>
              </div>

              <div v-if="locationIsCorrupted(scope.row)" class="display-inline">
                <div
                  class="display-inline standard-spacing custom-icon cross-icon"
                ></div>
                <div
                  @click="displayDeleteModal(scope.row.id)"
                  class="display-inline standard-spacing custom-icon delete-icon"
                ></div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="totalPages > 1"
      @current-change="pageChange($event)"
      layout="pager"
      :page-count="totalPages"
      class="text-xs-center mt-1"
    >
    </el-pagination>
  </div>
</template>

<script>
import locationsToolbar from "./locations-toolbar";
import importForm from "./import";
import modal from "../shared/modal";
import newForm from "./new";
import * as configs from "../../config";

export default {
  name: "locations",
  components: { locationsToolbar, modal, importForm, newForm },
  data() {
    return {
      shop_id: this.$route.params.shop_id,
      showNewForm: false,
      showImportForm: false,
      showDeleteModal: false,
      deleteModalFunction: function () {}
    };
  },
  computed: {
    shop() {
      return this.$store.getters.activeShop;
    },
    locations() {
      return this.$store.getters.allLocations;
    },
    totalPages() {
      return this.$store.getters.locationsTotalPages;
    },
    locationsLoading() {
      return this.$store.getters.locationsLoading;
    },
    isDeleteAllButton() {
      return this.locations.length > 1;
    }
  },
  created() {
    this.$store.dispatch("fetchShop", this.shop_id);
    this.$store.dispatch("getAllLocations", this.shop_id);
  },
  methods: {
    rowText(location) {
      var rowArray = [location.address, location.city, location.zip_code];
      if (location.region) rowArray.push(location.region.name);
      return rowArray.join(", ");
    },
    openInGoogleMaps(location) {
      const url = `https://www.google.com/maps/place/${location.lat}+${location.lng}`;
      window.open(url);
    },
    locationIsCorrupted(location) {
      return !(location.lat && location.lng);
    },
    isNew() {
      this.showImportForm = false;
      this.showNewForm = !this.showNewForm;
    },
    isImport() {
      this.showNewForm = false;
      this.showImportForm = !this.showImportForm;
    },
    delete() {
      this.deleteModalFunction();
    },
    onDelete(id) {
      this.$store.dispatch("deleteLocation", id);
    },
    displayDeleteAllModal() {
      this.showDeleteModal = true;
      this.deleteModalFunction = function () {
        this.$store.dispatch("deleteAllLocations", this.shop_id);
        this.onHideDelete();
      };
    },
    displayDeleteModal(id) {
      this.showDeleteModal = true;
      this.deleteModalFunction = function () {
        this.$store.dispatch("deleteLocation", id);
        this.onHideDelete();
      };
    },
    onHideDelete() {
      this.showDeleteModal = false;
    },
    pageChange(page) {
      this.$store.dispatch("locationPageChange", page);
    }
  }
};
</script>
