<template>
  <div class="pull-right">
    <router-link
      :to="{ path: item.id }"
      class="btn btn-modal btn-md text-capitalize"
      append
    >
      View
    </router-link>
  </div>
</template>

<script>
export default {
  name: "TableButtons",
  props: ["item"]
};
</script>
