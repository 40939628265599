<template>
  <div ref="googleLoginBtn" class="google-login-button" />
</template>

<script>
import { googleClientId } from "@/utils/google.js";

export default {
  mounted() {
    if (window.google) {
      this.initButton();
    } else {
      const googleScript = document.getElementById("google-script");

      googleScript.addEventListener("load", this.initButton);
    }
  },
  methods: {
    initButton() {
      const googleLoginBtn = this.$refs.googleLoginBtn;

      window.google.accounts.id.initialize({
        client_id: googleClientId(),
        callback: this.handleCredentialResponse
      });
      window.google.accounts.id.renderButton(googleLoginBtn, {
        type: "standard", // or 'standard' | 'icon' | 'image'
        size: "large", // or 'small' | 'medium'
        width: 224, // max width 400
        height: "48", // max width 400
        theme: "outline", // or 'filled_black' |  'filled_blue'
        logo_alignment: "left" // or 'center'
      });
    },
    handleCredentialResponse(response) {
      if (response.credential) {
        this.$emit("sign-in", response.credential);
      }
    }
  }
};
</script>
