<template>
  <section id="menu">
    <ul class="sidebar-nav" v-if="navLinks">
      <li v-for="link in navLinks" :key="link.id">
        <router-link :to="link.to">{{ link.label }}</router-link>
      </li>
    </ul>
  </section>
</template>

<script>
import { adminRoles } from "../../constants";

export default {
  name: "nav-links",
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  computed: {
    navLinks() {
      return [
        {
          to: "/brochures",
          label: "Brochures",
          id: "brochures"
        },
        {
          to: "/shops",
          label: "Shops",
          id: "shops"
        },
        {
          to: "/categories",
          label: "Categories",
          id: "categories"
        },
        {
          to: "/parsers",
          label: "Parsers",
          id: "parsers"
        },
        {
          to: "/offers",
          label: "New Offers",
          id: "offers"
        },
        {
          to: "/config",
          label: "Content Items",
          id: "config"
        },
        {
          to: "/users",
          label: "Users",
          id: "identity_users"
        },
        {
          to: "/ads",
          label: "Ads",
          id: "ads"
        }
      ];
    }
  },
  data() {
    return {
      adminRoles: adminRoles
    };
  }
};
</script>
