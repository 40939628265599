import { api } from "../api";

import * as types from "../mutation-types";

const state = {
  new_brochures: undefined,
  brochures: undefined,
  newLoading: false,
  brochuresLoading: false,
  filter: {
    language_id: undefined
  }
};

const getters = {
  offerNew: (state) => state.new_brochures,
  offerBrochures: (state) => state.brochures,
  offerNewLoading: (state) => state.newLoading,
  offerBrochuresLoading: (state) => state.brochuresLoading
};

const actions = {
  getAllOffers({ commit }) {
    state.newLoading = true;
    state.brochuresLoading = true;
    api.getOffers(state.filter, (data) => {
      let new_brochures = data.new_brochures;
      let brochures = data.brochures;

      commit(types.RECEIVE_NEW_OFFERS, { new_brochures });
      commit(types.RECEIVE_OFFERS, { brochures });
    });
  },
  updateOfferPosition({ commit }, { brochure_id, position }) {
    api.updateOfferPosition(brochure_id, { position: position }, () => {
      actions.getAllOffers({ commit });
    });
  },
  updateOfferFreeze({ commit }, { brochure_id, position_frozen_to }) {
    api.updateOfferFreeze(
      brochure_id,
      { position_frozen_to: position_frozen_to },
      () => {
        actions.getAllOffers({ commit });
      }
    );
  },
  offerFilterChange({ commit }, filter) {
    state.filter.language_id = filter.language ? filter.language.id : undefined;
    actions.getAllOffers({ commit });
  },
  offerFilterReset() {
    state.filter = {};
  }
};

const mutations = {
  [types.RECEIVE_NEW_OFFERS](state, { new_brochures }) {
    state.new_brochures = new_brochures;
    state.newLoading = false;
  },
  [types.RECEIVE_OFFERS](state, { brochures }) {
    state.brochures = brochures;
    state.brochuresLoading = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
