import * as _ from "lodash";

export default {
  methods: {
    truncat(text, length, separator = "...") {
      if (text && length) {
        return _.truncate(text, {
          length: length,
          separator: separator
        });
      }
    }
  }
};
