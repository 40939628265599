export const interruptMixin = {
  mounted() {
    window.onbeforeunload = () => {
      return "Data will be lost if you leave the page, are you sure?";
    };
  },
  destroyed() {
    window.onbeforeunload = "";
  }
};
