<template>
  <div class="new-shop">
    <modal :show.sync="show" :large="true" @cancel="onClose">
      <div slot="header">
        <h3 class="title">New Shop</h3>
      </div>
      <shop-form
        v-if="formStruct !== null && params !== null"
        ref="newShopForm"
        :struct="formStruct"
        :languages="languages"
        v-on:update="updateData"
      />
      <div slot="footer">
        <b-button class="btn-ln-md" variant="modal" @click="onSubmit()">
          create
        </b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import shopForm from "./form";
import modal from "../shared/modal";
const helper = require("./helper");
import { mapGetters } from "vuex";
import { interruptMixin } from "../../mixins/interruptMixin.js";
import * as _ from "lodash";

export default {
  name: "new-shop",
  mixins: [interruptMixin],
  components: { shopForm, modal },
  computed: mapGetters({
    languages: "allLanguages"
  }),
  data() {
    return {
      formStruct: null,
      data: null
    };
  },
  created() {
    this.$store.dispatch("fetchCategories");

    this.$set(this, "formStruct", _.cloneDeep(helper.formStruct()));

    for (let i = 0, len = this.languages.length; i < len; i++) {
      this.formStruct.description[this.languages[i].id] = "";
      this.formStruct.meta_description[this.languages[i].id] = "";
    }
  },
  methods: {
    serializeShop() {
      this.formStruct = {};
      this.params = {};
    },
    onClose() {
      this.$emit("close");
    },
    onSubmit() {
      const form = this.$refs.newShopForm;
      form.validateAll();
      if (form.isValid()) {
        var params = helper.deserialize(this.data);
        this.$store.dispatch("createShop", params);
        this.onClose();
      }
    },
    updateData(data) {
      this.data = data;
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
};
</script>
