<template>
  <div class="categories content">
    <Breadcrumb :data="breadcrumbData" />

    <ParsersForm />

    <el-table v-if="isParsers" :data="parsers" stripe border class="mt-2">
      <el-table-column
        :resizable="resizableColumn"
        label="Name"
        width="219"
        prop="name"
      >
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Shop"
        width="219"
        prop="shop.name"
        sortable
      >
      </el-table-column>

      <el-table-column
        :resizable="resizableColumn"
        label="Language"
        width="115"
        align="center"
      >
        <template slot-scope="scope">
          <TableLanguage :item="scope.row" />
        </template>
      </el-table-column>

      <el-table-column :resizable="resizableColumn" label="URL">
        <template slot-scope="scope">
          {{ truncat(scope.row.url, 60) }}
          <TableButtons :item="scope.row" />
        </template>
      </el-table-column>
    </el-table>

    <div v-if="!isParsers" class="panel-background radius-4 border mt-2 p-2">
      You don't have parsers in this class, please add new!
    </div>
  </div>
</template>

<script>
import TableLanguage from "../../shared/table-language";
import Breadcrumb from "../../shared/breadcrumb";
import ParsersForm from "../elements/parsers-form.vue";
import TableButtons from "../elements/table-buttons.vue";
import truncat from "../../../mixins/truncatMixin";

export default {
  name: "Parsers",
  components: { Breadcrumb, ParsersForm, TableButtons, TableLanguage },
  mixins: [truncat],
  data() {
    return {
      resizableColumn: false
    };
  },
  computed: {
    parsers() {
      return this.$store.getters.allParsers;
    },
    activeParserClass() {
      return this.$store.getters.activeParserClass;
    },
    isParsers() {
      return this.parsers && this.parsers.length;
    },
    breadcrumbData() {
      return [
        {
          name: "Parser:" + this.activeParserClass.class,
          url: "/parsers/"
        }
      ];
    }
  },
  beforeCreate() {
    this.$store.dispatch("fetchParsers", this.$route.params.class);
    this.$store.dispatch("getAllRegions");
  }
};
</script>
