<template>
  <div class="brochure-merge-regions">
    <modal :show.sync="show" @cancel="onClose" :small="true">
      <div slot="header">
        <h6 class="title">Choose region</h6>
      </div>

      <section>
        <multiselect
          v-model="region"
          :options="mergeParams.regions"
          placeholder="Select region"
          :custom-label="customLabel"
        >
        </multiselect>
      </section>

      <div class="row" slot="footer">
        <div class="col-xs-6">
          <b-button variant="modal" class="btn-ln-md" @click="onConfirm">
            confirm
          </b-button>
        </div>
        <div class="col-xs-6">
          <b-button variant="modal" class="btn-ln-md" @click="onClose">
            cancel
          </b-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import modal from "../shared/modal";

export default {
  name: "brochure-merge-regions",
  components: { modal },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    mergeParams: {
      default: {}
    }
  },
  data() {
    return {
      region: null
    };
  },
  methods: {
    customLabel(option) {
      return option.name;
    },
    onClose() {
      this.$emit("close");
    },
    onConfirm() {
      this.$store.dispatch("mergeBrochures", this.castParams());
      this.$emit("close");
    },
    castParams() {
      return {
        region_id: this.region.region_id,
        base_brochure_id: this.mergeParams.base_brochure_id,
        merge_brochure_id: this.mergeParams.merge_brochure_id
      };
    }
  }
};
</script>
