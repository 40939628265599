import { api } from "../api";

import * as types from "../mutation-types";

const state = {
  all: [],
  active: undefined,
  brochures: undefined,
  busy: false,
  params: setDefaults(),
  total: undefined,
  count: undefined
};

const getters = {
  allShops: (state) => state.all,
  allShopsLoading: (state) => state.busy,
  activeShop: (state) => state.active,
  activeShopBrochures: (state) => state.brochures,
  shopsTotalPages: (state) => state.total,
  shopsCounts: (state) => state.count
};

const actions = {
  shopFilterChange({ commit }, filter) {
    if (state.busy) return;
    onBusy();
    state.params.customer = filter;

    api.getShops(state.params, (data) => {
      commit(types.RECEIVE_SHOPS, { data });
    });
  },
  shopPageChange({ commit }, page_number) {
    if (state.busy) return;
    state.busy = true;
    state.params.page = page_number;

    api.getShops(state.params, (data) => {
      commit(types.RECEIVE_SHOPS, { data });
    });
  },
  shopSearchChange({ commit }, query) {
    if (state.busy) return;
    onBusy();
    state.params.search = query;
    state.params.customer = undefined;

    api.getShops(state.params, (data) => {
      commit(types.RECEIVE_SHOPS, { data });
    });
  },
  shopReset() {
    state.params = setDefaults();
  },
  fetchShops({ commit }) {
    if (state.busy) return;
    state.busy = true;

    api.getShops(state.params, (data) => {
      commit(types.RECEIVE_SHOPS, { data });
    });
  },
  fetchShop({ commit }, id) {
    api.getShop(id, (shop) => {
      commit(types.RECEIVE_SHOP, { shop });
    });
  },
  fetchShopBrochures({ commit }, id) {
    api.getShopBrochures(id, (brochures) => {
      commit(types.RECEIVE_SHOP_BROCHURES, { brochures });
    });
  },
  resetShopBrochures({ commit }) {
    let brochures = [];
    commit(types.RECEIVE_SHOP_BROCHURES, { brochures });
  },
  updateShop({ commit }, { id, params }) {
    api.updateShop(id, params, () => {
      actions.fetchShops({ commit });
    });
  },
  createShop: ({ commit }, params) => {
    api.createShop(params, () => {
      actions.fetchShops({ commit });
    });
  },
  deleteShop: ({ commit }, id) => {
    api.deleteShop(id, (ok) => {
      if (ok) actions.fetchShops({ commit });
    });
  },
  getShopOptions: ({ commit }) => {
    api.getShopOptions((data) => {
      commit(types.RECEIVE_SHOPS, { data });
    });
  }
};

const mutations = {
  [types.RECEIVE_SHOPS](state, { data }) {
    state.all = data.shops;
    state.total = data.total_pages;
    state.count = data.count;
    busyDelay();
  },
  [types.RECEIVE_SHOP](state, { shop }) {
    state.active = shop;
  },
  [types.RECEIVE_SHOP_BROCHURES](state, { brochures }) {
    state.brochures = brochures;
  }
};

function setDefaults() {
  return {
    customer: undefined,
    search: undefined,
    page: undefined
  };
}

function onBusy() {
  state.busy = true;
  state.params.page = undefined;
}

const busyDelay = () => {
  setTimeout(() => (state.busy = false), 300);
};

export default {
  state,
  getters,
  actions,
  mutations
};
