<template>
  <form class="parsers-form border-b">
    <div class="row">
      <div class="col-md-3">
        <b-form-input
          @input="$v.url.$touch()"
          v-model="url"
          label="URL"
          :state="inputStateClass($v.url.$error)"
          placeholder="Type URL"
          size="sm"
        />
        <label v-if="$v.url.$error" class="col-form-label error-label">
          Enter valid url
        </label>
      </div>

      <div class="col-xs-3">
        <b-form-input
          @input="$v.name.$touch()"
          v-model="name"
          label="Name"
          :state="inputStateClass($v.name.$error)"
          placeholder="Type text..."
          size="sm"
        />
      </div>

      <div class="col-xs-2">
        <div class="form-group row">
          <div class="col-xs-12">
            <label class="col-form-label">Shops</label>
          </div>

          <div :class="selectStateClass($v.shop.$error)" class="col-xs-12">
            <multiselect
              @input="$v.shop.$touch()"
              @search-change="shopSearch"
              v-model="shop"
              :options="allShops"
              placeholder="Select Shop"
              label="name"
              track-by="id"
            >
            </multiselect>
            <label v-if="$v.shop.$error" class="col-form-label error-label">
              You must select at least one
            </label>
          </div>
        </div>
      </div>

      <div class="col-xs-2">
        <div class="form-group row">
          <div class="col-xs-12">
            <label class="col-form-label">Regions</label>
          </div>

          <div :class="selectStateClass($v.region.$error)" class="col-xs-12">
            <multiselect
              @input="$v.region.$touch()"
              v-model="region"
              :options="allRegions"
              placeholder="Select Region"
              label="name"
              track-by="id"
            >
            </multiselect>
            <label v-if="$v.region.$error" class="col-form-label error-label">
              You must select at least one
            </label>
          </div>
        </div>
      </div>

      <div class="col-xs-2">
        <div class="form-group row">
          <div class="col-xs-12">
            <label class="col-form-label">Language</label>
          </div>

          <div :class="selectStateClass($v.lang.$error)" class="col-xs-12">
            <multiselect
              v-model="lang"
              @input="$v.lang.$touch()"
              :options="allLangs"
              :class="multiselectFlagState(lang)"
              placeholder="Select Language"
              label="name"
              track-by="id"
            >
              <div slot="loading">
                <img
                  v-if="lang"
                  :src="lang.flag"
                  class="table-language-img select__icon"
                />
              </div>
            </multiselect>
            <label v-if="$v.lang.$error" class="col-form-label error-label">
              You must select at least one
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row flex-items-xs-right m-0 pt-2 pb-2">
      <b-button @click="parseUrl()" variant="modal" class="text-uppercase mr-1">
        Parse url
      </b-button>
    </div>

    <Manual v-if="isManualInfo" class="mb-2 mt-2" />
  </form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, url } from "vuelidate/lib/validators";
import Manual from "../elements/manual.vue";
import multiselectImg from "../../../mixins/multiselectImg.js";
import formStateMixin from "../../../mixins/formStateMixin";

export default {
  name: "ParsersForm",
  components: { Manual },
  mixins: [validationMixin, multiselectImg, formStateMixin],
  validations: {
    url: { required, url },
    name: { required },
    shop: { required },
    region: { required },
    lang: { required }
  },
  data() {
    return {
      url: "",
      name: null,
      shop: null,
      region: null,
      lang: null,
      manualInfo: false
    };
  },
  computed: {
    object() {
      return {
        url: this.url,
        name: this.name,
        shop_id: this.shop.id,
        region_id: this.region.id,
        language_id: this.lang.id,
        parser_class_id: this.$route.params.class
      };
    },
    allShops() {
      if (this.region) {
        let shops = this.$store.getters.allShops;
        return shops.filter((shop) => {
          for (let i = 0; i < shop.regions.length; i++) {
            if (shop.regions[i].name == this.region.name) return shop;
          }
        });
      } else {
        return this.$store.getters.allShops;
      }
    },
    allRegions() {
      if (this.shop) {
        return this.shop.regions;
      } else {
        return this.$store.getters.allRegions;
      }
    },
    allLangs() {
      return this.$store.getters.allLanguages;
    },
    isManualInfo() {
      return this.manualInfo;
    }
  },
  created() {
    this.$store.dispatch("getShopOptions");
  },
  methods: {
    parseUrl() {
      this.validateAll();

      if (this.isValid()) {
        this.$store.dispatch("createParser", this.object);
        this.resetAll();
        this.resetForm();
        this.showManualInfo();
      }
    },
    isValid() {
      return !this.$v.$invalid;
    },
    validateAll() {
      this.$v.$touch();
    },
    resetAll() {
      this.$v.$reset();
    },
    addErrorClass(validate) {
      return validate ? "invalid" : "";
    },
    resetForm() {
      this.url = "";
      this.name = "";
      this.shop = "";
      this.region = "";
      this.lang = "";
    },
    showManualInfo() {
      this.manualInfo = true;
    },
    shopSearch(val) {
      this.$store.dispatch("shopSearchChange", val);
    }
  }
};
</script>
